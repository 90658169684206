// @flow

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { resetState } from '../localStateUtils';
import { goToDay } from '../clientUtils';
import { useSettings } from '../settings/useSettings';

const ResetPuzzle = (): null => {
    const { puzzleId } = useParams();
    const settings = useSettings();

    useEffect(() => {
        resetState(puzzleId, settings);
        goToDay(puzzleId, 'puzzle', settings);
    }, [puzzleId, settings]);

    return null;
};

export default ResetPuzzle;
