/* eslint-disable no-useless-escape */
// @flow

export type TGTGCAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    source: string,
    uncover_order: Array<string>,
};

type TGTGCAnswers = {
    [string]: TGTGCAnswer
};

export const answers: TGTGCAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Mass Effect"],
        affiliate_links: [],
        franchise: 'Mass Effect',
        source: 'https://upload.wikimedia.org/wikipedia/en/e/e8/MassEffect.jpg',
        uncover_order: [0, 2, 8, 6],
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["Diablo"],
        affiliate_links: [],
        franchise: 'Diablo',
        source: 'https://static.wikia.nocookie.net/gamia_gamepedia_en/images/a/a3/Front-Cover-Diablo-NA-PC.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["Starfield"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://upload.wikimedia.org/wikipedia/en/6/6d/Bethesda_Starfield.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Rally Driver"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://pbs.twimg.com/media/F-OuikvXsAEIYuc?format=jpg&name=900x900',
        uncover_order: [0, 4, 3, 5],
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Fallout: New Vegas", "Fallout New Vegas"],
        affiliate_links: [],
        franchise: 'Fallout',
        source: 'https://pbs.twimg.com/media/F9lppDXWgAE14TU?format=jpg&name=900x900',
        uncover_order: [0, 2, 5, 6],
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["Tekken 4"],
        affiliate_links: [],
        franchise: 'Tekken',
        source: 'https://pbs.twimg.com/media/GFFZWeTXQAAn0uh?format=jpg&name=medium',
        uncover_order: [0, 2, 5, 6],
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Dead Space"],
        affiliate_links: ["https://www.g2a.com/dead-space-remake-pc-steam-gift-global-i10000336977011?aid=13390417?gtag=391e4ef696", "https://www.amazon.com/Dead-Space-Collectors-PS5-playstation-5/dp/B0BXZTX2WL?crid=3KU5HJTCETJTJ&keywords=Dead+Space&qid=1685576913&sprefix=sea+of+thieves%2Caps%2C556&sr=8-13&ufe=app_do%3Aamzn1.fos.2b70bf2b-6730-4ccf-ab97-eb60747b8daf&linkCode=ll1&tag=guessthegam06-20&linkId=ce56d1d78fb00db0743ce4560115f6f0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dead Space',
        source: 'https://m.media-amazon.com/images/I/510-C6MlBdL._SX300_SY300_QL70_ML2_.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario RPG: Legend of the Seven Stars"],
        affiliate_links: ["https://www.amazon.com/Super-Mario-RPG-Legend-Renewed-Nintendo/dp/B07NY3GJ7X?crid=332ASUMAKYQ4Y&keywords=Super+Mario+RPG%3A+Legend+of+the+Seven+Stars&qid=1685575570&sprefix=super+mario+rpg+legend+of+the+seven+stars%2Caps%2C365&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=76542528aab6d04175e79d6744ea179a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        source: 'https://upload.wikimedia.org/wikipedia/en/8/89/SuperMarioRPGSNESCoverArtUS.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Marvel's Spider-Man: Miles Morales"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Marvels%20Spider%20Man%20Miles%20Morales%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmarvels-spider-man-miles-morales-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/spider-man-miles-morales-ps5-psn-account-global-i10000217221005?gtag=391e4ef696", "https://www.amazon.com/Spider-Man-Miles-Morales-Ultimate-playstation-5/dp/B08R8CJZC6?crid=15XNMID9USGSQ&keywords=Marvel%27s+Spider-Man%3A+Miles+Morales&qid=1684969848&sprefix=marvel%27s+spider-man+miles+morales%2Caps%2C758&sr=8-13&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=894cbac9bfe21947593f172923f737b9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spider-Man',
        source: 'https://th.bing.com/th/id/OIP.hrhdpHHlf_qg3HUgv4cVwwAAAA?rs=1&pid=ImgDetMain',
        uncover_order: [6, 2, 5, 0],
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Final Fantasy VIII", "Final Fantasy 8"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Final%20Fantasy%20VIII%20Remaster%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffinal-fantasy-viii-remastered-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/final-fantasy-viii-remastered-steam-key-global-i10000190435001?aid=13421399?gtag=391e4ef696", "https://www.amazon.com/Final-Fantasy-VIII-Remastered-PlayStation-4/dp/B08L3Q8MMD?crid=3VK7WZRJ889C8&keywords=Final+Fantasy+VIII&qid=1684969295&sprefix=final+fantasy+viii%2Caps%2C711&sr=8-14&linkCode=ll1&tag=guessthegam06-20&linkId=25d9040705e84f371e6c07fd77aff7a5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Final Fantasy',
        source: 'https://cdn2.steamgriddb.com/file/sgdb-cdn/thumb/9c5c4d3dac0f50089fe7b81dd985e6f9.jpg',
        uncover_order: [0, 6, 1, 7],
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["Left 4 Dead"],
        affiliate_links: ["https://www.g2a.com/left-4-dead-bundle-steam-gift-global-i10000007891003?gtag=391e4ef696", "https://www.amazon.com/NEW-Left-Dead-Videogame-xbox-360/dp/B004Y3D4WU?crid=1WWHVQD77BOP8&keywords=Left+4+Dead&qid=1685662546&sprefix=left+4+dead%2Caps%2C651&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=c63cb66f74ccc526b599a8edb156cba6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Left 4 Dead',
        source: 'https://th.bing.com/th/id/R.ee62b5f1dd14bc2d439aefa8c54b0545?rik=CEW9PVcglaSnYg&riu=http%3a%2f%2fwww.l4d.com%2fblog%2fimages%2fposts%2f010%2fcover7.jpg&ehk=BiQjnZuhZdY9dbEeejxZiHf4ErdWfwBShUdGfxvpibA%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 7, 5, 1],
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["Celeste"],
        affiliate_links: ["https://www.g2a.com/celeste-steam-gift-global-i10000135047002?gtag=391e4ef696", "https://www.amazon.com/Celeste-Collectors-NSW-Nintendo-Switch/dp/B0859NBZBP?crid=3EOZJR1R67EYD&keywords=Celeste&qid=1685060591&sprefix=celeste%2Caps%2C415&sr=8-14&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=b02f7329cb45a3146e2b8d925708986c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Celeste',
        source: 'https://th.bing.com/th/id/R.6db8fd8ff73cc40f01168d6b2a4b858b?rik=eldoHsInnByS3A&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f452220-celeste-playstation-4-front-cover.jpg&ehk=VVnbFB2p2gktlLknkvkl4kShS5Foqnoj9CTHD1pcNZ4%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [0, 2, 5, 6],
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Outer Wilds"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Outer%20Wilds%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fouter-wilds-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/outer-wilds-pc-steam-gift-global-i10000196164003?gtag=391e4ef696", "https://www.amazon.com/Outer-Worlds-Nintendo-Switch/dp/B084H5J2TP?crid=9RDT7MEJKON&keywords=Outer+Wilds&qid=1685750176&sprefix=horizon+zero+dawn%2Caps%2C1065&sr=8-30&linkCode=ll1&tag=guessthegam06-20&linkId=88fae0b3e631b19d089aa7b33b62a494&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/OIP.OmdG9ChETUIj7Z7vQXrZYQHaLG?rs=1&pid=ImgDetMain',
        uncover_order: [7, 5, 6, 2],
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["Jak and Daxter: The Precursor Legacy"],
        affiliate_links: ["https://www.g2a.com/jak-and-daxter-the-precursor-legacy-psn-ps4-key-europe-i10000075636002?gtag=391e4ef696", "https://www.g2a.com/jak-and-daxter-the-precursor-legacy-psn-ps4-key-europe-i10000075636002"],
        franchise: 'Jak and Daxter',
        source: 'https://gamefaqs.gamespot.com/a/box/8/2/2/642822_front.jpg',
        uncover_order: [6, 7, 3, 2],
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Horizon Zero Dawn"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Horizon%20Zero%20Dawn%20Complete%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhorizon-zero-dawn-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/horizon-zero-dawn-complete-edition-pc-steam-gift-global-i10000145208004?gtag=391e4ef696", "https://www.amazon.com/Horizon-Zero-Dawn-Complete-playstation-4/dp/B07D2NY7RW?crid=1VB42LXVNN8OT&keywords=Horizon+Zero+Dawn&qid=1685750019&sprefix=the+secret+of+monkey+island%2Caps%2C422&sr=8-19&linkCode=ll1&tag=guessthegam06-20&linkId=2f2f1899885997e1be0c0ed730bac925&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Horizon',
        source: 'https://1.bp.blogspot.com/-BHuqY_9eojI/X6GkLhT1lqI/AAAAAAAADsI/ZJ2GwJF846QpoLGFiObE7pOSVgrcHK5cQCLcBGAsYHQ/s1233/Horizon%2BZero%2BDawn.jpg',
        uncover_order: [5, 6, 8, 0],
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["The Secret of Monkey Island"],
        affiliate_links: ["https://www.g2a.com/the-secret-of-monkey-island-special-edition-steam-key-global-i10000007335002?gtag=391e4ef696", "https://www.amazon.com/Secret-Monkey-Island-Premium-Sega-CD/dp/B08KGYPVDJ?crid=13Y9PLT9OQN07&keywords=The+Secret+of+Monkey+Island&qid=1685749899&sprefix=grand+theft+auto+iii%2Caps%2C608&sr=8-6&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=35924fa749143c67a106c39c4a5d8f50&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Monkey Island',
        source: 'https://th.bing.com/th/id/R.88b76e2db83e94ee3ffdfba5580170d9?rik=eG60zlTxsi0Fpw&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f43341-the-secret-of-monkey-island-dos-front-cover.jpg&ehk=2nu7DRaPU3kGntD8mVC8U4%2fzca3mHYyFn6MO66U1EgE%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 3, 4, 8],
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Portal 2"],
        affiliate_links: ["https://www.g2a.com/portal-2-steam-gift-global-i10000001119002?gtag=391e4ef696", "https://www.amazon.com/Portal-2-Playstation-3/dp/B003O6E3C8?crid=G2QU6DDCEZC7&keywords=Portal+2&qid=1685749464&sprefix=portal+2%2Caps%2C384&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=ddfed19755150dff109ba7b6e95676a4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Portal',
        source: 'https://m.media-amazon.com/images/M/MV5BNzEyNGM5YjgtYjFkMC00MTE1LTk1YjgtNjAyYjA2ODUzNzQwXkEyXkFqcGdeQXVyNjk2MTcyMDA@._V1_.jpg',
        uncover_order: [7, 4, 3, 2],
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Grand Theft Auto 3", "Grand Theft Auto III"],
        affiliate_links: ["https://www.g2a.com/grand-theft-auto-iii-steam-key-global-i10000007301005?aid=13323769?gtag=391e4ef696", "https://www.amazon.com/Grand-Theft-Auto-Trilogy-PlayStation-2/dp/B000KCX9M4?crid=2TLU57P5X00S6&keywords=Grand+Theft+Auto+III&qid=1685749755&sprefix=resident+evil+4%2Caps%2C472&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=8ddb4ef6beb09da463ed34c853fb385a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Grand Theft Auto',
        source: 'https://gamefaqs.gamespot.com/a/box/6/5/9/12659_front.jpg',
        uncover_order: [6, 0, 3, 8],
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Batman: Arkham Asylum"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Batman%20Arkham%20Asylum%20Game%20of%20the%20Year%20Overflow%201%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbatman-arkham-asylum-game-of-the-year%2F&intsrc=PUI2_9608", "https://www.g2a.com/batman-arkham-asylum-goty-pc-steam-key-global-i10000001347004?aid=13445291?gtag=391e4ef696", "https://www.amazon.com/Batman-Arkham-Asylum-Game-PlayStation-PS3/dp/B01CVFD9XQ?keywords=Batman%3A+Arkham+Asylum+game&qid=1685144667&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=333ee7556e7cbfaf88150bfaa058b21e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Batman',
        source: 'https://th.bing.com/th/id/R.8f9615ec88bf65e61de99260244336ab?rik=f2BdsghzIG6QrQ&riu=http%3a%2f%2f1.bp.blogspot.com%2f_1sHleFmwN54%2fSuYh9APg06I%2fAAAAAAAAA0I%2f0vG0JqDCScU%2fs800%2fBatman_Arkham_Asylum.jpg&ehk=MsgD15NqUzqs6xhS4XYw6oGhi4dusBx8BGc3HSl2oXQ%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 8, 0, 2],
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["Sea of Thieves"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Sea%20of%20Thieves%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsea-of-thieves-deluxe-edition-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/sea-of-thieves-pc-steam-gift-global-i10000145411012?gtag=391e4ef696", "https://www.amazon.com/Sea-Thieves-Standard-Xbox-Digital/dp/B0922SQCBR?crid=1COCL8RZNFKQX&keywords=Sea+of+Thieves&qid=1685576718&sprefix=sea+of+thieves%2Caps%2C409&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=046ae29f92528bd2de2dcb4c618bc2f1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Sea of Thieves',
        source: 'https://picfiles.alphacoders.com/157/thumb-1920-157095.jpg',
        uncover_order: [6, 8, 5, 1],
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["NieR:Automata"],
        affiliate_links: ["https://www.g2a.com/nier-automata-game-of-the-yorha-edition-steam-gift-global-i10000183050001?gtag=391e4ef696", "https://www.amazon.com/NieR-Automata-Nintendo-Switch-Digital/dp/B0B7N8G8V4?crid=WFDEXA6T9TAW&keywords=NieR%3AAutomata&qid=1685663048&sprefix=super+mario+64%2Caps%2C385&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=887dd994fa652f177c676eeb16531cab&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'NieR',
        source: 'https://picfiles.alphacoders.com/345/thumb-1920-345634.jpg',
        uncover_order: [5, 7, 6, 3],
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["BioShock Infinite"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Bioshock%20Infinite%20Post%20Overflow%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbioshock-infinite%2F&intsrc=PUI2_9608", "https://www.g2a.com/bioshock-infinite-season-pass-steam-gift-global-i10000004195004?gtag=391e4ef696", "https://www.amazon.com/BioShock-Infinite-Ultimate-Songbird-PC/dp/B009PJ9N4G?crid=RJHI7B82M3F0&keywords=BioShock+Infinite&qid=1685746939&sprefix=chrono+trigger.%2Caps%2C404&sr=8-14&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=3e1fcf72a665c6adec5e427ebd0d2bdf&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bioshock',
        source: 'https://external-preview.redd.it/Bq_T9q5tpW3jJzJJIW8cUiF66O1CHdSrh4uHUfNbH7c.jpg?auto=webp&s=1be9f4e6105a6efacaaa9f78c849c5745165a513',
        uncover_order: [5, 6, 7, 4],
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Hollow Knight"],
        affiliate_links: ["https://www.g2a.com/hollow-knight-pc-steam-key-global-i10000034437006?aid=13233088?gtag=391e4ef696", "https://www.amazon.com/Hollow-Knight-Collectors-Sony-PlayStation-PS4/dp/B07XDD2W6N?crid=2H5WEIG6DTOGU&keywords=Hollow+Knight&qid=1685576354&sprefix=destiny+2%2Caps%2C559&sr=8-13&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=77573c369290e042c9d60c058c932fe3&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Hollow Knight',
        source: 'https://th.bing.com/th/id/OIP.VogALVk4EUAYaRgGcmhmFgHaKk?rs=1&pid=ImgDetMain',
        uncover_order: [6, 8, 7, 3],
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["Furi"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Furi%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffuri%2F&intsrc=PUI2_9608", "https://www.g2a.com/furi-steam-key-global-i10000019195003?aid=13466439?gtag=391e4ef696", "https://www.amazon.com/Furi-Definitive-Switch-Limited-Run-Nintendo/dp/B07KVNRC31?crid=1J0U3LIX6KAFI&keywords=Furi&qid=1685746730&sprefix=furi%2Caps%2C828&sr=8-2&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=2fd03afc6af31f427ee6882c556393ae&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/OIP.udeSuZrUFrnq5gBHofZrwwHaLH?rs=1&pid=ImgDetMain',
        uncover_order: [7, 6, 8, 1],
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Forza Horizon 5"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Forza%20Horizon%205%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fforza-horizon-5-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/forza-horizon-5-premium-edition-pc-steam-gift-global-i10000256484019?gtag=391e4ef696", "https://www.amazon.com/Forza-Horizon-Premium-Windows-Digital/dp/B09DCR61Q9?crid=2LQDSG59WYNAF&keywords=Forza+Horizon+5&qid=1685747670&sprefix=super+meat+boy%2Caps%2C454&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=f950746d56db4b0b33c8be3d0f1a280e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Forza',
        source: 'https://th.bing.com/th/id/OIP.ADkoeLAsVcl7PxEYScZJaQHaJb?rs=1&pid=ImgDetMain',
        uncover_order: [0, 2, 7, 5],
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Zelda: Ocarina of Time"],
        affiliate_links: ["https://www.nintendo.com/store/products/the-legend-of-zelda-ocarina-of-time-3d-3ds/", "https://www.amazon.com/Legend-Zelda-Ocarina-Collectors-nintendo-64/dp/B081HWD676?crid=LBN7OB1SKYKH&keywords=The+Legend+of+Zelda%3A+Ocarina+of+Time&qid=1685750781&sprefix=call+of+duty+modern+warfare+2%2Caps%2C432&sr=8-15&linkCode=ll1&tag=guessthegam06-20&linkId=cf4f3838621b21839d49e79fd2f7ebd7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Legend of Zelda',
        source: 'https://www.techspot.com/images2/news/bigimage/2021/11/2021-11-29-image-4.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["Ghostwire: Tokyo"],
        affiliate_links: ["https://www.g2a.com/ghostwire-tokyo-deluxe-edition-pc-steam-key-global-i10000302559010?aid=13207990?gtag=391e4ef696", "https://www.amazon.com/Ghostwire-Tokyo-Deluxe-PlayStation-5/dp/B09S2ZJHR5?crid=XE0JVZVI54RA&keywords=Ghostwire%3A+Tokyo&qid=1685661210&sprefix=ghostwire+tokyo%2Caps%2C468&sr=8-2&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=f1c8cb2ec4b08cd85ff3fd035c408c4a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://images.purexbox.com/035cec3c16cf7/ghostwire-tokyo-cover.cover_large.jpg',
        uncover_order: [5, 8, 6, 3],
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["SimCopter"],
        affiliate_links: ["https://www.amazon.com/SimCopter-PC/dp/B00002EPY8?crid=1MP6DD3K888WB&keywords=SimCopter&qid=1685662428&sprefix=diablo+game%2Caps%2C388&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=fbc82eee4ea2831c8398b2f740f1083f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'SimCity',
        source: 'https://www.mobygames.com/images/covers/l/80575-simcopter-windows-front-cover.jpg',
        uncover_order: [5, 7, 0, 6],
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["Chrono Trigger"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Chrono%20Trigger%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fchrono-trigger%2F&intsrc=PUI2_9608", "https://www.g2a.com/chrono-trigger-steam-key-global-i10000156705001?aid=13466295?gtag=391e4ef696", "https://www.amazon.com/Chrono-Trigger-Renewed-nintendo-ds/dp/B07WSRW69Z?crid=1D8UPB8RYZVG8&keywords=Chrono+Trigger.&qid=1685746817&sprefix=furi%2Caps%2C416&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=80db2cc3fcb73a93215db5f4d5a580a7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Chrono',
        source: 'https://www.creativeuncut.com/gallery-01/art/ct-misc01.jpg',
        uncover_order: [6, 8, 5, 2],
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["GRIS"],
        affiliate_links: ["https://www.g2a.com/gris-steam-gift-global-i10000176461003?gtag=391e4ef696", "https://www.amazon.com/Gris-Nintendo-Switch/dp/B099L44Z27?crid=23FLM09UFM6B4&keywords=GRIS&qid=1685575775&sprefix=returnal%2Caps%2C396&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=62b5fde249024677e41f919cb56158e7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://assets1.ignimgs.com/2018/12/13/gris---button-fin-1544668050321.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["Returnal"],
        affiliate_links: ["https://www.g2a.com/returnal-pc-steam-gift-global-i10000245048008?aid=13403390?gtag=391e4ef696", "https://www.amazon.com/Returnal-PlayStation-5/dp/B08B41ZBXH?crid=BPRK7R0T3WC2&keywords=Returnal&qid=1685575680&sprefix=super+mario+rpg+legend+of+the+seven+stars%2Caps%2C523&sr=8-3&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=8bad4d3bcc6ac44d3c5cde12b76733b3&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://picfiles.alphacoders.com/427/thumb-1920-427808.jpg',
        uncover_order: [0, 2, 6, 4],
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Monster Hunter: World"],
        affiliate_links: ["https://www.g2a.com/monster-hunter-world-steam-gift-global-i10000084263008?gtag=391e4ef696", "https://www.g2a.com/monster-hunter-world-steam-gift-global-i10000084263008"],
        franchise: 'Monster Hunter',
        source: 'https://th.bing.com/th/id/OIP.L3HiUlpzGHeyfHeDlMaCjwHaHa?rs=1&pid=ImgDetMain',
        uncover_order: [5, 8, 6, 2],
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Dota 2"],
        affiliate_links: [],
        franchise: 'Dota',
        source: 'https://i.pinimg.com/474x/d1/59/e9/d159e9ca272b73f56ef2b770a7c0b17b.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["Hunt: Showdown"],
        affiliate_links: ["https://www.g2a.com/hunt-showdown-gold-edition-xbox-one-xbox-live-key-united-states-i10000144233031?aid=13303707?gtag=391e4ef696", "https://www.amazon.com/HUNT-Showdown-Limited-Bounty-Hunter-PlayStation/dp/B0BQ1GCZMZ?crid=1UO7CCGRXYIIV&keywords=Hunt%3A+Showdown+game&qid=1684887332&sprefix=hunt+showdown+game%2Caps%2C557&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=c3b50cfbc2ebe94015b2eeead6d98b14&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://www.pcgamesarchive.com/wp-content/uploads/2020/04/Hunt-Showdown.jpg',
        uncover_order: [6, 5, 8, 1],
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["Transistor"],
        affiliate_links: ["https://www.g2a.com/transistor-steam-key-global-i10000018791005?gtag=391e4ef696", "https://www.amazon.com/Transistor-Limited-Run-265-ps4-games/dp/B07ZJRF2HJ?crid=2NAR9B3E1K3J8&keywords=Transistor+game&qid=1684970062&sprefix=transistor+%2Caps%2C574&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=46821b9ff8eb93870da89296833f7239&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/OIP.ZpOFjS4bdyEq7Y5DNaXfTwHaKO?rs=1&pid=ImgDetMain',
        uncover_order: [0, 5, 4, 6],
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Fortnite"],
        affiliate_links: [],
        franchise: 'Fortnite',
        source: 'https://cdn.mobygames.com/covers/1771604-fortnite-standard-founders-pack-playstation-4-front-cover.png',
        uncover_order: [6, 7, 8, 1],
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Age: Inquisition"],
        affiliate_links: ["https://www.g2a.com/dragon-age-inquisition-game-of-the-year-edition-origin-key-global-i10000007382003?aid=13318487?gtag=391e4ef696", "https://www.amazon.com/Dragon-Age-Inquisition-Game-Year-PlayStation/dp/B015OYM10I?crid=160S4RGVDEQKS&keywords=Dragon+Age%3A+Inquisition&qid=1685144165&sprefix=dragon+age+inquisition%2Caps%2C364&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=fa03606bb69dd4e169040bf485f6ce02&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dragon Age',
        source: 'https://i0.wp.com/thekoalition.com/images/2014/04/717592.jpg?resize=600%2C600',
        uncover_order: [8, 6, 5, 2],
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["Overwatch"],
        affiliate_links: ["https://www.amazon.com/Overwatch-Legendary-PlayStation-4/dp/B07D9QWSG1?crid=2KRO0XSDBQLJK&keywords=Overwatch&qid=1685145181&sprefix=overwatch%2Caps%2C425&sr=8-27&linkCode=ll1&tag=guessthegam06-20&linkId=4d6a109d2c4cf1de463ccfe8d5f8b23a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Overwatch',
        source: 'https://th.bing.com/th/id/R.b95bdc1792cd0504ca0932a9b822fd65?rik=S2%2bSFaSxRrQOBg&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f340275-overwatch-origins-edition-playstation-4-front-cover.jpg&ehk=tnxV7ajnauLug3ygdl5k6ndih0tC0CEWNuQrP4%2fgVU0%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 7, 2, 5],
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Night In The Woods"],
        affiliate_links: ["https://www.g2a.com/night-in-the-woods-steam-key-global-i10000034896004?gtag=391e4ef696", "https://www.amazon.com/Night-Woods-Nintendo-Switch-Digital/dp/B07D1F6BJ2?crid=2DTXLB0FCFUS7&keywords=night+in+the+woods+video+game&qid=1685661107&sprefix=night+in+the+woods+game%2Caps%2C464&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=f70ff26221a76a9b5f933a194b8a19d6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.eb9a2c1b6c3a5b51d5794dce9b1b28aa?rik=o5eAxz2IalucnQ&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f381998-night-in-the-woods-playstation-4-front-cover.jpg&ehk=XEi%2fHACSCWo9BH0P%2fLR2L8SigReSgGJ4Sj3Wdy%2bkilM%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [0, 2, 5, 6],
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["Glover"],
        affiliate_links: ["https://www.amazon.com/Glover-Playstation/dp/B00001QHWH?crid=3BYAB6AB1MO11&keywords=Glover+game&qid=1684972505&sprefix=glover+%2Caps%2C511&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=9cceae6c5c533b1e0a932759059591f3&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Glover',
        source: 'https://images.launchbox-app.com/2a174478-2ff0-4bf0-833a-0915b5b2d594.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["The Witness"],
        affiliate_links: ["https://www.g2a.com/the-witness-gogcom-key-global-i10000009441008?gtag=391e4ef696"],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.eb1202da112b5179b05a2b86b01e54cf?rik=2N%2fftT%2f8xasc3g&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f362782-the-witness-xbox-one-front-cover.png&ehk=uD2oAuTSLPIVqhg%2bJZr4Q03ERMpz9B2%2f6XzJ%2fgFevvA%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 5, 8, 2],
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["Katamari Damacy"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Katamari%20Damacy%20REROLL%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fkatamari-damacy-reroll-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/katamari-damacy-reroll-steam-key-global-i10000176912001?aid=13447984?gtag=391e4ef696", "https://www.amazon.com/Katamari-Damacy-REROLL-Nintendo-Digital/dp/B07L8C9S9N?crid=38VDLQ158U3CI&keywords=Katamari+Damacy&qid=1685145264&sprefix=overwatch%2Caps%2C770&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=602557e8f678944ca5e3e51a8eeaabbf&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Katamari Damacy',
        source: 'https://cdn.mobygames.com/covers/4168303-katamari-damacy-playstation-2-front-cover.jpg',
        uncover_order: [8, 5, 4, 6],
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["Halo: Combat Evolved"],
        affiliate_links: ["https://www.g2a.com/halo-combat-evolved-anniversary-dlc-steam-gift-global-i10000193803002?gtag=391e4ef696", "https://www.amazon.com/Halo-Combat-Evolved-Anniversary-Xbox-360/dp/B0050SYY5E?crid=4V4EKOQ5GV50&keywords=Halo%3A+Combat+Evolved&qid=1685747992&sprefix=bloodborne%2Caps%2C465&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=7db4012ecacc2422b49463745cd53298&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Halo',
        source: 'https://m.media-amazon.com/images/M/MV5BZmFhOGZiMDEtZDU2NC00Njc2LTk2YzUtZTVkZDJkNWMwNDUzXkEyXkFqcGdeQXVyNzg3NjQyOQ@@._V1_.jpg',
        uncover_order: [5, 4, 0, 8],
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["Super Smash Bros. Ultimate"],
        affiliate_links: ["https://www.amazon.com/Super-Smash-Bros-Ultimate-Nintendo-Switch/dp/B01N5OKGLH?crid=2UC12NW6W3OF4&keywords=Super%2BSmash%2BBros.%2BUltimate&qid=1685142308&sprefix=super%2Bsmash%2Bbros.%2Bultimate%2Caps%2C674&sr=8-1&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=c4d22b7c59aada3029f0462259db5bc2&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Smash Brothers',
        source: 'https://th.bing.com/th/id/OIP.C5GQettC3iF0gL_PuEmv2wHaJj?rs=1&pid=ImgDetMain',
        uncover_order: [0, 2, 5, 6],
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Persona 5", "Persona 5 Royal", "Persona 5: The Royal"],
        affiliate_links: ["https://www.g2a.com/persona-5-royal-pc-steam-gift-global-i10000192991005?gtag=391e4ef696", "https://www.amazon.com/Persona-5-Royal-Nintendo-Switch-Swtich/dp/B0BHWVBCR2?crid=2BFUJNOJHE5JT&keywords=Persona+5&qid=1685663328&sprefix=nier+automata%2Caps%2C386&sr=8-14&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=c73b14ff260d6fa0b2a9c97f09095a48&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Megami Tensei',
        source: 'https://cdn.mobygames.com/covers/8842019-persona-5-royal-playstation-4-front-cover.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Goldeneye 007"],
        affiliate_links: ["https://www.amazon.com/GoldenEye-007-Renewed-nintendo-64/dp/B07X3KML98?crid=3FTTKWPUJ92G3&keywords=Goldeneye+007&qid=1685142179&sprefix=goldeneye+007%2Caps%2C368&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=13bb3b366e5ac6096d11fd1c0a6c305c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'James Bond',
        source: 'https://guessthe.game/gtgc_games/46/1.webp',
        uncover_order: [5, 8, 0, 6],
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Inside"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=INSIDE%20%20LIMBO%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Finside-limbo%2F&intsrc=PUI2_9608", "https://www.g2a.com/inside-steam-gift-global-i10000019615003?gtag=391e4ef696", "https://www.amazon.com/Inside-Limbo-Double-Pack-PS4-playstation-4/dp/B073PHLHP8?crid=1RHBHVPVR6IMY&keywords=inside+game+ps4&qid=1685145055&sprefix=Inside+game%2Caps%2C360&sr=8-5&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=7228c1dffa05d3c37f10495eeaff9513&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://www.h8man.com/static/media/inside.ccf9e471.png',
        uncover_order: [6, 5, 3, 4],
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Nidhogg II"],
        affiliate_links: ["https://www.g2a.com/nidhogg-steam-key-global-i10000004800004?aid=13423283?gtag=391e4ef696"],
        franchise: 'Nidhogg',
        source: 'https://www.newgamenetwork.com/images/uploads/games/1504372229_nidhogg2.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["Code Vein"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=CODE%20VEIN%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcode-vein-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/code-vein-deluxe-edition-pc-steam-account-global-i10000084218032?gtag=391e4ef696", "https://www.amazon.com/Code-Vein-Deluxe-Xbox-Digital/dp/B07W2YXG3X?crid=20YBS7K5XQ0ZJ&keywords=Code+Vein&qid=1685575418&sprefix=code+vein%2Caps%2C466&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=436d0c2a14497394cdf7a9f760f5f964&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/8216604-code-vein-playstation-4-front-cover.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["Stardew Valley"],
        affiliate_links: ["https://www.g2a.com/stardew-valley-steam-gift-global-i10000011727004?aid=13400263?gtag=391e4ef696", "https://www.amazon.com/Stardew-Valley-Collectors-Xbox-One-UK/dp/B06XWGF99X?crid=2ZQ1KAZZHRBHI&keywords=Stardew+Valley&qid=1685057532&sprefix=stardew+valley%2Caps%2C743&sr=8-15&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=70e38f15faf0192b84db56a66706565e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/7453765-stardew-valley-playstation-4-front-cover.jpg',
        uncover_order: [7, 3, 6, 2],
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["Destiny 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Destiny%202%20Legacy%20Collection%202023%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdestiny-2-legacy-collection-2023-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/destiny-2-legacy-collection-2022-pc-steam-key-global-i10000302225002?aid=13299711?gtag=391e4ef696", "https://www.amazon.com/Destiny-2-Limited-Xbox-One/dp/B06XTQ9WFM?crid=3T5AEQ69KU2NE&keywords=Destiny+2&qid=1685576108&sprefix=animal+crossing+new+horizons%2Caps%2C581&sr=8-62&linkCode=ll1&tag=guessthegam06-20&linkId=fb6e75b701190344071e0b88fc77d5a5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Destiny',
        source: 'https://gamepreorders.com/wp-content/uploads/2017/04/destiny-2-box-art.jpg',
        uncover_order: [6, 7, 4, 2],
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["Return of the Obra Dinn"],
        affiliate_links: ["https://www.g2a.com/return-of-the-obra-dinn-pc-steam-key-global-i10000174480003 ?gtag=391e4ef696", "https://www.amazon.com/Return-Obra-Dinn-Collectors-Nintendo-Switch/dp/B097J3W7WD?crid=21ZTGUHYK0Q8W&keywords=Return+of+the+Obra+Dinn&qid=1685141212&sprefix=return+of+the+obra+dinn%2Caps%2C409&sr=8-3&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=47278e8b218d5aab847bf4c17d54232f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/OIP.aZq7u1EyqY2ZcI1SQsdBzAHaLH?rs=1&pid=ImgDetMain',
        uncover_order: [2, 6, 8, 0],
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: Knights of the Old Republic"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Star%20Wars%20Knights%20of%20the%20Old%20Republic%20MAC%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstar-wars-knights-of-the-old-republic-mac%2F&intsrc=PUI2_9608", "https://www.g2a.com/star-wars-knights-of-the-old-republic-pc-steam-key-global-i10000007302003?aid=13264097?gtag=391e4ef696", "https://www.amazon.com/Star-Wars-Knights-Republic-Premium-Nintendo/dp/B0BZZVTYPK?crid=7SLDMALHX6FM&keywords=Star+Wars%3A+Knights+of+the+Old+Republic&qid=1685142725&sprefix=star+wars+knights+of+the+old+republic%2Caps%2C401&sr=8-4&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=9b6d10501b25bce5d6a24cb9ac118060&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Star Wars',
        source: 'https://cdn.mobygames.com/covers/4152623-star-wars-knights-of-the-old-republic-windows-front-cover.jpg',
        uncover_order: [6, 3, 5, 8],
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["Animal Crossing: New Horizons"],
        affiliate_links: ["https://www.g2a.com/animal-crossing-new-horizons-nintendo-switch-nintendo-eshop-key-united-states-i10000192990003?gtag=391e4ef696", "https://www.amazon.com/Animal-Crossing-Horizons-Standard-Nintendo/dp/B0BTKR52S1?crid=FMZ9IS6QKOJO&keywords=Animal+Crossing%3A+New+Horizons&qid=1685575992&sprefix=the+witcher+3+wild+hunt%2Caps%2C385&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=76673d6d1dda992b3cdf1ebe6c394003&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Animal Crossing',
        source: 'https://www.giantbomb.com/a/uploads/scale_medium/8/82063/3179211-acnhart.jpg',
        uncover_order: [6, 7, 5, 0],
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["Super Meat Boy"],
        affiliate_links: ["https://www.g2a.com/super-meat-boy-steam-gift-global-i10000003796002?gtag=391e4ef696", "https://www.amazon.com/Limited-Nintendo-Switch-Alternate-Physical-Region/dp/B07MK3ZMBF?crid=H5NQMKMJ68PQ&keywords=Super+Meat+Boy&qid=1685747340&sprefix=the+elder+scrolls+iv+oblivion%2Caps%2C524&sr=8-8&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=6e9d7f56ac49af1db8903cfd6de0d0bb&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Super Meat Boy',
        source: 'https://image.api.playstation.com/cdn/UP1055/CUSA02845_00/vo6mQBHf4KPJTTqisnmnhd3akUW7Yf4L.png',
        uncover_order: [6, 5, 2, 0],
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["Sid Meier's Civilization VI", "Sid Meier's Civilization 6"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Sid%20Meiers%20Civilization%20VI%20Anthology%20-%20MAC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsid-meiers-civilization-vi-anthology-mac%2F&intsrc=PUI2_9608", "https://www.g2a.com/sid-meiers-civilization-vi-anthology-pc-steam-key-global-i10000255897001?gtag=391e4ef696", "https://www.amazon.com/Sid-Meiers-Civilization-VI-Anthology/dp/B097BF6HRF?crid=2TQXHJU7REF0K&keywords=Sid+Meier%27s+Civilization+VI&qid=1685145531&sprefix=katamari+damacy%2Caps%2C388&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=776df72e7ee8dbe6bdae27ea1ac7fa81&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Civilization',
        source: 'https://cdn.mobygames.com/covers/2285788-sid-meiers-civilization-vi-windows-front-cover.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["Hotline Miami"],
        affiliate_links: ["https://www.g2a.com/hotline-miami-collection-xbox-one-xbox-live-key-united-states-i10000337773002?gtag=391e4ef696", "https://www.amazon.com/Hotline-Miami-Collection-Exclusive-Variant-Nintendo/dp/B08KHVTP5C?crid=2JNUHJO713SXT&keywords=Hotline+Miami&qid=1685141982&sprefix=hotline+miami%2Caps%2C424&sr=8-26&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=9fcfeec3d37665a9d27878b3835c5004&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Hotline Miami',
        source: 'https://th.bing.com/th/id/R.a73d60edb449416e2d1a019df1fea780?rik=YodE5Mfn5oHulw&riu=http%3a%2f%2fwww.vgmonline.net%2fwp-content%2fuploads%2fcover12.jpg&ehk=W36Mto9VBnD7tUUSvgnEJDGZyZpYbr4JhRImlvcKWQE%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [0, 2, 5, 6],
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Deep Rock Galactic"],
        affiliate_links: ["https://www.g2a.com/deep-rock-galactic-deluxe-edition-pc-steam-account-global-i10000081171028?gtag=391e4ef696"],
        franchise: 'None',
        source: 'https://static.actugaming.net/media/2017/06/deep-rock-galactic-cover-e1606530681509.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["XCOM: Enemy Unknown"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Xcom%20Enemy%20Unknown%20Complete%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fxcom-enemy-unknown-the-complete-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/xcom-enemy-unknown-complete-pack-steam-gift-global-i10000001633001?gtag=391e4ef696", "https://www.amazon.com/XCOM-Enemy-Complete-Other-System/dp/B00HVH4NCQ?crid=2XHTBM86G5HBR&keywords=XCOM%3A+Enemy+Unknown&qid=1685060711&sprefix=xcom+enemy+unknown%2Caps%2C486&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=d2a5e4b56c50387d4d9254f8594a7428&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'X-COM',
        source: 'https://assetsio.gnwcdn.com/co29i2.jpg?width=1200&height=1200&fit=bounds&quality=70&format=jpg&auto=webp',
        uncover_order: [0, 2, 5, 6],
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["FTL: Faster Than Light"],
        affiliate_links: ["https://www.g2a.com/ftl-faster-than-light-steam-gift-global-i10000012903002?gtag=391e4ef696", "https://www.amazon.com/FTL-Faster-Than-Light-Download/dp/B00D7GNPO2?crid=97P7BMOAYZXK&keywords=FTL%3A+Faster+Than+Light+game&qid=1684971195&sprefix=ftl+faster+than+light+%2Caps%2C700&sr=8-18&linkCode=ll1&tag=guessthegam06-20&linkId=d8252e9eaed8c9923110592bf34bcadc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/8965698-ftl-faster-than-light-windows-apps-front-cover.jpg',
        uncover_order: [2, 8, 4, 5],
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["Street Fighter II: The World Warrior", "Street Fighter 2"],
        affiliate_links: ["https://www.amazon.com/Fighter-Warrior-Super-Nintendo-Entertainment-System/dp/B0C1L382NF?crid=2OGJQLWUAQ9I1&keywords=Street+Fighter+II%3A+The+World+Warrior&qid=1684967977&sprefix=%2Caps%2C859&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=a0a650111ee59fcc7607873917cb5f05&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Street Fighter',
        source: 'https://www.giantbomb.com/a/uploads/scale_medium/8/87790/2768048-box_sf2ww.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["Paper Mario: The Thousand-Year Door"],
        affiliate_links: ["https://www.amazon.com/Paper-Mario-Thousand-Year-Door-Gamecube/dp/B0002ILS1K?crid=XJ3ACSMP3C6M&keywords=Paper+Mario%3A+The+Thousand-Year+Door&qid=1684970253&sprefix=paper+mario+the+thousand-year+door%2Caps%2C656&sr=8-2&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=f8a8452784bdac3013af25a882a4838c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        source: 'https://m.media-amazon.com/images/M/MV5BNDk1MDIwNzAtMThlMy00M2NkLThlZTctNmJlMDcxYjBmNzk4XkEyXkFqcGdeQXVyMTA0MTM5NjI2._V1_FMjpg_UX1000_.jpg',
        uncover_order: [6, 5, 4, 2],
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["Braid"],
        affiliate_links: ["https://www.g2a.com/braid-steam-gift-global-i10000001243001?gtag=391e4ef696", "https://www.amazon.com/Braid-PC/dp/B0030BFW18?crid=5LY9P3XI5YX4&keywords=braid+video+game&qid=1685058363&sprefix=braid+game%2Caps%2C352&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=f777d269abc6b438789674ae1297c40b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.wikimg.net/strategywiki/images/b/b3/Braid_fullart.jpg',
        uncover_order: [0, 8, 5, 6],
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["Shovel Knight"],
        affiliate_links: ["https://www.amazon.com/Shovel-Knight-Wii-U-Renewed-nintendo/dp/B07Z893ZR4?crid=6U3AONBH65PA&keywords=Shovel+Knight&qid=1685061483&sprefix=shovel+knight%2Caps%2C364&sr=8-29&linkCode=ll1&tag=guessthegam06-20&linkId=966e621c7ddb8978f8d29bff193fcd41&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Shovel Knight',
        source: 'https://i.pinimg.com/originals/87/82/90/87829063a261d2c393c02139505bc05b.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["Papers, Please"],
        affiliate_links: ["https://www.g2a.com/papers-please-steam-gift-global-i10000000400002?gtag=391e4ef696"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/2160642-papers-please-windows-front-cover.jpg',
        uncover_order: [0, 2, 5, 3],
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["Diablo II", "Diablo 2"],
        affiliate_links: ["https://www.g2a.com/diablo-2-pc-battlenet-key-global-i10000043344001?aid=13434432?gtag=391e4ef696", "https://www.amazon.com/Diablo-II-Pc/dp/B00005BV7N?crid=1TJKOP8OP232H&keywords=Diablo+II&qid=1684970551&sprefix=paper+mario+the+thousand-year+door%2Caps%2C638&sr=8-1&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=a345a85e5f20db3432992006fab94bdd&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Diablo',
        source: 'https://th.bing.com/th/id/OIP.KPm1wvsFPwyfobp6oCDIPQHaKg?rs=1&pid=ImgDetMain',
        uncover_order: [0, 2, 5, 6],
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["Dead Cells"],
        affiliate_links: ["https://www.g2a.com/dead-cells-steam-gift-global-i10000041807002?gtag=391e4ef696", "https://www.amazon.com/Dead-Cells-Action-Game-Nintendo-Switch/dp/B07SSV287J?crid=RIZ46T3DKS39&keywords=Dead%2BCells&qid=1685663990&sprefix=persona%2B5%2Caps%2C378&sr=8-2&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=7e33108e2b9d97878b51bd71db063c23&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/10528036-dead-cells-playstation-4-front-cover.jpg',
        uncover_order: [2, 5, 4, 3],
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["Fable: The Lost Chapter", "Fable"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Fable%20The%20Lost%20Chapters%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffable-the-lost-chapters%2F&intsrc=PUI2_9608", "https://www.g2a.com/fable-the-lost-chapters-steam-key-global-i10000043886003?aid=11444407?gtag=391e4ef696", "https://www.amazon.com/Fable-Lost-Chapters-PC/dp/B0001UEHP8?crid=WNCBYG0ZER6S&keywords=Fable%3A%2BThe%2BLost%2BChapter&qid=1685660616&sprefix=dota%2B2%2Bpc%2Bgame%2Caps%2C488&sr=8-7&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=8bf6d2a20668eb364a02b81916172b24&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Fable',
        source: 'https://cdn.mobygames.com/covers/4306497-fable-the-lost-chapters-windows-front-cover.jpg',
        uncover_order: [2, 0, 1, 5],
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["Resident Evil 4"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Resident%20Evil%204%20Deluxe%20Edition%20Remake%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fresident-evil-4-deluxe-edition-remake-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/resident-evil-4-remake-deluxe-edition-preorder-bonus-pc-steam-key-global-i10000337236037?gtag=391e4ef696", "https://www.amazon.com/Resident-Evil-4-Collectors-Playstation-5/dp/B0BZN6JVDP?crid=2A2QMSMWHLOB8&keywords=Resident+Evil+4&qid=1685749567&sprefix=portal+2%2Caps%2C449&sr=8-4&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=8d243a7b83213e662da3f57e17fe876f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Resident Evil',
        source: 'https://th.bing.com/th/id/R.1a703484c3c0cd6971f0d403ce94f0d5?rik=BkkKg4HF6CsKAg&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f41976-resident-evil-4-gamecube-front-cover.jpg&ehk=c%2bWn5YMTyl0Ljor0ItfbBoEt2SCSOIHr%2bLEJsMIWL8g%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 7, 8, 0],
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["Bloodborne"],
        affiliate_links: ["https://www.g2a.com/bloodborne-ps4-psn-account-account-global-i10000006490003?aid=13419419?gtag=391e4ef696", "https://www.amazon.com/Sony-Bloodborne-Game-Year-Ps4/dp/B0188QFSHC?crid=CDXAJI9SNZU7&keywords=Bloodborne&qid=1685747919&sprefix=bloodborne%2Caps%2C464&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=71cce4cfb978daa89b0e40726f17ef7b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bloodborne',
        source: 'https://cdn.mobygames.com/covers/11078327-bloodborne-playstation-4-front-cover.jpg',
        uncover_order: [0, 6, 8, 2],
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["Yakuza 0"],
        affiliate_links: ["https://www.g2a.com/yakuza-0-steam-gift-global-i10000156568002?gtag=391e4ef696", "https://www.amazon.com/Yakuza-Remastered-Collection-PlayStation-4/dp/B07WNS15YQ?keywords=Yakuza%2B0&qid=1685746511&sr=8-8&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=593b88af20ae44b806fc3aedd61af0de&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Yakuza',
        source: 'https://th.bing.com/th/id/OIP.c_Kt092PqN15SdggkKoTGQHaHa?rs=1&pid=ImgDetMain',
        uncover_order: [6, 7, 8, 0],
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["The Last of Us"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Last%20of%20Us%20Part%20I%20Digital%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-last-of-us-part-i-digital-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-last-of-us-part-i-preorder-bonus-pc-steam-key-global-i10000326425021?gtag=391e4ef696", "https://www.amazon.com/Last-Us-Part-PlayStation-5/dp/B0B3QWRQL8?crid=279LN4FURAH1R&keywords=The+Last+of+Us&qid=1685662654&sprefix=the+last+of+u%2Caps%2C442&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=9b194917763883a725613fa961476f95&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Last of Us',
        source: 'https://th.bing.com/th/id/OIP.jixSdown06D93lZP_Ksc_AAAAA?w=473&h=709&rs=1&pid=ImgDetMain',
        uncover_order: [0, 2, 5, 6],
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["Apex Legends"],
        affiliate_links: ["https://www.g2a.com/apex-legends-bloodhound-edition-pc-origin-key-global-i10000192584012?gtag=391e4ef696", "https://www.amazon.com/Apex-Legends-Bloodhound-No-Operating-System/dp/B07Y8RM45F?crid=258IISEJ5JLOG&keywords=Apex+Legends+game&qid=1685575037&sprefix=apex+legends+game%2Caps%2C501&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=3f8cc851107e19cff93dc7d0fa6c611c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Titanfall',
        source: 'https://cdn.mobygames.com/covers/7478493-apex-legends-xbox-one-front-cover.jpg',
        uncover_order: [6, 7, 0, 5],
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["Burnout 3: Takedown", "Burnout 3"],
        affiliate_links: [],
        franchise: 'Burnout',
        source: 'https://m.media-amazon.com/images/M/MV5BYTIwNzdkNzUtYzlkMy00MTQwLTlmODEtMWViOTAzMmRjNjM3XkEyXkFqcGdeQXVyMTA0MTM5NjI2._V1_.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["Rock Band"],
        affiliate_links: ["https://www.g2a.com/rock-band-vr-oculus-key-global-i10000149674001?gtag=391e4ef696", "https://www.amazon.com/Rock-Band-XBox-360-Renewed/dp/B07Y47VW96?crid=2G7GR2XUG63T&keywords=Rock+Band&qid=1685141826&sprefix=rock+band%2Caps%2C396&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=49ace788fa12238eba67f095eb0903d6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Rock Band',
        source: 'https://cdn.mobygames.com/covers/187535-rock-band-xbox-360-front-cover.jpg',
        uncover_order: [8, 5, 6, 0],
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario 64"],
        affiliate_links: ["https://www.amazon.com/Super-Mario-64-Renewed-Nintendo/dp/B07WTXLQKQ?crid=282E2VWVC9R77&keywords=Super+Mario+64&qid=1685662828&sprefix=the+last+of+us%2Caps%2C636&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=f734baff647dc6042671b93c6e125fa0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        source: 'https://th.bing.com/th/id/OIP.v6iBC8CVRtqa9oq5_cYFdQHaFJ?rs=1&pid=ImgDetMain',
        uncover_order: [0, 8, 2, 5],
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario Galaxy"],
        affiliate_links: [],
        franchise: 'Mario',
        source: 'https://th.bing.com/th/id/OIP.2xIjEDBMvA4hSPCUj68yNgHaKJ?rs=1&pid=ImgDetMain',
        uncover_order: [8, 6, 3, 0],
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["PUBG: Battlegrounds"],
        affiliate_links: ["https://www.amazon.com/PLAYERUNKNOWNS-BATTLEGROUNDS-PS4-playstation-4/dp/B07KFX7157?crid=12Q2P9DRYF191&keywords=PUBG%3A+Battlegrounds&qid=1685061912&sprefix=pubg+battlegrounds%2Caps%2C427&sr=8-15&linkCode=ll1&tag=guessthegam06-20&linkId=7d590260d1f047d88dfda49e7f660eb8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'PUBG',
        source: 'https://cdn.mobygames.com/covers/7419296-playerunknowns-battlegrounds-xbox-one-front-cover.png',
        uncover_order: [2, 3, 1, 8],
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["Metroid Prime"],
        affiliate_links: ["https://www.g2a.com/metroid-prime-remastered-nintendo-switch-nintendo-eshop-key-united-states-i10000338593001?gtag=391e4ef696", "https://www.amazon.com/Metroid-Prime-Trilogy-Collectors-nintendo-wii/dp/B07ZYP2BRF?crid=3KG9602950KWE&keywords=Metroid+Prime&qid=1684971606&sprefix=metroid+prime%2Caps%2C714&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=b7d08b75316de1343fa78e483c7f7c39&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Metroid',
        source: 'https://th.bing.com/th/id/OIP.T2ZWWjcZbwrwfYX7rdr-iQHaKg?rs=1&pid=ImgDetMain',
        uncover_order: [8, 5, 3, 0],
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["Okami"],
        affiliate_links: ["https://www.g2a.com/okami-hd-steam-key-global-i10000026248002?aid=13448176?gtag=391e4ef696", "https://www.amazon.com/Okami-HD-PS4-playstation-4/dp/B078P1N1GG?crid=3RZLF29XPFVM5&keywords=Okami&qid=1684451902&sprefix=okami%2Caps%2C393&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=64b7384d94cdf6013b8b006708ee202a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/5197202-okami-playstation-2-front-cover.jpg',
        uncover_order: [6, 5, 3, 2],
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["The Sims"],
        affiliate_links: ["https://www.amazon.com/Sims-Complete-Collection-PC/dp/B000BAVN14?crid=2AGUHWN17FOPO&keywords=The+Sims&qid=1685142849&sprefix=the+sims%2Caps%2C395&sr=8-36&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=cf0815cf5c4fb50ab04765c76fb54b18&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Sims',
        source: 'https://vignette.wikia.nocookie.net/sims/images/8/88/The_Sims_Cover_2.jpg/revision/latest?cb=20150215224537',
        uncover_order: [0, 2, 5, 6],
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["God of War"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=God%20of%20War%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgod-of-war-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/god-of-war-pc-steam-gift-global-i10000152407007?gtag=391e4ef696", "https://www.amazon.com/God-War-Ragnar%C3%B6k-Launch-PlayStation-5/dp/B0B6228XBM?crid=2BZCA58JQJQBR&keywords=God+of+War&qid=1685140448&sprefix=god+of+war%2Caps%2C390&sr=8-7&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=d867b351787b0e2f105feb099aa390b7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'God of War',
        source: 'https://th.bing.com/th/id/OIP.Cnm97kOj8xKehKNnbhw-oQHaHa?rs=1&pid=ImgDetMain',
        uncover_order: [6, 7, 8, 5],
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Zelda: Breath of the Wild"],
        affiliate_links: ["https://www.g2a.com/the-legend-of-zelda-breath-of-the-wild-nintendo-switch-nintendo-eshop-key-united-states-i10000154753005?gtag=391e4ef696", "https://www.amazon.com/Legend-Zelda-Expansion-Nintendo-Digital/dp/B078JXVGS7?crid=3PAK70IMQJKHA&keywords=The%2BLegend%2Bof%2BZelda%3A%2BBreath%2Bof%2Bthe%2BWild&qid=1685660942&sprefix=fable%2Bthe%2Blost%2Bchapter%2Caps%2C420&sr=8-13&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=7368eede414322cc0cf571aab33acb31&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Legend of Zelda',
        source: 'https://preview.redd.it/pg5pl0mpzl9y.png?width=640&crop=smart&auto=webp&s=316ec1b8db456634ceee3de1a9fc6e7de5f31058',
        uncover_order: [0, 2, 5, 6],
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["The Elder Scrolls V: Skyrim"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Elder%20Scrolls%20V%20Skyrim%20Anniversary%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-elder-scrolls-v-skyrim-anniversary-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-elder-scrolls-v-skyrim-anniversary-edition-ps5-psn-account-global-i10000277168013?gtag=391e4ef696", "https://www.amazon.com/Elder-Scrolls-Skyrim-Special-playstation-4/dp/B01GV7Y1SM?keywords=The+Elder+Scrolls+V%3A+Skyrim&qid=1685139952&sr=8-19&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=929637c521ba1c883eee04ab11ab6bdc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Elder Scrolls',
        source: 'https://www.gematsu.com/wp-content/uploads/2011/06/zp-228694_The-Elder-Scrolls-V-Skyrim_2011_06-16-11_001-1024x1177.jpg',
        uncover_order: [6, 7, 8, 0],
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Zelda: The Wind Waker"],
        affiliate_links: ["https://www.amazon.com/Legend-Zelda-Waker-nintendo-wii-u/dp/B002I0GF72?crid=36Y8Y6YAAMU8X&keywords=The+Legend+of+Zelda%3A+The+Wind+Waker&qid=1685059613&sprefix=the+legend+of+zelda+the+wind+waker%2Caps%2C714&sr=8-4&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=d6c73adda90e53076893e976444cf0b5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Legend of Zelda',
        source: 'https://th.bing.com/th/id/OIP.WYOKze_4VYBO1zH8wcxnswHaKj?rs=1&pid=ImgDetMain',
        uncover_order: [2, 6, 8, 0],
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["Control"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Control%20Ultimate%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcontrol-ultimate-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/control-ultimate-edition-pc-steam-key-global-i10000189076006?aid=13283007?gtag=391e4ef696", "https://www.amazon.com/Control-Ultimate-PlayStation-4/dp/B08L7NFCW6?crid=SUVLZM125EFI&keywords=Control+game&qid=1685057388&sprefix=control+gam%2Caps%2C400&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=2acb812bd0ade8b19eb7be7dcce3e77a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/8862245-control-playstation-4-front-cover.jpg',
        uncover_order: [6, 2, 1, 4],
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["Shadow of the Colossus"],
        affiliate_links: ["https://www.amazon.com/Shadow-Colossus-PlayStation-2-Certified-Refurbished/dp/B07KQG3N4B?crid=1DUJI1ZVYDYJK&keywords=Shadow+of+the+Colossus&qid=1685749202&sprefix=mario+golf%2Caps%2C472&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=10802ce5f3aac393d97c61b6eaf414ef&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Ico',
        source: 'https://th.bing.com/th/id/R.2ba6aec2ba993d95d2094552f54fc459?rik=qGH%2bseSnvyNohQ&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f455396-shadow-of-the-colossus-playstation-4-front-cover.png&ehk=%2f99a6byTn6JJahi082RWGsSIpCHFkIkvYxz6JxuNexY%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [0, 2, 6, 7],
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["Mario Golf"],
        affiliate_links: ["https://www.amazon.com/Mario-Golf-Renewed-nintendo-64/dp/B084C2XPBG?crid=3OU5EU7O9G090&keywords=Mario+Golf&qid=1685749124&sprefix=call+of+duty+modern+warfare+2%2Caps%2C405&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=56f3c3ed26abb14278cfce16d143be40&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        source: 'https://m.media-amazon.com/images/M/MV5BNzNmYjI3OTYtMmM4Ny00YjE1LTk0MjctMzlmZDE0NDFiMzVlXkEyXkFqcGdeQXVyMTA0MTM5NjI2._V1_FMjpg_UX1000_.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["Call of Duty: Modern Warfare 2", "Call of Duty: Modern Warfare II"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Call%20of%20Duty%20Modern%20Warfare%202%202009%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcall-of-duty-modern-warfare-2-2009-pc%2F&intsrc=PUI2_9616", "https://www.g2a.com/call-of-duty-modern-warfare-2-bundle-steam-key-global-i10000048518002?gtag=391e4ef696", "https://www.g2a.com/call-of-duty-modern-warfare-2-bundle-steam-key-global-i10000048518002"],
        franchise: 'Call of Duty',
        source: 'https://th.bing.com/th/id/R.b49072b1c16794c8a673dce2541c68c6?rik=Mg7M7fgKTSP5zQ&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f170805-call-of-duty-modern-warfare-2-windows-front-cover.jpg&ehk=1cPfVOAowECSVwSEtiDNeclZ7W7PJs3PrMZDk7Tphws%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [0, 2, 6, 8],
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["The Elder Scrolls IV: Oblivion"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Elder%20Scrolls%20IV%20Oblivion%20Game%20of%20the%20Year%20Edition%20Deluxe%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-elder-scrolls-iv-oblivion-game-of-the-year-edition-deluxe%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-elder-scrolls-iv-oblivion-goty-steam-gift-global-i10000003911001?gtag=391e4ef696", "https://www.amazon.com/Elder-Scrolls-IV-Oblivion-Collectors-Xbox/dp/B08F4PSHVV?crid=2D7S2ZFDYD8VJ&keywords=The+Elder+Scrolls+IV%3A+Oblivion&qid=1685747127&sprefix=the+elder+scrolls+iv+oblivion%2Caps%2C433&sr=8-11&linkCode=ll1&tag=guessthegam06-20&linkId=e3dcf7983bc323b70d69cad057abf9b8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Elder Scrolls',
        source: 'https://th.bing.com/th/id/R.1dbf8480c5f14d076c2b8f500ef9543a?rik=US55fuVAWEz6zg&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f60329-the-elder-scrolls-iv-oblivion-collector-s-edition-windows-front-cover.jpg&ehk=2YLrRC6nQUVp4f9gHBJ3HnujCbHg1Lxd78j0S%2bZWmb4%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 5, 8, 2],
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["Dark Souls"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DARK%20SOULS%20REMASTERED%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdark-souls-remastered-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dark-souls-remastered-pc-steam-key-global-i10000155592001?aid=13269947?gtag=391e4ef696", "https://www.amazon.com/Dark-Souls-Trilogy-PS4-playstation-4/dp/B07MG7Y4CB?crid=27ZALAHGJ47KQ&keywords=Dark+Souls&qid=1685661444&sprefix=ghostwire+tokyo%2Caps%2C408&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=b23ffaeb1058954ecde157a66d3aa15d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dark Souls',
        source: 'https://cdn.mobygames.com/covers/8520604-dark-souls-playstation-3-manual.jpg',
        uncover_order: [6, 5, 4, 1],
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["World of Warcraft"],
        affiliate_links: ["https://www.amazon.com/World-Warcraft-15th-Anniversary-Collectors-Collector/dp/B07RXWJLX1?crid=1U15NE62KQBSP&keywords=world+of+warcraft+collector%27s+edition&qid=1685577494&sprefix=World+of+Warcraft+collector%2Caps%2C815&sr=8-4&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=9e3872db6d32318061017b4ec4119983&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Warcraft',
        source: 'https://cdn.mobygames.com/covers/6285952-world-of-warcraft-windows-front-cover.jpg',
        uncover_order: [3, 6, 7, 8],
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["The Witcher 3: Wild Hunt", "The Witcher 3"],
        affiliate_links: ["https://www.g2a.com/the-witcher-3-wild-hunt-goty-edition-steam-gift-global-i10000026141004?gtag=391e4ef696", "https://www.amazon.com/Bandai-Entertainment-Witcher-Complete-Nintendo-Switch/dp/B082P5XQMN?crid=V89PYR1UURSV&keywords=The+Witcher+3%3A+Wild+Hunt&qid=1685575862&sprefix=gris%2Caps%2C458&sr=8-7&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=7ca52f47c7fd3a0cb488362f4f624e32&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Witcher',
        source: 'https://th.bing.com/th/id/OIP.7-BDYGbncGnVvbrHDTEB8QHaLH?rs=1&pid=ImgDetMain',
        uncover_order: [6, 8, 2, 0],
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["DIRT 4"],
        affiliate_links: ["https://www.g2a.com/dirt-4-pc-steam-key-global-i10000033853006?aid=13433798?gtag=391e4ef696", "https://www.amazon.com/Dirt-4-Xbox-One/dp/B07B8QZ63P?crid=2NTKDE0LJLA00&keywords=DIRT+4&qid=1685143888&sprefix=dirt+4%2Caps%2C377&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=b9f71ed8084b9570dc23661225ffd7be&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'DIRT',
        source: 'https://th.bing.com/th/id/OIP.PnFfgq13qQwItWl6uweM2wHaHa?rs=1&pid=ImgDetMain',
        uncover_order: [6, 7, 8, 5],
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["Wii Sports"],
        affiliate_links: ["https://www.amazon.com/Nintendo-Wii-Sports-Resort-Special-Renewed/dp/B082Z94G1M?crid=2VSP89M6Z6M5Z&keywords=Wii+Sports&qid=1685061757&sprefix=wii+sports%2Caps%2C375&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=2f02a7f654f1ed7e0a77d0c6c3aec5d0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Wii',
        source: 'https://www.lukiegames.com/assets/images/Wii/wii_wii_sports_p_y2z8ju.jpg',
        uncover_order: [6, 7, 0, 2],
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["Divinity: Original Sin II", "Divinity: Original Sin 2"],
        affiliate_links: ["https://www.g2a.com/divinity-original-sin-2-eternal-edition-steam-gift-global-i10000078703003?gtag=391e4ef696", "https://www.amazon.com/Divinity-Original-Sin-II-Definitive-Nintendo/dp/B0863GZKMF?crid=WEQEA9CDLBK1&keywords=Divinity%3A+Original+Sin+II&qid=1685060440&sprefix=divinity+original+sin+ii%2Caps%2C388&sr=8-1&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=bd11966ad45ffff7abd8686a10b64c3e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/OIP.OcVofOK8mfwqp17O3DjGnQHaJ4?rs=1&pid=ImgDetMain',
        uncover_order: [0, 2, 5, 6],
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["Gone Home"],
        affiliate_links: ["https://www.g2a.com/gone-home-steam-gift-global-i10000002603001?gtag=391e4ef696", "https://www.amazon.com/Gone-Home-Nintendo-Switch-Physical/dp/B07TWYW22P?crid=1WTWNTM7YYJCT&keywords=gone+home+video+game&qid=1685060354&sprefix=Gone+Home%2Caps%2C1044&sr=8-1&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=8bff51d7a03830a4f4785c3c1bdc0c70&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/3526403-gone-home-nintendo-switch-front-cover.jpg',
        uncover_order: [0, 2, 5, 6],
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["Kerbal Space Program"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Kerbal%20Space%20Program%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fkerbal-space-program%2F&intsrc=PUI2_9608", "https://www.g2a.com/kerbal-space-program-complete-edition-steam-key-global-i10000014989014?aid=13331776?gtag=391e4ef696", "https://www.amazon.com/Kerbal-Space-Program-Complete-Enhanced/dp/B08FS1ZS48?crid=8VSOVDM2V8BT&keywords=Kerbal+Space+Program&qid=1685058559&sprefix=kerbal+space+program%2Caps%2C368&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=85ce70743f3d307af36ab765962b11b5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Kerbal Space Program',
        source: 'https://www.gamingtalker.it/wp-content/uploads/2023/01/kerbal-space-program-box-art.jpg',
        uncover_order: [6, 7, 2, 1],
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["Dead Rising"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dead%20Rising%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdead-rising-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dead-rising-triple-pack-steam-key-global-i10000146260001?gtag=391e4ef696", "https://www.amazon.com/Dead-Rising-PlayStation-4-Standard/dp/B01JCV26KO?crid=1J2SHKS6TK51E&keywords=Dead+Rising&qid=1685057672&sprefix=dead+rising%2Caps%2C355&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=2e78ad87f333c887d017c449149ba9b6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dead Rising',
        source: 'https://sm.ign.com/t/ign_jp/cover/d/dead-risin/dead-rising_smd1.1200.jpg',
        uncover_order: [5, 6, 7, 0],
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["Minecraft"],
        affiliate_links: ["https://www.g2a.com/minecraft-windows-10-edition-pc-microsoft-key-global-i10000032198001?aid=13409899?gtag=391e4ef696", "https://www.amazon.com/Minecraft-Bedrock-PS4-playstation-4/dp/B082J3D5HN?crid=18KGGGNYTND0S&keywords=minecraft+game&qid=1685057242&sprefix=minecraft+gam%2Caps%2C334&sr=8-20&linkCode=ll1&tag=guessthegam06-20&linkId=8194218c315ab7d209aeca0dac8a2a1c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Minecraft',
        source: 'https://th.bing.com/th/id/OIP.Etn5AiPWlgbaqSK-68rH1QAAAA?rs=1&pid=ImgDetMain',
        uncover_order: [7, 2, 8, 5],
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["Counter-Strike: Global Offensive"],
        affiliate_links: ["https://www.g2a.com/counter-strike-global-offensive-prime-status-upgrade-pc-steam-gift-global-i10000016291010?gtag=391e4ef696", "https://www.amazon.com/Counter-strike-Global-Offensive-Pc-Game-MAC/dp/B009FZZUPQ?crid=3AED1UXB2NN1H&keywords=Counter-Strike%3A+Global+Offensive&qid=1684972691&sprefix=glover+game%2Caps%2C1069&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=be6fb7d2c732d588fb320abc6b557796&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Counter-Strike',
        source: 'https://th.bing.com/th/id/OIP.x8VB_TMmh-PP_44O-UvTJgHaHa?rs=1&pid=ImgDetMain',
        uncover_order: [6, 5, 4, 0],
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["Dead by Daylight"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dead%20by%20Daylight%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdead-by-daylight-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dead-by-daylight-ultimate-edition-pc-steam-account-global-i10000018558040?gtag=391e4ef696", "https://www.g2a.com/dead-by-daylight-ultimate-edition-pc-steam-account-global-i10000018558040"],
        franchise: 'Dead by Daylight',
        source: 'https://th.bing.com/th/id/OIP.7i0A-Qw7dmhOKqo15MC62AHaLH?rs=1&pid=ImgDetMain',
        uncover_order: [0, 6, 2, 8],
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["Mega Man 3"],
        affiliate_links: ["https://www.amazon.com/Mega-Man-Renewed-nintendo-entertainment-system/dp/B07P15YCCW?crid=G7W4G3ZXX1KB&keywords=Mega+Man+3&qid=1683677534&sprefix=mega+man+3%2Caps%2C365&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=989cb19729d32ca0e34c5062cd05e865&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mega Man',
        source: 'https://www.vgdb.com.br/gf/fotos/games/media_27130/mega-man-3-27130.jpg',
        uncover_order: [7, 5, 3, 1],
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["Uncharted: Drake's Fortune", "Uncharted", "Uncharted 1"],
        affiliate_links: ["https://www.amazon.com/Uncharted-Drake-s-Fortune-Playstation-3/dp/B00LD4AY4O?crid=YCE602MDZIQ2&keywords=Uncharted%3A+Drake%27s+Fortune&qid=1684886901&sprefix=uncharted+drake%27s+fortune%2Caps%2C660&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=cbb7b51449bd071be6d422f764e18bd4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Uncharted',
        source: 'https://cdn.mobygames.com/covers/6174941-uncharted-drakes-fortune-playstation-3-front-cover.jpg',
        uncover_order: [8, 6, 2, 1],
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["Neon White"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Neon%20White%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fneon-white-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/neon-white-pc-steam-gift-global-i10000326322001?gtag=391e4ef696", "https://www.amazon.com/Neon-White-Standard-Nintendo-Digital/dp/B0B5RBPZFN?crid=3BMYWO8EKCZ21&keywords=neon+white+game&qid=1683677949&sprefix=Neon+White+%2Caps%2C334&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=2a20d1f76ba6c21c781a715afcd0f011&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/10631785-neon-white-nintendo-switch-front-cover.jpg',
        uncover_order: [0, 6, 1, 8],
    },
    '106': {
        content: '',
        submitted_by: '',
        answers: ["Far Cry 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Far%20Cry%203%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffar-cry-3%2F&intsrc=PUI2_9608", "https://www.g2a.com/far-cry-3-deluxe-edition-steam-gift-global-i10000006377002?gtag=391e4ef696", "https://www.amazon.com/Far-Cry-Compliation-Xbox-360/dp/B00KG823T2?keywords=Far+Cry+3&qid=1684886749&sr=8-17&linkCode=ll1&tag=guessthegam06-20&linkId=d65488e57dee2aa5e6e734c0c61c0b41&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Far Cry',
        source: 'https://cdn.mobygames.com/covers/3785002-far-cry-3-classic-edition-xbox-one-front-cover.jpg',
        uncover_order: [6, 7, 8, 5],
    },
    '107': {
        content: '',
        submitted_by: '',
        answers: ["Getting Over It with Bennett Foddy"],
        affiliate_links: ["https://www.g2a.com/getting-over-it-with-bennett-foddy-steam-pc-gift-global-i10000084088002?gtag=391e4ef696"],
        franchise: 'None',
        source: 'https://cdn2.steamgriddb.com/file/sgdb-cdn/grid/2157b6d660df50a65047393f125c07ab.png',
        uncover_order: [6, 5, 4, 0],
    },
    '108': {
        content: '',
        submitted_by: '',
        answers: ["Dishonored"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dishonored%20Complete%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdishonored-complete-collection-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dishonored-complete-collection-steam-key-global-i10000083178002?gtag=391e4ef696", "https://www.amazon.com/Dishonored-Definitive-Xbox-One/dp/B00ZOET7BU?crid=2V7L7611AQWJA&keywords=Dishonored&qid=1684454432&sprefix=dishonored%2Caps%2C537&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=fb26afcec49c5d6035dfd30dc5d1166e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dishonored',
        source: 'https://cdn.mobygames.com/covers/1203744-dishonored-playstation-3-manual.jpg',
        uncover_order: [8, 5, 4, 2],
    },
    '109': {
        content: '',
        submitted_by: '',
        answers: ["Life is Strange"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Life%20is%20Strange%20Complete%20Season%20Episodes%2015%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Flife-is-strange-complete-season-episodes-1-5%2F&intsrc=PUI2_9608", "https://www.g2a.com/life-is-strange-complete-season-episodes-1-5-steam-key-global-i10000025993009?gtag=391e4ef696", "https://www.amazon.com/Life-Strange-PlayStation-4/dp/B00SRZH210?crid=LHU55W031MT7&keywords=Life%2Bis%2BStrange%2Bcomplete%2Bedition&qid=1683678495&sprefix=life%2Bis%2Bstrange%2Bcomplete%2Bedition%2Caps%2C329&sr=8-5&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=1158f09f9df9955a6b15cbfa439d8418&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Life is Strange',
        source: 'https://cdn.mobygames.com/covers/2861486-life-is-strange-complete-season-episodes-1-5-playstation-3-front.jpg',
        uncover_order: [6, 5, 4, 2],
    },
    '110': {
        content: '',
        submitted_by: '',
        answers: ["Metal Gear Rising: Revengeance"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=METAL%20GEAR%20RISING%20REVENGEANCE%20UK%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmetal-gear-rising-revengeance-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/metal-gear-rising-revengeance-steam-key-global-i10000017865008?aid=12672304?gtag=391e4ef696", "https://www.amazon.com/Revengeance-Premium-Package-Limited-Playstation-3/dp/B009CKQI1E?crid=3LVL0WEXT3Q0Y&keywords=Metal+Gear+Rising%3A+Revengeance&qid=1684453852&sprefix=metal+gear+rising+revengeance%2Caps%2C424&sr=8-6&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=a1bbf03f128bd5c5978843beabe87f62&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Metal Gear',
        source: 'https://cdn.mobygames.com/covers/3341021-metal-gear-rising-revengeance-xbox-360-front-cover.jpg',
        uncover_order: [6, 8, 4, 2],
    },
    '111': {
        content: '',
        submitted_by: '',
        answers: ["Blasphemous"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Blasphemous%20%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fblasphemous-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/blasphemous-digital-deluxe-edition-steam-key-global-i10000190512004?gtag=391e4ef696", "https://www.amazon.com/Limited-Run-304-Blasphemous-PlayStation-4/dp/B08F38K9WX?crid=JJW8XC8N7Z55&keywords=Blasphemous&qid=1683678643&sprefix=blasphemous%2Caps%2C383&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=830f78da89fe8a922c59b5d799e954b9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Blasphemous',
        source: 'https://cdn.mobygames.com/covers/8616613-blasphemous-xbox-one-front-cover.jpg',
        uncover_order: [3, 6, 5, 2],
    },
    '112': {
        content: '',
        submitted_by: '',
        answers: ["Risk of Rain 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Risk%20of%20Rain%202%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frisk-of-rain-2-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/risk-of-rain-2-steam-gift-global-i10000186331002?gtag=391e4ef696", "https://www.amazon.com/Revengeance-Premium-Package-Limited-Playstation-3/dp/B009CKQI1E?crid=3LVL0WEXT3Q0Y&keywords=Metal+Gear+Rising%3A+Revengeance&qid=1684453852&sprefix=metal+gear+rising+revengeance%2Caps%2C424&sr=8-6&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=6c91c24acc9481553a53662fb57434f2&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Risk of Rain',
        source: 'https://th.bing.com/th/id/OIP.cpcTQ4q2Atkwu_G07BYtMwHaHa?rs=1&pid=ImgDetMain',
        uncover_order: [0, 5, 1, 8],
    },
    '113': {
        content: '',
        submitted_by: '',
        answers: ["Kentucky Route Zero"],
        affiliate_links: ["https://www.g2a.com/kentucky-route-zero-steam-key-global-i10000045044002?gtag=391e4ef696", "https://www.amazon.com/Kentucky-Route-Zero-Switch-Digital/dp/B0848GDZ2S?crid=206I6OR3JWJNF&keywords=kentucky+route+zero+switch&qid=1683678859&sprefix=Kentucky+Route+Zero%2Caps%2C347&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=34e942f549b2f4e0068563d2fa24da68&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/8717955-kentucky-route-zero-playstation-4-front-cover.jpg',
        uncover_order: [6, 3, 7, 2],
    },
    '114': {
        content: '',
        submitted_by: '',
        answers: ["Rollercoaster Tycoon"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=RollerCoaster%20Tycoon%20Deluxe%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frollercoaster-tycoon-deluxe%2F&intsrc=PUI2_9608", "https://www.g2a.com/rollercoaster-tycoon-deluxe-gogcom-key-global-i10000000653005?gtag=391e4ef696", "https://www.amazon.com/Scholastic-Roller-Coaster-Tycoon/dp/0439468973?crid=78B0C5030B6E&keywords=Rollercoaster+Tycoon&qid=1684453171&sprefix=death+stranding%2Caps%2C457&sr=8-9&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=5bff8fd4d4b0b4943a70f0ee5deec139&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'RollerCoaster Tycoon',
        source: 'https://i.pinimg.com/736x/39/cd/b6/39cdb65d56df1fb6d1703286ba0fd9a1.jpg',
        uncover_order: [8, 0, 2, 5],
    },
    '115': {
        content: '',
        submitted_by: '',
        answers: ["World of Goo"],
        affiliate_links: ["https://www.g2a.com/world-of-goo-steam-gift-global-i10000002374001?gtag=391e4ef696", "https://www.amazon.com/Super-Rare-Games-SRG-27-Switch/dp/B089YW3F68?keywords=World+of+Goo&qid=1683679193&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=859081f83d95de42a012975d1bbeec64&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/3264293-world-of-goo-windows-manual.jpg',
        uncover_order: [0, 6, 1, 5],
    },
    '116': {
        content: '',
        submitted_by: '',
        answers: ["Death Stranding"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Death%20Stranding%20Directors%20Cut%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdeath-stranding-directors-cut-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/death-stranding-directors-cut-pc-steam-gift-global-i10000192339023?gtag=391e4ef696", "https://www.amazon.com/Death-Stranding-Directors-Cut-Playstation-5/dp/B0992ZKC5Q?keywords=Death+Stranding&qid=1684453027&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=cd855c31783ab568b4c82edb9f166625&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/8188718-death-stranding-playstation-4-front-cover.jpg',
        uncover_order: [8, 2, 0, 5],
    },
    '117': {
        content: '',
        submitted_by: '',
        answers: ["Xenoblade Chronicles 3"],
        affiliate_links: ["https://www.g2a.com/xenoblade-chronicles-3-nintendo-switch-nintendo-eshop-key-united-states-i10000326297001?gtag=391e4ef696", "https://www.amazon.com/Xenoblade-Chronicles-Expansion-Pass-Standard/dp/B0B9259PXG?crid=32LKGEQK7V3WC&keywords=Xenoblade+Chronicles+3&qid=1683679289&sprefix=xenoblade+chronicles+3%2Caps%2C357&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=42574c99aa87a30f8ed1557141466f18&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Xenoblade',
        source: 'https://cdn.mobygames.com/covers/10952360-xenoblade-chronicles-3-nintendo-switch-front-cover.jpg',
        uncover_order: [8, 2, 7, 5],
    },
    '118': {
        content: '',
        submitted_by: '',
        answers: ["Kingdom Hearts II", "Kingdom Hearts 2"],
        affiliate_links: ["https://www.amazon.com/Kingdom-Hearts-II-PlayStation-Certified-Refurbished/dp/B07KQDZCDB?crid=152XNW173729Y&keywords=Kingdom+Hearts+II&qid=1684452349&sprefix=kingdom+hearts+ii%2Caps%2C377&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=c080a86cfff9b877972167139edcc0ef&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Kingdom Hearts',
        source: 'https://cdn.mobygames.com/covers/6198385-kingdom-hearts-ii-playstation-2-front-cover.jpg',
        uncover_order: [5, 0, 1, 8],
    },
    '119': {
        content: '',
        submitted_by: '',
        answers: ["Subnautica"],
        affiliate_links: ["https://www.g2a.com/subnautica-ultimate-collection-pc-steam-account-global-i10000015252019?gtag=391e4ef696", "https://www.amazon.com/Subnautica-Below-Zero-Double-Nintendo-Switch/dp/B08VFRVYT8?crid=1LY0MAL55ILRS&keywords=Subnautica&qid=1683679407&sprefix=subnautica%2Caps%2C332&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=ae84744cc447b1ea4ce799d09daa6806&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Subnautica',
        source: 'https://store-images.s-microsoft.com/image/apps.24838.63409341877910445.4fd452e1-c3ee-4448-a0f8-ac6eb6bdaabf.d531d87c-d1b7-497f-b802-baabfb728090',
        uncover_order: [7, 5, 3, 2],
    },
    '120': {
        content: '',
        submitted_by: '',
        answers: ["Devil May Cry 5"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Devil%20May%20Cry%205%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdevil-may-cry-5-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/devil-may-cry-5-deluxe-edition-steam-key-global-i10000171621022?gtag=391e4ef696", "https://www.amazon.com/Devil-May-Cry-Deluxe-Xbox-One/dp/B07HJSC5V6?crid=2UL5DQR508V5K&keywords=Devil+May+Cry+5&qid=1684452152&sprefix=devil+may+cry+5%2Caps%2C406&sr=8-14&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=041d97b0e935d3ff81d44a84ed9cca41&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Devil May Cry',
        source: 'https://gamepreorders.com/wp-content/uploads/2018/09/box-art-9.jpg',
        uncover_order: [0, 5, 6, 8],
    },
    '121': {
        content: '',
        submitted_by: '',
        answers: ["Titanfall 2"],
        affiliate_links: ["https://www.g2a.com/titanfall-2-ultimate-edition-pc-steam-gift-global-i10000084543008?gtag=391e4ef696", "https://www.amazon.com/Titanfall-Ultimate-Xbox-Digital-Code/dp/B074TFGYLW?crid=2E66EK7YDZXM&keywords=Titanfall+2&qid=1683679786&sprefix=titanfall+2%2Caps%2C346&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=17e7b262c51b7ba413c645948f803c84&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Titanfall',
        source: 'https://cdn.mobygames.com/covers/1929201-titanfall-2-xbox-one-front-cover.png',
        uncover_order: [8, 3, 4, 2],
    },
    '122': {
        content: '',
        submitted_by: '',
        answers: ["Spyro the Dragon", "Spyro"],
        affiliate_links: ["https://www.g2a.com/spyro-reignited-trilogy-steam-key-global-i10000175966010?gtag=391e4ef696", "https://www.amazon.com/Spyro-Reignited-Trilogy/dp/B07T4N5B8T?crid=6YN8I90C4CIX&keywords=Spyro&qid=1684452070&sprefix=spyro%2Caps%2C391&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=6da40273e71a41ee4915c8059aad5bf4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spyro the Dragon',
        source: 'https://th.bing.com/th/id/R.a19d9977f854072ea8910ef7b4acc5d9?rik=HCe%2favn2dNOwHw&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f17925-spyro-the-dragon-playstation-front-cover.jpg&ehk=qin%2bL1T7gxAIAD3xokxVqP7BqrwEmo%2b9eiuzuRQViAI%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 2, 8, 7],
    },
    '123': {
        content: '',
        submitted_by: '',
        answers: ["Grim Fandango"],
        affiliate_links: ["https://www.g2a.com/grim-fandango-remastered-steam-key-global-i10000000435004?gtag=391e4ef696", "https://www.amazon.com/Grim-Fandango-Remastered-Nintendo-Switch/dp/B0B1H82Q66?crid=1GUQSA2K8H4EG&keywords=Grim+Fandango&qid=1683680007&sprefix=grim+fandango%2Caps%2C490&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=3baad3f8d0a4cfec209a67652ac17556&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Grim Fandango',
        source: 'https://cdn.mobygames.com/covers/4256325-grim-fandango-windows-front-cover.jpg',
        uncover_order: [8, 6, 4, 2],
    },
    '124': {
        content: '',
        submitted_by: '',
        answers: ["Warhammer: Vermintide 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Warhammer%20Vermintide%202%20Collectors%20Edition%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fwarhammer-vermintide-2-collectors-edition-ztorm-4bf596dd-en.aspx%2F&intsrc=PUI2_9608", "https://www.g2a.com/warhammer-vermintide-2-content-bundle-2020-steam-key-global-i10000190414001?gtag=391e4ef696", "https://www.amazon.com/Warhammer-Vermintide-Deluxe-PS4-playstation-4/dp/B07QGG4CHR?crid=1M0NHQ62R9FCQ&keywords=Warhammer%3A+Vermintide+2&qid=1683680140&sprefix=warhammer+vermintide+2%2Caps%2C632&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=5f7e6b814df80c1e8eadf8b50d57d57f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Warhammer',
        source: 'https://th.bing.com/th/id/R.af2380bb933bcc2c76634b430a7a555b?rik=E3kzzYbkzSIJ4g&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f493412-warhammer-vermintide-ii-xbox-one-front-cover.jpg&ehk=X9H4tTQm5JohDLmwFBVtKCGKx8YKQ72GBZNGds%2fDpc4%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [1, 3, 5, 7],
    },
    '125': {
        content: '',
        submitted_by: '',
        answers: ["Bomberman Hero"],
        affiliate_links: ["https://www.amazon.com/Bomberman-Hero-Renewed-nintendo-64/dp/B084GWT7DR?crid=3IJZ4XN1O7XPT&keywords=Bomberman+Hero&qid=1684451630&sprefix=bomberman+hero%2Caps%2C400&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=4a770a09746a4cb81f6dc7633f29e1ca&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bomberman',
        source: 'https://cdn.mobygames.com/covers/3925991-bomberman-hero-nintendo-64-front-cover.jpg',
        uncover_order: [2, 3, 8, 1],
    },
    '126': {
        content: '',
        submitted_by: '',
        answers: ["Slay the Spire"],
        affiliate_links: ["https://www.g2a.com/slay-the-spire-steam-gift-global-i10000083851002?gtag=391e4ef696", "https://www.amazon.com/Slay-Spire-PlayStation-4/dp/B07X25XTJN?crid=M9UAIEBO5AZ8&keywords=Slay+the+Spire&qid=1683680409&sprefix=warhammer+vermintide+2+collectors%2Caps%2C417&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=da638e5024fe110b839c1ea172c62de5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/7364287-slay-the-spire-nintendo-switch-front-cover.jpg',
        uncover_order: [2, 7, 6, 4],
    },
    '127': {
        content: '',
        submitted_by: '',
        answers: ["LittleBigPlanet"],
        affiliate_links: ["https://www.amazon.com/LittleBigPlanet-Game-Year-Playstation-3-Renewed/dp/B07TY64VHT?keywords=LittleBigPlanet&qid=1684451467&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=120f2443606d5d430df8a95d746c66e0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/5035184-littlebigplanet-playstation-3-front-cover.jpg',
        uncover_order: [6, 7, 5, 1],
    },
    '128': {
        content: '',
        submitted_by: '',
        answers: ["Angry Birds"],
        affiliate_links: ["https://www.amazon.com/Angry-Birds-Trilogy-Nintendo-Wii/dp/B00DLL8P98?crid=YZ4QMYG548TK&keywords=angry%2Bbirds%2Bgame&qid=1683680534&sprefix=Angry%2BBirds%2Caps%2C325&sr=8-3&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=b0180abdf8ef584840dcfbfcdbf03333&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/411469-angry-birds-windows-front-cover.jpg',
        uncover_order: [7, 3, 5, 2],
    },
    '129': {
        content: '',
        submitted_by: '',
        answers: ["Final Fantasy XIV Online: A Realm Reborn", "Final Fantasy XIV"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=FINAL%20FANTASY%20XIV%20Online%20Comp%20Coll%20Ed%202021%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffinal-fantasy-xiv-online-complete-collectors-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/final-fantasy-xiv-online-complete-edition-final-fantasy-key-north-america-i10000068335002?gtag=391e4ef696", "https://www.amazon.com/FINAL-FANTASY-Online-Complete-Collectors/dp/B09NLLPZSJ?crid=FYKG0RNE85WO&keywords=final+fantasy+xiv+online&qid=1684450649&sprefix=Final+Fantasy+XIV+Online%2Caps%2C355&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=9e82f35646b0771f3f0ef01dceaaed24&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Final Fantasy',
        source: 'https://cdn.mobygames.com/covers/6852302-final-fantasy-xiv-online-a-realm-reborn-windows-front-cover.jpg',
        uncover_order: [3, 8, 1, 6],
    },
    '130': {
        content: '',
        submitted_by: '',
        answers: ["Stray"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Stray%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstray-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/stray-ps5-psn-account-global-i10000326277008?aid=13397890?gtag=391e4ef696", "https://www.amazon.com/Stray-PlayStation-5/dp/B0B69SG993?crid=1OGKSBOZZJ7V3&keywords=Stray&qid=1683680610&sprefix=stray%2Caps%2C449&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=21b9ad6d219185e23ad9812086b8ee92&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/17752931-stray-windows-apps-front-cover.jpg',
        uncover_order: [8, 0, 2, 4],
    },
    '131': {
        content: '',
        submitted_by: '',
        answers: ["Quake"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=QUAKE%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fquake%2F&intsrc=PUI2_9608", "https://www.g2a.com/quake-collection-steam-key-global-i10000034151003?gtag=391e4ef696", "https://www.amazon.com/Quake-Deluxe-Limited-Run-119-Nintendo/dp/B0BS8HCB73?crid=DOOTGFQ67UA8&keywords=Quake&qid=1684450079&sprefix=quake%2Caps%2C483&sr=8-15&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=34d9c386b7a1d897349bad348b8a1aec&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Quake',
        source: 'https://th.bing.com/th/id/R.eac3430e15b27ebc34e48e9cd527fb6c?rik=tA0Ysi8ImQfTXg&riu=http%3a%2f%2fwww.mobygames.com%2fimages%2fcovers%2fl%2f12494-quake-dos-front-cover.jpg&ehk=wEOsEM2BpHUc%2feEPCTidoBccnwk4ucCnFEC3sQxLtBs%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [8, 5, 6, 1],
    },
    '132': {
        content: '',
        submitted_by: '',
        answers: ["Mario & Luigi: Bowser's Inside Story"],
        affiliate_links: ["https://www.amazon.com/Mario-Luigi-Bowsers-Journey-Nintendo-3DS/dp/B088F8TXB3?crid=1JFAR3HAPWPCJ&keywords=Mario+%26+Luigi%3A+Bowser%27s+Inside+Story&qid=1683680716&sprefix=mario+%26+luigi+bowser%27s+inside+story%2Caps%2C795&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=b820c209aa1f112bb0b675f88a15f89f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/12891-mario-luigi-bowsers-inside-story-nintendo-ds-front-cover.jpg',
        uncover_order: [1, 0, 8, 7],
    },
    '133': {
        content: '',
        submitted_by: '',
        answers: ["The Talos Principle"],
        affiliate_links: ["https://www.g2a.com/the-talos-principle-gold-edition-pc-gogcom-key-global-i10000006332022?gtag=391e4ef696", "https://www.amazon.com/Talos-Principle-Deluxe-PlayStation-4/dp/B00X67FWNY?crid=NWATLM5QAVBK&keywords=The+Talos+Principle&qid=1684449924&sprefix=the+talos+principle%2Caps%2C379&sr=8-1&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=be967aaa44521e68414b2d8fa2d15497&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/7496681-the-talos-principle-windows-apps-front-cover.jpg',
        uncover_order: [8, 3, 5, 1],
    },
    '134': {
        content: '',
        submitted_by: '',
        answers: ["Alien: Isolation"],
        affiliate_links: ["https://www.g2a.com/alien-isolation-collection-steam-gift-global-i10000007292006?gtag=391e4ef696", "https://www.amazon.com/Alien-Isolation-PS3-playstation-3/dp/B00HQCQ1R6?crid=3UCV7OWQABJR5&keywords=Alien%3A%2BIsolation&qid=1683680830&sprefix=alien%2Bisolation%2Caps%2C340&sr=8-2&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=86734f1c90fc821e3c0ed66f5a94d7c7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Alien',
        source: 'https://cdn.mobygames.com/covers/1240554-alien-isolation-xbox-one-front-cover.png',
        uncover_order: [6, 5, 4, 1],
    },
    '135': {
        content: '',
        submitted_by: '',
        answers: ["The Stanley Parable"],
        affiliate_links: ["https://www.g2a.com/the-stanley-parable-ultra-deluxe-pc-steam-gift-global-i10000325640004?gtag=391e4ef696"],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.0a142a483edbe81d6cc66c16a68da196?rik=PvXMtb8qHhK%2bmQ&riu=http%3a%2f%2fassets1.ignimgs.com%2f2013%2f10%2f10%2fstanley-parable-buttonjpg-88412a.jpg&ehk=IdYJRRXxoVrPK%2bihheEjoqkRCHBOjKHRRhzjWv6yhqU%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [6, 5, 8, 1],
    },
    '136': {
        content: '',
        submitted_by: '',
        answers: ["Cult of the Lamb"],
        affiliate_links: ["https://www.g2a.com/cult-of-the-lamb-pc-steam-gift-global-i10000326369004?gtag=391e4ef696", "https://www.amazon.com/Cult-Lamb-Deluxe-Nintendo-Switch/dp/B0BJW49FK2?crid=C31M09YZGADM&keywords=cult+of+the+lamb+game&qid=1683849161&sprefix=Cult+of+the+Lamb+%2Caps%2C415&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=ef93e67e793d91ff1a61f3adda1a296b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/10979806-cult-of-the-lamb-xbox-one-front-cover.jpg',
        uncover_order: [7, 0, 1, 4],
    },
    '137': {
        content: '',
        submitted_by: '',
        answers: ["Fez"],
        affiliate_links: ["https://www.amazon.com/FEZ-Standard-Switch-Digital-Code/dp/B09FM44VYZ?crid=32J9VB0ZLCVJR&keywords=fez+game&qid=1684449720&sprefix=Fez+game%2Caps%2C426&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=72ce07b18f51046027e5f2c997a44162&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/1cb0f616-ac03-11ed-b4f3-02420a000135.webp',
        uncover_order: [8, 3, 4, 2],
    },
    '138': {
        content: '',
        submitted_by: '',
        answers: ["Plants vs. Zombies"],
        affiliate_links: ["https://www.g2a.com/plants-vs-zombies-goty-edition-pc-origin-key-global-i10000029914004?gtag=391e4ef696", "https://www.amazon.com/Plants-Vs-Zombies-Nintendo-DS/dp/B00407F2BQ?crid=2G9CYTGO5WPQH&keywords=plants+vs.+zombies+game&qid=1683849485&sprefix=Plants+vs.+Zombies%2Caps%2C388&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=88ef4b08bf10d52eeff220470643e5e4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.staticneo.com/boxshots/MjAwOS8=/plants_vs_zombies_frontcover_large_9uJcNz85WANtb8N.jpg',
        uncover_order: [2, 8, 4, 1],
    },
    '139': {
        content: '',
        submitted_by: '',
        answers: ["Amnesia: The Dark Descent"],
        affiliate_links: ["https://www.g2a.com/amnesia-the-dark-descent-steam-gift-global-i10000001466001?gtag=391e4ef696"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/209571-amnesia-the-dark-descent-windows-front-cover.jpg',
        uncover_order: [8, 6, 5, 0],
    },
    '140': {
        content: '',
        submitted_by: '',
        answers: ["Monster Hunter Rise"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Monster%20Hunter%20Rise%20Plus%20Sunbreak%20Deluxe%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmonster-hunter-rise-plus-sunbreak-deluxe-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/monster-hunter-rise-sunbreak-deluxe-edition-pc-steam-gift-global-i10000337162010?gtag=391e4ef696", "https://www.amazon.com/Monster-Hunter-Rise-Sunbreak-Standard/dp/B0B7LPVNXK?crid=19IVPINJK5B5U&keywords=Monster+Hunter+Rise&qid=1683849554&sprefix=monster+hunter+rise%2Caps%2C417&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=865c561508f8f793cf8a0bbbf9d5caef&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/9846347-monster-hunter-rise-nintendo-switch-front-cover.jpg',
        uncover_order: [7, 5, 4, 1],
    },
    '141': {
        content: '',
        submitted_by: '',
        answers: ["Professor Layton and the Curious Village"],
        affiliate_links: ["https://www.amazon.com/Professor-Layton-Curious-Village-Nintendo-DS/dp/B000N3PKEK?crid=3TDOKALRQ1J8C&keywords=Professor+Layton+and+the+Curious+Village&qid=1684448932&sprefix=professor+layton+and+the+curious+village%2Caps%2C537&sr=8-2&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=61d1e05db1829b52633a18b758b28b51&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/4865902-professor-layton-and-the-curious-village-nintendo-ds-front-cover.jpg',
        uncover_order: [8, 5, 4, 1],
    },
    '142': {
        content: '',
        submitted_by: '',
        answers: ["The Sims 4"],
        affiliate_links: ["https://www.amazon.com/Sims-Everyday-Bundle-Online-Game/dp/B0BR3W49KH?crid=I6D5EIM9RIJT&keywords=the%2Bsims%2B4%2Bbase%2Bgame&qid=1683850032&sprefix=the%2Bsims%2B4%2Bbase%2B%2Caps%2C862&sr=8-2&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=70632cbc216b2a0b344173e059c8c775&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Sims',
        source: 'https://cdn.mobygames.com/covers/10405877-the-sims-4-windows-front-cover.jpg',
        uncover_order: [8, 7, 4, 1],
    },
    '143': {
        content: '',
        submitted_by: '',
        answers: ["Half-Life 2"],
        affiliate_links: ["https://www.g2a.com/half-life-2-steam-key-global-i10000003580008?gtag=391e4ef696", "https://www.amazon.com/Half-Life-Renewed-Pc/dp/B088F46GZQ?crid=VWT8ILMDL1RG&keywords=Half-Life+2&qid=1684448837&sprefix=half-life+2%2Caps%2C399&sr=8-16&linkCode=ll1&tag=guessthegam06-20&linkId=e2f082b70d5534d8205d5c29c24b8e07&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Half-Life',
        source: 'https://cdn.mobygames.com/covers/4205774-half-life-2-windows-front-cover.jpg',
        uncover_order: [8, 3, 6, 2],
    },
    '144': {
        content: '',
        submitted_by: '',
        answers: ["Spelunky"],
        affiliate_links: ["https://www.g2a.com/spelunky-steam-gift-global-i10000000394002?gtag=391e4ef696", "https://www.amazon.com/Spelunky-Collectors-Limited-Run-220-Playstation/dp/B07SG1GP63?crid=1SZ50YM6HJXUJ&keywords=spelunky&qid=1683850880&s=videogames&sprefix=spelunky%2Cvideogames%2C332&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=890dbd08bd39715d82256cabbf6d8ce1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spelunky',
        source: 'https://cdn.mobygames.com/covers/15544123-spelunky-playstation-4-front-cover.jpg',
        uncover_order: [3, 8, 4, 1],
    },
    '145': {
        content: '',
        submitted_by: '',
        answers: ["Assassin's Creed II", "Assassin's Creed 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Assassins%20Creed%20II%20NEW%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fassassins-creed-ii-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/assassins-creed-ii-deluxe-edition-ubisoft-connect-key-global-i10000005122006?gtag=391e4ef696", "https://www.amazon.com/Assassins-Creed-Game-essentials-playstation-3/dp/B009DLTF58?crid=F4RPRPV8Q88S&keywords=Assassin%27s+Creed+II&qid=1684448607&sprefix=the+simpsons+hit+%26+run%2Caps%2C801&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=bde8d9db3d4c2980718e92516c391f7d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Assassins Creed',
        source: 'https://cdn.mobygames.com/covers/5790434-assassins-creed-ii-playstation-3-front-cover.jpg',
        uncover_order: [5, 7, 4, 1],
    },
    '146': {
        content: '',
        submitted_by: '',
        answers: ["System Shock 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=System%20Shock%20Enhanced%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsystem-shock-enhanced-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/system-shock-2-gogcom-key-global-i10000000133005?gtag=391e4ef696"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/8512782-system-shock-2-windows-front-cover.jpg',
        uncover_order: [3, 5, 0, 7],
    },
    '147': {
        content: '',
        submitted_by: '',
        answers: ["The Simpsons: Hit & Run"],
        affiliate_links: ["https://www.amazon.com/Simpsons-Hit-Run-Gamecube-Renewed/dp/B07KQD6S7C?crid=329THJBLGMWZ8&keywords=The+Simpsons%3A+Hit+%26+Run&qid=1684448478&sprefix=the+simpsons+hit+%26+run%2Caps%2C395&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=5bfe0e08e38ef0dbbbab3196a8fa668b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/4208176-the-simpsons-hit-run-windows-front-cover.jpg',
        uncover_order: [8, 6, 4, 2],
    },
    '148': {
        content: '',
        submitted_by: '',
        answers: ["Sonic the Hedgehog"],
        affiliate_links: ["https://www.g2a.com/sonic-the-hedgehog-ultimate-bundle-pc-steam-key-global-i10000336762002?gtag=391e4ef696", "https://www.amazon.com/Sonic-Hedgehog-Xbox-360-Renewed/dp/B07Y2FT9BF?crid=2ELZS15I635D4&keywords=Sonic+the+Hedgehog+bundle&qid=1683851211&s=videogames&sprefix=sonic+the+hedgehog+bundle%2Cvideogames%2C382&sr=1-7&linkCode=ll1&tag=guessthegam06-20&linkId=409508ac24e4877e432a551cb60a53e7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/5299693-sonic-the-hedgehog-genesis-front-cover.jpg',
        uncover_order: [0, 3, 2, 7],
    },
    '149': {
        content: '',
        submitted_by: '',
        answers: ["Payday 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Payday%202%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpayday-2-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/payday-2-legacy-collection-steam-key-global-i10000192666001?aid=13390774?gtag=391e4ef696", "https://www.amazon.com/Payday-2-Playstation-3-Renewed/dp/B088W1Z4N3?crid=2OZ9S93MWS6IZ&keywords=Payday+2&qid=1684448311&sprefix=payday+2%2Caps%2C461&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=832c553ee004b9a4293dc66522119c34&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/17248299-payday-2-xbox-360-front-cover.jpg',
        uncover_order: [7, 6, 8, 2],
    },
    '150': {
        content: '',
        submitted_by: '',
        answers: ["Darkest Dungeon"],
        affiliate_links: ["https://www.g2a.com/darkest-dungeon-ancestral-edition-steam-key-global-i10000154528001?aid=12627847?gtag=391e4ef696", "https://www.amazon.com/Darkest-Dungeon-Collectors-Console-Nintendo-Switch/dp/B07MWVBX1X?crid=3SVAWZAUMJTMI&keywords=Darkest+Dungeon&qid=1683851292&s=videogames&sprefix=darkest+dungeon%2Cvideogames%2C352&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=0eafb8efae4d3f948eb93fdded978ae0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/3157495-darkest-dungeon-windows-apps-front-cover.jpg',
        uncover_order: [8, 0, 1, 4],
    },
    '151': {
        content: '',
        submitted_by: '',
        answers: ["Metal Gear Solid 2: Sons of Liberty"],
        affiliate_links: ["https://www.amazon.com/Metal-Gear-Solid-2-Liberty-Pc/dp/B00005ML10?crid=2FP9PN14HEOQC&keywords=Metal+Gear+Solid+2%3A+Sons+of+Liberty&qid=1684448196&sprefix=metal+gear+solid+2+sons+of+liberty%2Caps%2C396&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=18d7ccaf51abddced98966425a208ca6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/3990598-metal-gear-solid-2-sons-of-liberty-playstation-2-front-cover.jpg',
        uncover_order: [2, 5, 0, 7],
    },
    '152': {
        content: '',
        submitted_by: '',
        answers: ["Prince of Persia: The Sands of Time"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Prince%20of%20Persia%20The%20Sands%20of%20Time%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fprince-of-persia-the-sands-of-time%2F&intsrc=PUI2_9608", "https://www.g2a.com/prince-of-persia-the-sands-of-time-remake-xbox-series-x-xbox-live-key-united-states-i10000218455001?gtag=391e4ef696", "https://www.amazon.com/Prince-Persia-Sands-Time-Remake/dp/B08TS7B3YL?crid=LEG6H4WZA1RH&keywords=Prince+of+Persia%3A+The+Sands+of+Time&qid=1683851400&s=videogames&sprefix=prince+of+persia+the+sands+of+time%2Cvideogames%2C535&sr=1-3&linkCode=ll1&tag=guessthegam06-20&linkId=1a7405fb6912456b5807bb2040fffe39&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/5137967-prince-of-persia-the-sands-of-time-windows-front-cover.png',
        uncover_order: [7, 5, 6, 2],
    },
    '153': {
        content: '',
        submitted_by: '',
        answers: ["Disco Elysium"],
        affiliate_links: ["https://www.g2a.com/disco-elysium-the-final-cut-pc-gogcom-key-global-i10000191524005?gtag=391e4ef696", "https://www.amazon.com/Disco-Elysium-Final-Cut-Nintendo-Switch/dp/B09P4CH1FB?crid=GHX3J9SGKOUA&keywords=Disco+Elysium&qid=1684448046&sprefix=disco+elysium%2Caps%2C449&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=c6cdc9618b7ce1d307883f9e353b9fc2&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/11267408-disco-elysium-stadia-front-cover.jpg',
        uncover_order: [2, 3, 1, 7],
    },
    '154': {
        content: '',
        submitted_by: '',
        answers: ["Battlefield 2"],
        affiliate_links: ["https://www.amazon.com/Battlefield-2-Complete-Pc/dp/B001Q9C5RU?crid=11PFW6WSMKWOQ&keywords=Battlefield+2&qid=1683851720&s=videogames&sprefix=battlefield+2%2Cvideogames%2C466&sr=1-5&linkCode=ll1&tag=guessthegam06-20&linkId=2a51bb90ecbae2bd0b24350a81169a4e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/4586187-battlefield-2-windows-front-cover.jpg',
        uncover_order: [8, 7, 1, 4],
    },
    '155': {
        content: '',
        submitted_by: '',
        answers: ["Cities: Skylines"],
        affiliate_links: ["https://www.g2a.com/cities-skylines-city-startup-bundle-pc-steam-key-global-i10000218085001?gtag=391e4ef696", "https://www.amazon.com/Cities-Skylines-Nintendo-Switch/dp/B07RFSJNYC?crid=3PGZFROGAVXJK&keywords=Cities%3A+Skylines+bundle&qid=1684367090&sprefix=cities+skylines+bundle%2Caps%2C329&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=10903b7643525500afc64f44eca7a3ae&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Cities: Skylines',
        source: 'https://cdn.mobygames.com/covers/877706-cities-skylines-linux-front-cover.jpg',
        uncover_order: [0, 3, 5, 6],
    },
    '156': {
        content: '',
        submitted_by: '',
        answers: ["Manhunt"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Manhunt%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmanhunt%2F&intsrc=PUI2_9608", "https://www.g2a.com/manhunt-steam-key-global-i10000044389003?gtag=391e4ef696", "https://www.amazon.com/Manhunt-Xbox/dp/B0001JXQ5Q?crid=3AON7RL8YJYWJ&keywords=manhunt+game&qid=1683853012&sprefix=Manhunt%2Caps%2C348&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=b6a21664ca93d64b149307fa08b2721f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Manhunt',
        source: 'https://cdn.mobygames.com/covers/4087382-manhunt-playstation-2-front-cover.jpg',
        uncover_order: [3, 7, 4, 1],
    },
    '157': {
        content: '',
        submitted_by: '',
        answers: ["Rust"],
        affiliate_links: ["https://www.g2a.com/rust-pc-steam-key-global-i10000001781013?gtag=391e4ef696", "https://www.amazon.com/Rust-Xbox-One/dp/B091F1J6R7?crid=2DBS98QFH7LQ2&keywords=Rust&qid=1684366715&sprefix=rust%2Caps%2C374&sr=8-6&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=414fba1b428851a5995e4532a00c0f1b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://images.g2a.com/300x400/1x1x1/rust-steam-gift-global-i10000001781009/59bb0ee65bafe314cb4b4a36',
        uncover_order: [1, 4, 6, 8],
    },
    '158': {
        content: '',
        submitted_by: '',
        answers: ["Crypt of the NecroDancer"],
        affiliate_links: ["https://www.g2a.com/crypt-of-the-necrodancer-steam-gift-global-i10000004674002?gtag=391e4ef696", "https://www.amazon.com/Crypt-Necrodancer-Collectiors-Nintendo-Switch/dp/B08L67XTQG?crid=60HPXOFU46GM&keywords=Crypt+of+the+NecroDancer&qid=1683853066&sprefix=manhunt+game%2Caps%2C991&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=f031ca83ed7b1066781a288753538e6f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/1948961-crypt-of-the-necrodancer-xbox-one-front-cover.png',
        uncover_order: [7, 8, 6, 2],
    },
    '159': {
        content: '',
        submitted_by: '',
        answers: ["Viva Pi\u00f1ata"],
        affiliate_links: ["https://www.amazon.com/Pi%C3%B1ata-Platinum-Family-Renewed-Xbox-360/dp/B07S64MWW4?crid=30WIJ9CYQYL0&keywords=Viva+Pinata&qid=1684366631&sprefix=viva+pinata%2Caps%2C462&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=ae5eebde4c5ad26cb1bcd677a091b3dc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/4154319-viva-pinata-xbox-360-front-cover.jpg',
        uncover_order: [8, 7, 4, 2],
    },
    '160': {
        content: '',
        submitted_by: '',
        answers: ["Friday the 13th: The Game"],
        affiliate_links: ["https://www.g2a.com/friday-the-13th-the-game-steam-key-global-i10000042338007?aid=13233952?gtag=391e4ef696", "https://www.amazon.com/Tisch-Friday-13th-Game-Ultimate-PC/dp/B0C1BSBLK9?keywords=Friday+the+13th%3A+The+Game&qid=1683946781&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=544db3665050bbb0eb63007770d7c499&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/3559625-friday-the-13th-the-game-playstation-4-front-cover.jpg',
        uncover_order: [8, 0, 7, 3],
    },
    '161': {
        content: '',
        submitted_by: '',
        answers: ["Overcooked!"],
        affiliate_links: ["https://www.g2a.com/overcooked-steam-key-global-i10000025931006?aid=13234396?gtag=391e4ef696", "https://www.amazon.com/Overcooked-Special-Double-Pack-Nintendo-Switch/dp/B0824C7F2L?crid=JLQD3SFCC6XJ&keywords=Overcooked%21&qid=1684366456&sprefix=overcooked+%2Caps%2C412&sr=8-11&linkCode=ll1&tag=guessthegam06-20&linkId=e6e14797d5083594f8bb0c8f0504c356&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Overcooked',
        source: 'https://cdn.mobygames.com/covers/2041568-overcooked-xbox-one-front-cover.png',
        uncover_order: [3, 4, 5, 2],
    },
    '162': {
        content: '',
        submitted_by: '',
        answers: ["Ori and the Blind Forest"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Ori%20and%20the%20Blind%20Forest%20Definitive%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fori-and-the-blind-forest-definitive-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/ori-and-the-blind-forest-xbox-live-key-global-i10000001951007?gtag=391e4ef696", "https://www.amazon.com/Ori-Blind-Forest-Defintive-Nintendo-Switch/dp/B08KJGC2QC?crid=F1TOKC1GZ4AI&keywords=Ori+and+the+Blind+Forest&qid=1683947080&sprefix=ori+and+the+blind+forest%2Caps%2C374&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=3bf0a0aa0643f2903a2e0f0e1f3acd93&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Ori',
        source: 'https://cdn.mobygames.com/covers/1753232-ori-and-the-blind-forest-xbox-one-front-cover.png',
        uncover_order: [2, 3, 5, 0],
    },
    '163': {
        content: '',
        submitted_by: '',
        answers: ["Team Fortress 2"],
        affiliate_links: [],
        franchise: 'Team Fortress',
        source: 'https://cdn.mobygames.com/covers/4064233-team-fortress-2-windows-front-cover.jpg',
        uncover_order: [3, 4, 5, 2],
    },
    '164': {
        content: '',
        submitted_by: '',
        answers: ["Unreal Tournament"],
        affiliate_links: ["https://www.g2a.com/unreal-tournament-game-of-the-year-edition-steam-key-global-i10000050095002?gtag=391e4ef696", "https://www.amazon.com/Unreal-Tournament-Game-Year-Mac/dp/B00005A3LG?crid=3Q5LNL6PQCDNA&keywords=Unreal+Tournament&qid=1683947242&sprefix=unreal+tournament%2Caps%2C374&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=3aadc91384532cc7656c4a25c0c9ebd6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Unreal',
        source: 'https://cdn.mobygames.com/covers/4056670-unreal-tournament-windows-front-cover.jpg',
        uncover_order: [2, 0, 5, 3],
    },
    '165': {
        content: '',
        submitted_by: '',
        answers: ["Hitman 3"],
        affiliate_links: [],
        franchise: 'Hitman',
        source: 'https://cdn.mobygames.com/covers/9846356-hitman-iii-playstation-4-front-cover.png',
        uncover_order: [0, 3, 6, 1],
    },
    '166': {
        content: '',
        submitted_by: '',
        answers: ["Tomb Raider"],
        affiliate_links: [],
        franchise: 'Tomb Raider',
        source: 'https://cdn.mobygames.com/covers/4048329-tomb-raider-dos-front-cover.jpg',
        uncover_order: [7, 4, 5, 3],
    },
    '167': {
        content: '',
        submitted_by: '',
        answers: ["Doom Eternal"],
        affiliate_links: [],
        franchise: 'Doom',
        source: 'https://cdn.mobygames.com/covers/8657152-doom-eternal-windows-apps-front-cover.jpg',
        uncover_order: [6, 5, 3, 8],
    },
    '168': {
        content: '',
        submitted_by: '',
        answers: ["Phasmophobia"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://m.media-amazon.com/images/M/MV5BNjI3ZTgxMjYtOTM4YS00NDEwLTg3YzktYWVlNTJhNGI1NzZlXkEyXkFqcGdeQXVyMTA0MTM5NjI2._V1_FMjpg_UX1000_.jpg',
        uncover_order: [6, 7, 8, 4],
    },
    '169': {
        content: '',
        submitted_by: '',
        answers: ["Hades"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/10377243-hades-playstation-4-front-cover.jpg',
        uncover_order: [0, 2, 5, 3],
    },
    '170': {
        content: '',
        submitted_by: '',
        answers: ["BioShock"],
        affiliate_links: [],
        franchise: 'Bioshock',
        source: 'https://cdn.mobygames.com/covers/5349929-bioshock-playstation-3-front-cover.jpg',
        uncover_order: [5, 7, 3, 4],
    },
    '171': {
        content: '',
        submitted_by: '',
        answers: ["Tunic"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/10797483-tunic-windows-apps-front-cover.jpg',
        uncover_order: [5, 8, 3, 6],
    },
    '172': {
        content: '',
        submitted_by: '',
        answers: ["Luigi's Mansion"],
        affiliate_links: [],
        franchise: 'Luigis Mansion',
        source: 'https://cdn.mobygames.com/covers/6012797-luigis-mansion-gamecube-front-cover.jpg',
        uncover_order: [3, 5, 0, 6],
    },
    '173': {
        content: '',
        submitted_by: '',
        answers: ["Ratchet & Clank"],
        affiliate_links: [],
        franchise: 'Ratchet & Clank',
        source: 'https://cdn.mobygames.com/covers/4097822-ratchet-clank-playstation-2-front-cover.jpg',
        uncover_order: [3, 8, 5, 7],
    },
    '174': {
        content: '',
        submitted_by: '',
        answers: ["Killzone 2"],
        affiliate_links: [],
        franchise: 'Killzone',
        source: 'https://cdn.mobygames.com/covers/5173920-killzone-2-playstation-3-front-cover.jpg',
        uncover_order: [1, 2, 8, 0],
    },
    '175': {
        content: '',
        submitted_by: '',
        answers: ["Elden Ring"],
        affiliate_links: [],
        franchise: 'Elden Ring',
        source: 'https://cdn.mobygames.com/covers/10371758-elden-ring-xbox-one-front-cover.jpg',
        uncover_order: [8, 6, 3, 5],
    },
    '176': {
        content: '',
        submitted_by: '',
        answers: ["Twisted Metal"],
        affiliate_links: [],
        franchise: 'Twisted Metal',
        source: 'https://cdn.mobygames.com/covers/6728269-twisted-metal-playstation-front-cover.jpg',
        uncover_order: [7, 8, 4, 6],
    },
    '177': {
        content: '',
        submitted_by: '',
        answers: ["No Man's Sky"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/2844559-no-mans-sky-playstation-4-front-cover.jpg',
        uncover_order: [4, 3, 5, 2],
    },
    '178': {
        content: '',
        submitted_by: '',
        answers: ["FEAR: First Encounter Assault Recon", "FEAR"],
        affiliate_links: [],
        franchise: 'FEAR',
        source: 'https://cdn.mobygames.com/covers/6720811-fear-first-encounter-assault-recon-xbox-360-front-cover.jpg',
        uncover_order: [0, 2, 1, 7],
    },
    '179': {
        content: '',
        submitted_by: '',
        answers: ["Crazy Taxi"],
        affiliate_links: [],
        franchise: 'Crazy Taxi',
        source: 'https://cdn.mobygames.com/covers/122502-crazy-taxi-windows-front-cover.jpg',
        uncover_order: [0, 5, 2, 6],
    },
    '180': {
        content: '',
        submitted_by: '',
        answers: ["Borderlands"],
        affiliate_links: [],
        franchise: 'Borderlands',
        source: 'https://cdn.mobygames.com/covers/5591400-borderlands-xbox-360-front-cover.jpg',
        uncover_order: [7, 6, 0, 3],
    },
    '181': {
        content: '',
        submitted_by: '',
        answers: ["Crysis"],
        affiliate_links: [],
        franchise: 'Crysis',
        source: 'https://cdn.mobygames.com/covers/4083983-crysis-windows-front-cover.jpg',
        uncover_order: [7, 2, 8, 6],
    },
    '182': {
        content: '',
        submitted_by: '',
        answers: ["Starcraft"],
        affiliate_links: [],
        franchise: 'StarCraft',
        source: 'https://cdn.mobygames.com/covers/2986321-starcraft-macintosh-front-cover.jpg',
        uncover_order: [3, 8, 5, 7],
    },
    '183': {
        content: '',
        submitted_by: 'https://cdn.mobygames.com/covers/6588401-tom-clancys-splinter-cell-chaos-theory-playstation-2-front-cover.jpg',
        answers: ["Tom Clancy's Splinter Cell: Chaos Theory"],
        affiliate_links: [],
        franchise: 'Tom Clancy',
        source: 'https://cdn.mobygames.com/covers/6588401-tom-clancys-splinter-cell-chaos-theory-playstation-2-front-cover.jpg',
        uncover_order: [7, 4, 8, 3],
    },
    '184': {
        content: '',
        submitted_by: '',
        answers: ["Fall Guys"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/9366561-fall-guys-playstation-4-front-cover.jpg',
        uncover_order: [2, 0, 4, 1],
    },
    '185': {
        content: '',
        submitted_by: '',
        answers: ["Mother 3"],
        affiliate_links: [],
        franchise: 'Mother',
        source: 'https://cdn.mobygames.com/covers/4283878-mother-3-game-boy-advance-front-cover.jpg',
        uncover_order: [3, 4, 5, 7],
    },
    '186': {
        content: '',
        submitted_by: '',
        answers: ["Silent Hill 2"],
        affiliate_links: [],
        franchise: 'Silent Hill',
        source: 'https://cdn.mobygames.com/covers/8885902-silent-hill-2-playstation-2-front-cover.jpg',
        uncover_order: [3, 5, 4, 2],
    },
    '187': {
        content: '',
        submitted_by: '',
        answers: ["Journey"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/8111422-journey-iphone-front-cover.jpg',
        uncover_order: [0, 1, 2, 8],
    },
    '188': {
        content: '',
        submitted_by: '',
        answers: ["Vampire Survivors"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/11181616-vampire-survivors-iphone-front-cover.jpg',
        uncover_order: [0, 3, 6, 2],
    },
    '189': {
        content: '',
        submitted_by: '',
        answers: ["Beyond Good & Evil"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/46610-beyond-good-evil-windows-front-cover.jpg',
        uncover_order: [7, 5, 4, 3],
    },
    '190': {
        content: '',
        submitted_by: '',
        answers: ["Middle-earth: Shadow of Mordor"],
        affiliate_links: [],
        franchise: 'The Lord of the Rings',
        source: 'https://cdn.mobygames.com/covers/826603-middle-earth-shadow-of-mordor-playstation-3-front-cover.jpg',
        uncover_order: [8, 7, 6, 3],
    },
    '191': {
        content: '',
        submitted_by: '',
        answers: ["Dark Souls III", "Dark Souls 3"],
        affiliate_links: [],
        franchise: 'Dark Souls',
        source: 'https://cdn.mobygames.com/covers/2942369-dark-souls-iii-playstation-4-front-cover.jpg',
        uncover_order: [8, 6, 4, 7],
    },
    '192': {
        content: '',
        submitted_by: '',
        answers: ["50 Cent: Blood on the Sand"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/4048421-50-cent-blood-on-the-sand-playstation-3-front-cover.jpg',
        uncover_order: [5, 7, 4, 3],
    },
    '193': {
        content: '',
        submitted_by: '',
        answers: ["Pokemon Legends: Arceus"],
        affiliate_links: [],
        franchise: 'Pokemon',
        source: 'https://cdn.mobygames.com/covers/10614715-pokemon-legends-arceus-nintendo-switch-front-cover.jpg',
        uncover_order: [2, 8, 7, 1],
    },
    '194': {
        content: '',
        submitted_by: '',
        answers: ["Enter the Gungeon"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.mobygames.com/covers/9463136-enter-the-gungeon-windows-front-cover.jpg',
        uncover_order: [3, 7, 6, 5],
    },
};
