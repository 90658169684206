// @flow
import React from 'react';
import SuggestionsListComponent from './SuggestionsListComponent';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../settings/useSettings';

type TProps = {
    shouldShowSuggestions: boolean,
    inputValue: string,
    activeSuggestionIndex: number,
    filteredSuggestionsList: Array<string>,
    onChangeFn: (e: Event) => void,
    onKeyDownFn: (key: KeyboardEvent) => void,
    onClickFn: (e: Event) => void,
    loading: boolean,
};

const AutoComplete = ({
    inputValue,
    shouldShowSuggestions,
    activeSuggestionIndex,
    filteredSuggestionsList,
    onChangeFn,
    onKeyDownFn,
    onClickFn,
    loading,
    placeholder,
}: TProps): React$Element<React$FragmentType> => {
    const { t } = useTranslation();
    const settings = useSettings();

    if (!placeholder) {
        placeholder = t('Search for a ') + settings.guess_item + '...';
    }
    return (
        <>
            <input
                className="game-input"
                spellCheck={false}
                type="text"
                placeholder={placeholder}
                onChange={onChangeFn}
                onKeyDown={onKeyDownFn}
                value={inputValue}
            ></input>
            {(shouldShowSuggestions && inputValue) || loading ? (
                <SuggestionsListComponent
                    filteredSuggestionsList={filteredSuggestionsList}
                    activeIndex={activeSuggestionIndex}
                    onClickFn={onClickFn}
                    loading={loading}
                />
            ) : null}
        </>
    );
};

export default AutoComplete;
