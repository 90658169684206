import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NavigateButton({ text, path, params, hash }) {
    const navigate = useNavigate();
    const navigateButtonStyle = {
        width: '245px',
        textAlign: 'center',
        backgroundColor: 'rgb(32, 135, 172)',
        margin: '5px',
    };

    return (
        <div
            style={navigateButtonStyle}
            className={'mainButton share-results-btn replay-previous-days'}
            onClick={() => {
                navigate({ pathname: path, search: params, hash: hash });
            }}
        >
            {text}
        </div>
    );
}
