import React, { useState } from 'react';
import Typewriter from './Typewriter';
import { RotateLoader } from 'react-spinners';

const ChatGPT = () => {
    const initalText =
        'Good day to you! My name is Guess the Game GPT. I have taken over today’s Guess the Game. You’ll have to ask me\n' +
        "questions to figure out what today’s game is. You may not know the name of the game but do not give up! You'll be able to figure it out from my hints if you're smart or feel free to do outside research! Here are some things to know: \n" +
        '1) This game has never been featured as a daily game before. 2) The name of todays game might be one word or multiple words. \n \n Good luck! I suggest your first question should be asking me: What is today’s game?';

    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState([]);
    const [threadId, setThreadId] = useState('');
    const [assistantId, setAssistantId] = useState('asst_dwmX4QXBivd76SwODLBCfDnA');
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);

    const postToServer = async () => {
        setIsRequestInProgress(true);
        // by console logging here it seems to work. something with react
        console.log({ inputValue } + ' ' + { assistantId } + ' ' + { threadId });

        try {
            // post the input value, thread_id, assistant_id
            const response = await fetch(
                'https://casebasedlearning-0a0d5d093d48.herokuapp.com/openai_stream_view_four',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ content: inputValue, assistant_id: assistantId, thread_id: threadId }),
                },
            );

            const data = await response.json();
            console.log(data.message);
            setMessages((prevMessages) => [...prevMessages, { text: data.message, type: 'response' }]);
            // Update the assistantId and threadId for the next request
            setAssistantId(data.assistant_id);
            setThreadId(data.thread_id);
        } catch (error) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    text: 'Sorry, your message did not go through. This is likely a problem with OpenAI, my maker. Try refreshing the conversation!',
                    type: 'response',
                },
            ]);
            console.error(error);
        } finally {
            setIsRequestInProgress(false); // Re-enable input box by indicating request has completed
        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = () => {
        if (!inputValue.trim()) return; // Prevents adding empty strings
        setMessages((prevMessages) => [...prevMessages, { text: inputValue, type: 'submission' }]);
        postToServer(inputValue);
        setInputValue(''); // Clears the input box after submission
    };

    return (
        <div style={{ paddingLeft: '8px', paddingRight: '8px' }} >
            <Typewriter text={initalText} delay={20} />
            <br />
            <br />
            <br />
            {messages.map((message, index) => (
                <div key={index} style={{ color: 'white', marginBottom: '10px' }}>
                    {message.type === 'submission' ? (
                        <span style={{ fontWeight: 'bold' }}>You:</span>
                    ) : (
                        <span style={{ fontWeight: 'bold', color: 'green' }}>GtG GPT:</span>
                    )}{' '}
                    <br /> {message.text}
                </div>
            ))}
            {isRequestInProgress && (
                <span className={'rotate'} style={{ marginLeft: '50%' }}>
                    {' '}
                    <RotateLoader color={'#064e3b'} loading={isRequestInProgress} size={5} />{' '}
                </span>
            )}
            <br />
            <br />
            <br />
            <div id="inputHoldings" style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                <input
                    className="game-input"
                    spellCheck={false}
                    type="text"
                    value={inputValue} // Controlled component
                    disabled={isRequestInProgress}
                    onChange={handleInputChange} // Updates state on change
                    style={{
                        width: '100%',
                        border: 'solid 1px #eee',
                        borderRadius: '5px',
                        padding: '10px',
                        fontSize: '14px',
                        height: '36px',
                    }}
                    placeholder={'Ask me a question'}
                ></input>
                <button
                    style={{ maxWidth: '150px', paddingLeft: '10px' }}
                    type="button"
                    className="mainButton submitButton"
                    onClick={handleSubmit} // Handles click event
                >
                    Ask GtG GPT
                </button>
            </div>
        </div>
    );
};

export default ChatGPT;
