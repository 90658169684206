// @flow
import React from 'react';
import { Toaster } from 'react-hot-toast';

const Toast = (): React$Element<React$FragmentType> => {

    return (
        <Toaster
            position="top-center"
            toastOptions={{
                className: 'global-toast',
            }}
        />
    );
};

export default Toast;


