import { loadLocalStorageItem } from '../../localStateUtils';
import { $NotNull } from '../../globals';
import type { GameSettings } from '../../settings/game_settings';

export const getInitialGameState = (originalSaveState: ?string) => {
    return originalSaveState ?? 'playing';
};

export const getInitialGuessList = (
    originalSaveState: ?string,
    puzzleId: string,
    settings: GameSettings,
): Array<string> => {
    return originalSaveState
        ? Array(settings.max_guesses)
              .fill('')
              .map((_, idx) => loadLocalStorageItem(puzzleId, `${settings.storage_keys.guess}${idx + 1}`) || '')
        : Array(settings.max_guesses).fill('');
};

export const getInitialGuesses = (originalSaveState: ?string, puzzleId: string, settings: GameSettings): number => {
    const initialGuessList = getInitialGuessList(originalSaveState, puzzleId, settings);
    return originalSaveState
        ? initialGuessList.reduce((accumulator, guess) => accumulator + (guess !== '' ? 1 : 0), 0)
        : 0;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const shakeInput = async () => {
    const gameInput = document.querySelector('input.game-input');

    $NotNull(gameInput).classList.add('shakeme');
    await delay(500);
    $NotNull(gameInput).classList.remove('shakeme');
};

// We need to keep this mapping for backwards compatibility reasons.
// The localstate data is stored as the non-index names
export const idxToLocalstateNameMapping = {
    0: 'onefers',
    1: 'twofers',
    2: 'threefers',
    3: 'quads',
    4: 'fivers',
    5: 'sixers',
};
