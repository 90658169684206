import React from 'react';
import { KOFI_URL } from '../constants';

export default function SubscribeOnKofiButton({ text }) {
    return (
        <div style={{ zIndex: 990, position: 'absolute' }}>
            <h2 className={'subscribe-on-kofi-btn'} style={{}}>
                <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                    {text ? text : 'Subscribe on Ko-Fi to view'}
                </a>
            </h2>
        </div>
    );
}
