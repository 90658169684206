// @flow

import { ADMIN_URL, EMAIL, SEARCH_ITEM_SUBMISSION_URL } from '../../constants';

import { useDispatch } from 'react-redux';
import type { TDispatch } from '../../store/store';

import React from 'react';
import { setModal } from '../../store/slices/uxSlice';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../settings/useSettings';

const AboutModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const settings = useSettings();
    const { modalTexts } = settings.TEXTS.modal.AboutModal;

    return (
        <div className={`modal about-modal ${direction}`}>
            <div className="about-modal-inner">
                <button
                    type="button"
                    className={'close-modal'}
                    onClick={() => {
                        dispatch(setModal('none'));
                    }}
                >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
                <h3 onClick={() => (window.location.href += `?${ADMIN_URL}=True`)}>{t('About')}</h3>
                <p>
                    {settings.website_url} is a daily puzzle game inspired by{' '}
                    <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noreferrer noopener">
                        Wordle
                    </a>
                    , Heardle &{' '}
                    <a href="https://framed.wtf/" target="_blank" rel="noreferrer noopener">
                        Framed.wtf
                    </a>
                    .
                </p>
                {modalTexts.map((text, index) => (
                    <p key={index}>
                        {typeof text === 'string' ? (
                            text
                        ) : (
                            <>
                                {text.beforeLink}
                                <a href={text.url} target="_blank" rel="noopener noreferrer">
                                    {text.linkText}
                                </a>
                                {text.afterLink}
                            </>
                        )}
                    </p>
                ))}

                <p>
                    Did you try searching for a {settings.guess_item.toLowerCase()} only to be disappointed that you
                    couldn't find it?{' '}
                    <a href={SEARCH_ITEM_SUBMISSION_URL} target="_blank" rel="noreferrer noopener">
                        Submit a {settings.guess_item.toLowerCase()} to our database
                    </a>
                </p>

                <p>
                    Notice any broken features or bugs? Please let me know what kind of device and browser you're using
                    and send us an email at <a href={'mailto:' + EMAIL}>{EMAIL}</a>.
                </p>
                <br />
                <p style={{ fontSize: '0.7em' }}>
                    Our copyright policy: Copyright for the images, games, songs or other displays is likely retained by
                    the publisher/producer and/or artist(s) who produced the item in question. It is believed that the
                    use of these images falls under United States fair use law. Any other uses of this image might be
                    copyright infringement. If you require that the image/game/puzzle or any other source be removed
                    from the site, please reach out via email.
                </p>
            </div>
        </div>
    );
};

export default AboutModal;
