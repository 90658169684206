// @flow

import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { AnswerUnion, GameSettings } from './game_settings';

export class BaseSettings implements GameSettings {
    static PATHS: Object;

    get paths(): Object {
        return this.constructor.PATHS;
    }

    routes(): React$Element<React$FragmentType> {
        throw new Error('Not Implemented');
    }

    puzzle_start_date: Date;
    website_url: string;
    title: string;
    website_title: string;
    parent_abbr: string;
    max_guesses: number;
    root_url: string;
    puzzle_type: PUZZLE_TYPES_TYPE;
    clipboard_tag: string;
    storage_keys: { guess: string, game_state: string };
    answers: AnswerUnion;
    website_emoji: string;
    guess_item: string;
    progress_ranks: { [string]: string };

    num_days_from_start_date(): number {
        const today = new Date();
        // $FlowIgnore
        const diffTime = Math.abs(today - this.puzzle_start_date);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    game_url(puzzleId: string): string {
        return `https://${this.website_url}${this.paths.puzzle.replace(':puzzleId', puzzleId)}`;
    }

    get_hint_text(imageIndex: number, puzzle: any, settings: any): string {
        return '';
    }

    puzzle_url(): string {
        return this.paths.puzzle;
    }

    getParentTitle(): string {
        return this.title; // Default implementation returns its own title
    }

    getCoverTitle(): string {
        return 'Guess The Cover'; // Default implementation
    }

    TEXTS: Object;
}
