
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { TDispatch } from '../../../store/store';
import { setModal } from '../../../store/slices/uxSlice';
import { saveMustRedirect } from '../../../localStateUtils';

export function useRedirectPuzzle(guessNumber) {

    const dispatch: TDispatch = useDispatch();
    const isEmbeddedExternally = useSelector((state: State) => state.ux.isEmbeddedExternally);

    useEffect(() => {
        if (guessNumber === 3) {
            // Open redirect modal after 3rd guess
            if (isEmbeddedExternally) {
                dispatch(setModal('redirect'));
                saveMustRedirect(true);
            }
        }
    }, [dispatch, isEmbeddedExternally, guessNumber]);

}
