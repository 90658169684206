// @flow
import { KOFI_URL, SEARCH_ITEM_SUBMISSION_URL } from '../../constants';
import { setModal } from '../../store/slices/uxSlice';

import { useDispatch } from 'react-redux';

import React from 'react';
import type { TDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../settings/useSettings';

const LoveModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const { t } = useTranslation();
    const settings = useSettings();

    return (
        <div className="modal love-modal">
            <div className="love-modal-inner">
                <button
                    type="button"
                    className={'close-modal'}
                    onClick={() => {
                        dispatch(setModal('none'));
                    }}
                >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>

                <h3>{t('Support Us')}</h3>

                <p>
                    I hope you enjoy this daily puzzle game! If it has brightened your day, the best way you can support
                    us is to share the site with a friend, post your daily score on reddit, discord or your favorite
                    website, or share {settings.website_title} with your favorite news outlet!
                </p>
                <>
                    <h4>{t('Support Us on Ko-fi')}</h4>

                    <p className="login">
                        If you would like to support {settings.website_title} development, you can make a donation or
                        become a monthly member at{' '}
                        <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                            <span style={{ whiteSpace: 'nowrap' }}>Ko-Fi</span>
                        </a>
                        <br />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                                <img src={'/img/kofi_s_tag_dark.png'} alt="Ko-Fi" style={{ height: '50px' }} />
                            </a>
                        </div>
                    </p>
                </>
                <>
                    <p>
                        Did you try searching for a {settings.guess_item.toLowerCase()} only to be disappointed that you
                        couldn't find it?{' '}
                        <a href={SEARCH_ITEM_SUBMISSION_URL} target="_blank" rel="noreferrer noopener">
                            Submit a {settings.guess_item.toLowerCase()} to our database
                        </a>
                    </p>
                </>
            </div>
        </div>
    );
};

export default LoveModal;
