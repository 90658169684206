import { useSelector } from 'react-redux';
import { settingsFactory } from './settingsFactory';
import type { State } from '../store/types';
import type { GameSettings } from './game_settings';
import type { PUZZLE_TYPES_TYPE } from '../constants';

export function useSettings(overridePuzzleType?: PUZZLE_TYPES_TYPE): GameSettings {
    const { puzzleType: storePuzzleType } = useSelector((state: State) => state.ux);
    const puzzleType = overridePuzzleType || storePuzzleType;
    return settingsFactory(puzzleType);
}