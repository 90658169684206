import { isAnswerCorrect } from '../globals';
import React, { useState } from 'react';
import ShareGuesses from './ShareGuesses';

const ShowGuesses = ({ guessList, answer, puzzleId }): React$Element<'div'> => {
    const [showGuesses, setShowGuesses] = useState(false);

    const toggleShowGuesses = () => {
        setShowGuesses(!showGuesses);
    };

    //style that will align end-of-game-guesses-summary to the left
    const style = {
        textAlign: 'center',
    };

    return (
        <>
            <p
                className="end-of-game-guesses-summary-label"
                onClick={() => {
                    toggleShowGuesses();
                }}
            >
                {showGuesses ? 'Hide' : 'Show'} Guesses
            </p>
            <div style={style}>
                {showGuesses &&
                    guessList.map((guess, index) => {
                        return guess === '' ? null : (
                            <div key={index} className="end-of-game-guesses-summary">
                                <span>
                                    {isAnswerCorrect(guess, answer) && '✅'}
                                    {!isAnswerCorrect(guess, answer) && '❌'}
                                </span>
                                {guess}
                            </div>
                        );
                    })}
                {showGuesses && <ShareGuesses guessList={guessList} puzzleId={puzzleId} />}
            </div>
        </>
    );
};
export default ShowGuesses;
