// @flow

import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect, useState, useMemo } from 'react';
import type { State } from '../store/types';
import { copyPreviousPuzzlesToClipboard } from '../prevPuzzleUtils';
import { copyToClipboard, goToDay } from '../clientUtils';
import { useNavigate, useLocation } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import { setPuzzleType } from '../store/slices/uxSlice';
import type { TDispatch } from '../store/store';
import { useSettings } from '../settings/useSettings';
import { PUZZLE_TYPES } from '../constants';
import axios from 'axios';
import { API_BASE } from '../globals';

declare var __GTA_BUILD__: boolean;
declare var __GTB_BUILD__: boolean;

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const NUM_OF_ADS = 3;
const MIN_GAP_BETWEEN_ADS = 5;
// min_puzzles_for_ad_gaps = ADS + GAP * (ADS - 1) + GAP
//                         = ADS + GAP * ADS
//                         = (GAP + 1) * ADS
const min_puzzles_for_ad_gaps = (MIN_GAP_BETWEEN_ADS + 1) * NUM_OF_ADS;

const PreviousPuzzles = (): React$Element<'div'> => {
    const navigate = useNavigate();
    const query = useQuery();
    const dispatch: TDispatch = useDispatch();
    useEffect(() => {
        let puzzleType = query.get('puzzleType');
        if (puzzleType) {
            // $FlowIgnore - puzzleType should match literals
            dispatch(setPuzzleType(puzzleType));
        }
    });
    const settings = useSettings();

    const userState = useSelector((state: State) => state.user);
    const previousPuzzles = useSelector((state: State) => state.stats[settings.puzzle_type].previousPuzzles);

    const { t } = useTranslation();
    // States to manage ad placement
    const [adPositions, setAdPositions] = useState([]);
    const [wasAdSetup, setWasAdSetup] = useState(false);
    const [longResponse, setLongResponse] = useState(false);

    useEffect(() => {
        const getLongResponse = async () => {
            try {
                const response = await axios.get(`${API_BASE}/api/get_og_images/` + '343' + '/');
                setLongResponse(true);
            } catch (error) {
                console.error('error fetching resonse: ', error);
                setLongResponse(true); // Proceed anyhow even if ogImages is unavailable
            }
        };

        getLongResponse();
    }, []);

    useEffect(() => {
        if (adPositions.length > 0) {
            console.log('Adding ads');
            const adBuilds = [
                {
                    condition: __GTA_BUILD__,
                    adNames: ['guesstheaudio_incontent_1', 'guesstheaudio_incontent_2', 'guesstheaudio_incontent_3'],
                },
                {
                    condition: __GTB_BUILD__,
                    adNames: ['guessthebook_incontent_1', 'guessthebook_incontent_2', 'guessthebook_incontent_3'],
                },
            ];

            adBuilds.forEach((build) => {
                if (build.condition) {
                    build.adNames.forEach((adName, index) => {
                        console.log(
                            'positions has index',
                            index,
                            "positoin index slot #'s",
                            Array.from(adPositions)[index],
                        );
                        let slotId = `ad-slot-${Array.from(adPositions)[index]}`;
                        console.log(slotId);
                        window.freestar.config.enabled_slots.push({
                            placementName: adName,
                            slotId: slotId,
                        });
                    });
                }
            });

            setWasAdSetup(true);
        }
    }, [adPositions]);

    useEffect(() => {
        if (longResponse && adPositions.length === 0 && !userState.user) {
            console.log('deciding position');
            const numberOfPuzzles = previousPuzzles.length;
            let positions = new Set();
            // Randomly choose NUM_OF_ADS different positions to place ads, assuming there are at least NUM_OF_ADS+1 puzzles
            while (positions.size < NUM_OF_ADS && numberOfPuzzles > NUM_OF_ADS) {
                const randomPosition = Math.floor(Math.random() * numberOfPuzzles);
                if (numberOfPuzzles >= min_puzzles_for_ad_gaps) {
                    const sufficientGaps = Array.from(positions).every((pos) => Math.abs(pos - randomPosition) >= MIN_GAP_BETWEEN_ADS)
                    if (!sufficientGaps) {
                        continue;
                    }
                }
                positions.add(randomPosition);
            }

            setAdPositions([...positions]);
        }
    }, [adPositions, longResponse, previousPuzzles.length, userState.user, wasAdSetup]);

    console.log('Puzzle TYpe: ', query.get('puzzleType'));

    const showRandomButton = previousPuzzles
        .map((prevGame, index) => (prevGame.result === 'unplayed' ? index : -1))
        .filter((index) => index !== -1);

    let [shareButtonText, setButtonText] = useState('Share All Games');

    const buttonStyle = { margin: '3px', fontSize: '20px' };

    useEffect(() => {
        const href = window.location.href;
        if (href.includes('#')) {
            const id = `${href.substring(href.indexOf('#') + 1)}`;
            const anchor = document.getElementById(id);
            if (anchor) {
                anchor.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    const puzzlePageType = useMemo(() => {
        // as of 8/27/24 only guess_the_cover is a child puzzle
        console.log('ischildpuzzle', settings.isChildPuzzle);
        return settings.isChildPuzzle ? 'guess_the_cover' : 'puzzle';
    }, [settings]);

    return (
        <div className="previous-games" style={{ display: 'contents' }}>
            <div className="back-to-today">
                <button
                    className="mainButton share-results-btn back-to-today-btn"
                    onClick={() => {
                        navigate(settings.root_url);
                    }}
                >
                    {t('Back To Current Game')}
                </button>
            </div>
            <div className="prev-game-legend">
                <div className="emoji-track qmark-emojitrack">
                    <p className="unplayed-qmark">❓</p>
                    <p>&nbsp;=&nbsp;{t('Unplayed')}</p>
                </div>

                <div className="emoji-track">
                    <div className="guess-cube"></div>
                    <p>&nbsp;=&nbsp;{t('Skipped')}</p>
                </div>

                <div className="emoji-track">
                    <div className="guess-cube fail"></div>
                    <p>&nbsp;=&nbsp;{t('Incorrect')}</p>
                </div>

                <div className="emoji-track">
                    <div className="guess-cube success"></div>
                    <p>&nbsp;=&nbsp;{t('Correct')}</p>
                </div>
            </div>
            {previousPuzzles.map((prevGame, index: number) => (
                <React.Fragment key={index + 1}>
                    <div
                        className={'prev-game-result-row ' + (index + 1)}
                        id={index + 1}
                        onClick={() => {
                            goToDay(index + 1, puzzlePageType, settings);
                        }}
                    >
                        <div className="play-prev-game-btn">
                            <button className="play-prev-day-btn">
                                {t('Play')} <span className="day-text">{t('Day')}</span> #{index + 1}
                            </button>
                        </div>
                        <div className="emoji-track prev-game-emoji-track">
                            {prevGame.result === 'unplayed' &&
                                Array(settings.max_guesses)
                                    .fill(0)
                                    .map((_, index) => (
                                        <span key={index} className="unplayed-qmark">
                                            ❓
                                        </span>
                                    ))}

                            {['success', 'fail'].includes(prevGame.result) && (
                                <>
                                    {prevGame.cubes.map((cube, index_2) => (
                                        <div
                                            key={index_2}
                                            className={`guess-cube ${
                                                cube === 'f'
                                                    ? 'fail'
                                                    : cube === 's'
                                                    ? 'success'
                                                    : cube === 'p'
                                                    ? 'partial'
                                                    : ''
                                            }`}
                                        >
                                            {/* if cube === 's' then show index_2 */}
                                            {cube === 's' && (
                                                <span className="cube-index" style={{ color: 'rgba(5, 150, 105, 1)' }}>
                                                    G{index_2 + 1}
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        {prevGame.result === 'unplayed' && (
                            <div className="prev-game-result pending">
                                ⌛<span className="result-text-supplementary"> {t('Unplayed')}! 🚨</span>
                            </div>
                        )}
                        {prevGame.result === 'success' && (
                            <div className="prev-game-result success">
                                ✅
                                <span className="result-text-supplementary">
                                    <Marquee style={{ backgroundColor: 'grey', color: 'grey' }}>
                                        {prevGame.answer}
                                    </Marquee>
                                </span>
                            </div>
                        )}
                        {prevGame.result === 'fail' && prevGame.skippedAll === true && (
                            <div className="prev-game-result fail">
                                ❌<span className="result-text-supplementary"> {t('Skipped all')} 😔</span>
                            </div>
                        )}
                        {prevGame.result === 'fail' && prevGame.skippedAll === false && (
                            <div className="prev-game-result fail">
                                ❌<span className="result-text-supplementary"> {t('Failed')} 😔</span>
                            </div>
                        )}
                    </div>
                    {adPositions.includes(index) && (
                        <div
                            key={`ad-slot-${index}`}
                            id={`ad-slot-${index}`}
                            align="center"
                            data-freestar-ad={`__512x512`}
                        ></div>
                    )}
                </React.Fragment>
            ))}
            <div
                style={{
                    display: 'flex',
                    postion: 'relative',
                    margin: '0 auto',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    paddingBottom: '100px',
                }}
            >
                <button
                    className="mainButton share-results-btn"
                    style={buttonStyle}
                    onClick={() => {
                        let text = copyPreviousPuzzlesToClipboard(previousPuzzles, settings.max_guesses);
                        text = `${settings.clipboard_tag}\n\n${text}\n\nhttps://${settings.website_url}/`;
                        copyToClipboard(text);
                        setButtonText('Copied');
                        setTimeout(() => {
                            setButtonText('Share All Games');
                        }, 2000);
                    }}
                >
                    {shareButtonText}
                </button>

                {/* play random game button */}
                {/* if player has played all days dont show the button */}
                {/* if player has played random day pick another one*/}
                {showRandomButton.length > 0 && (
                    <button
                        className="mainButton iconButton"
                        style={buttonStyle}
                        onClick={() => {
                            //select a random enry in showRandomButton
                            let randomElement = showRandomButton[Math.floor(Math.random() * showRandomButton.length)];
                            goToDay(randomElement + 1, puzzlePageType, settings);
                        }}
                    >
                        {t('Play Random Game')}
                    </button>
                )}
            </div>
        </div>
    );
};

export default PreviousPuzzles;
