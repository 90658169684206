// @flow

import PreviousPuzzles from '../components/PreviousPuzzles';
import { type GameSettings } from './game_settings';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import ResetPuzzle from '../components/ResetPuzzle';
import { PUZZLE_TYPES } from '../constants';
import type { PUZZLE_TYPES_TYPE } from '../constants';
import GTBPuzzle from '../components/Puzzle/GTBPuzzle';
import Stats from '../components/Stats';
import { answers } from '../puzzles/gtb_puzzles';
import type { AnswerUnion } from './game_settings';
import Setting from '../components/Setting';
import { BaseSettings } from './BaseSettings';
import type { TGTBAnswer } from '../puzzles/gtb_puzzles';

export class GTBSettings extends BaseSettings {
    static PATHS: {
        email_callback: string,
        previous_games: string,
        puzzle: string,
        reset: string,
        setting: string,
        stats: string,
        ...
    } = {
        puzzle: '/p/:puzzleId',
        previous_games: '/previous-games',
        reset: '/reset/:puzzleId',
        stats: '/stats/:puzzleId',
        email_callback: '/email_callback/',
        setting: '/setting',
    };

    routes(): React$Element<React$FragmentType> {
        return (
            <>
                <Route element={<GTBPuzzle />} path="/" />
                <Route path={GTBSettings.PATHS.puzzle} element={<GTBPuzzle />} />
                <Route path={GTBSettings.PATHS.previous_games} element={<PreviousPuzzles />} />
                <Route path={GTBSettings.PATHS.stats} element={<Stats />} />
                <Route path={GTBSettings.PATHS.reset} element={<ResetPuzzle />} />
                <Route path={GTBSettings.PATHS.setting} element={<Setting />} />
                <Route path={GTBSettings.PATHS.email_callback} element={<Setting fromEmailCallback={true} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </>
        );
    }

    puzzle_start_date: Date = new Date('7/24/2023');

    website_url: string = 'GuessTheBook.app';
    title: string = 'Guess The Book';
    website_title: string = 'Guess The Book'; // except for GTG this should be the same as title
    parent_abbr: string = 'gtb';

    max_guesses: number = 6;
    root_url: string = '/';
    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTB;
    clipboard_tag: string = '#GuessTheBook';
    storage_keys: { guess: string, game_state: string } = { guess: 'guess', game_state: 'gamestate' };
    answers: AnswerUnion = answers;
    website_emoji: string = '📕';
    guess_item: string = 'Book';

    progress_ranks: { [string]: string } = {
        '0': 'Rookie Reader',
        '5': 'Book Enthusiast',
        '10': 'Literary Explorer',
        '20': 'Story Sleuth',
        '40': 'Pro Reader',
        '80': 'Insightful Bookworm',
        '150': 'Page Virtuoso',
        '200': 'Master Reader',
        '250': 'Book Guessing Guru',
        '300': 'Distinguished Book Detective',
        '350': 'Elite Reader',
        '400': 'Exalted Book Guru',
        '430': 'Extraordinary Reader',
        '470': 'Champion of Chapters',
        '500': 'Prodigious Book Guesser',
        '530': 'Masterful Mind of Literature',
        '560': 'Superior Story Strategist',
        '600': 'Grandmaster Reader',
        '630': 'Supreme Literary Savant',
        '700': 'Sovereign of Guess the Book',
        '750': 'Grand Book Guesser',
        '800': 'Ultimate Book Connoisseur',
        '900': 'Eternal Expert of Books',
        '1000': 'Omni-Reader',
        '1100': 'Galactic Guru of Literature',
        '1300': 'Legendary Book Oracle',
    };

    // $FlowIgnore
    get_hint_text(imageIndex: number, puzzle: TGTBAnswer, settings: GameSettings): string {
        const HINT_1 = '# of Goodreads Ratings: ';
        const HINT_2 = 'Genre: ';
        const HINT_3 = 'Release Year: ';
        const HINT_4 = 'Author: ';
        const HINT_5 = 'Name of a Character: ';
        const HINT_6 = 'Plot: ';
        switch (imageIndex) {
            case 1:
                return `${HINT_1} ${puzzle.goodreads_ratings}`;
            case 2:
                return `${HINT_2} ${puzzle.genre}`;
            case 3:
                return `${HINT_3} ${puzzle.release_year}`;
            case 4:
                return `${HINT_4} ${puzzle.author}`;
            case 5:
                return `${HINT_5} ${puzzle.character}`;
            case 6:
                return `${HINT_6} ${puzzle.plot}`;
            default:
                return '';
        }
    }

    TEXTS: { modal: { AboutModal: { modalTexts: Array<string>, ... }, ... }, ... } = {
        modal: {
            AboutModal: {
                modalTexts: [
                    `Every day a new ${this.guess_item.toLowerCase()} is selected and your goal is to guess what book it is! You will have 6 chances to guess from 6 quotes along with 6 hints.`,
                    'We try to have a selection of books from different genres, years, and authors. This ranges from the books you read as a kid to your parents books as a kid.',
                ],
            },
            HowToPlayModal: {
                modalTexts: [
                    'On every guess you will be presented with a snippet of a book and a hint.',
                    'Search for the book that you think it is.',
                    'Click skip to move on to the next snippet & hint.',
                    'If you get it wrong, we will reveal an additional snippet & book.',
                    `You get ${this.max_guesses} guesses in total.`,
                ],
            },
        },
    };
}
