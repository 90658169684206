// @flow
import { configureStore } from '@reduxjs/toolkit';
import uxSlice from './slices/uxSlice';
import type { Store } from 'redux';
import type { State } from './types';
import type { TUXActions } from './slices/uxSlice';
import statSlice from './slices/statSlice';
import type { TStatActions } from './slices/statSlice';
import type { TGameActions } from './slices/puzzleSlice';
import type { TUserActions } from './slices/userSlice';
import userSlice from './slices/userSlice';

const store: Store<State, TUXActions | TStatActions | TGameActions | TUserActions> = configureStore({
    reducer: {
        ux: uxSlice.reducer,
        stats: statSlice.reducer,
        user: userSlice.reducer,
    },
});

export const { dispatch } = store;
export type TDispatch = typeof dispatch;

export default store;
