// @flow

import React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoComplete from '../AutoComplete';
import { autocompleteSearchThunk, submitGuessThunk } from '../../store/slices/puzzleSlice';
import { debounce } from 'lodash';
import ShareResult from '../ShareResult';
import ShowGuesses from '../ShowGuesses';
import KofiButton from '../Kofi';
import { createSearchParams, useParams } from 'react-router-dom';
import { useRedirectPuzzle } from './hooks/puzzleHooks';
import Switch from 'react-switch';

import { populateGameList } from '../../puzzleUtils';
import { useDispatch, useSelector } from 'react-redux';
import type { TDispatch } from '../../store/store';
import { $NotNull, DEV_MODE, isAnswerCorrect, calculatePuzzleDate } from '../../globals';
import { calcStats, loadLocalStorageItem, saveLocalStorageItem, loadMustRedirect } from '../../localStateUtils';
import { setPuzzleType } from '../../store/slices/uxSlice';
import { KOFI_URL } from '../../constants';
import { calcPreviousPuzzleResults } from '../../prevPuzzleUtils';
import { setPreviousPuzzles, setStats } from '../../store/slices/statSlice';
import type { State } from '../../store/types';
import NavigateButton from '../NavigateButton';
import Footer from '../Footer';
import { useSettings } from '../../settings/useSettings';
import type { AnswerUnion, GameSettings } from '../../settings/game_settings';
import { getInitialGameState, getInitialGuesses, getInitialGuessList } from './PuzzleUtils';
import FinalAnswer from '../FinalAnswer';
import FinalMessage from '../FinalMessage';
import type { PUZZLE_TYPES_TYPE } from '../../constants';

const getInitialFranchiseMatchList = (originalSaveState: ?string, puzzleId: string, settings: GameSettings) => {
    return originalSaveState
        ? [1, 2, 3, 4, 5, 6].map(
              (val) => loadLocalStorageItem(puzzleId, `${settings.storage_keys.guess}${val}_f`) === 'true',
          )
        : Array(6).fill(false);
};

const getInitialBlurIdxs = (puzzleId: string, originalSaveState: ?string, settings: GameSettings): Array<number> => {
    let blurIdxs = Array(9).fill(0).map((_, i) => i);
    if (!originalSaveState) {
        return blurIdxs;
    }
    if (originalSaveState === 'win' || originalSaveState === 'lose') {
        return [];
    }
    const numGuesses = getInitialGuesses(puzzleId, originalSaveState, settings);
    for (let i = 0; i < numGuesses; i++) {
        blurIdxs = blurIdxs.filter(
            // $FlowIgnore
            (val) => val !== settings.answers[puzzleId].uncover_order[i],
        );
    }
    return blurIdxs;
};

const getBlurIdxsFromGuess = (puzzleId: string, settings: GameSettings, guess: number): Array<number> => {
    const puzzleConfig = settings.answers[puzzleId];
    console.log('puzzleConfig', puzzleConfig)
    let blurIdxs = Array(9).fill(0).map((_, i) => i);
    if (puzzleConfig.uncover_order && guess > 0) {
        const indexes = puzzleConfig.uncover_order.slice(0, guess);
        blurIdxs = blurIdxs.filter(
            // $FlowIgnore
            (val) => !indexes.includes(val),
        );
    }
    return blurIdxs;
};

type TParams = {
    puzzleType: PUZZLE_TYPES_TYPE,
    answers: AnswerUnion,
};

const GTCPuzzle = ({ puzzleType, answers }: TParams): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();

    let { puzzleId } = useParams();
    puzzleId = puzzleId ?? settings.num_days_from_start_date().toString();

    const { t } = useTranslation();

    const userState = useSelector((state: State) => state.user);

    const savedState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
    if (savedState == null && settings.puzzle_type === puzzleType) {
        // Populate new state
        saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'playing');
    }

    const myImage = useRef<?HTMLImageElement>(null);
    const myCanvas = useRef<?HTMLCanvasElement>(null);

    const [imgLoaded, setImgLoaded] = useState(false);
    const [guessInputValue, setGuessInputValue] = useState('');

    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [filteredSuggestionsMetadata, setFilteredSuggestionsMetadata] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [previousGuesses, setPreviousGuesses] = useState(Object.create(null));
    const [ACloading, setACloading] = useState(false);

    const [gameState, setGameState] = useState(() => getInitialGameState(savedState));
    const [guessNum, setGuessNum] = useState(() => getInitialGuesses(savedState, puzzleId, settings));
    const [blurredIdxs, setBlurredIdxs] = useState(() => getInitialBlurIdxs(puzzleId, savedState, settings));
    const [guessList, setGuessList] = useState(() => getInitialGuessList(savedState, puzzleId, settings));
    const [franchiseMatch, setFranchiseMatch] = useState(() =>
        getInitialFranchiseMatchList(savedState, puzzleId, settings),
    );

    const [showBlur, setShowBlur] = useState(false);
    const [blurRangeIndex, setBlurRangeIndex] = useState(0);
    const rangeBlurIdxs = useMemo(() => getBlurIdxsFromGuess(puzzleId, settings, blurRangeIndex), [puzzleId, settings, blurRangeIndex]);

    const answer = answers[puzzleId].answers;
    const franchise = $NotNull(answers[puzzleId].franchise);

    const stateGuesses = useSelector((state: State) => state.stats[puzzleType].guesses);
    useEffect(() => {
        const savedState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
        setGameState(getInitialGameState(savedState));
        setGuessNum(getInitialGuesses(savedState, puzzleId, settings));
        setGuessList(getInitialGuessList(savedState, puzzleId, settings));
        setFranchiseMatch(getInitialFranchiseMatchList(savedState, puzzleId, settings));
        setBlurredIdxs(getInitialBlurIdxs(puzzleId, savedState, settings));
    }, [stateGuesses, puzzleId, settings]);

    useRedirectPuzzle(guessNum);

    useEffect(() => {
        dispatch(setPuzzleType(puzzleType));
    });

    const blurSegments = (canvas: HTMLCanvasElement, image: HTMLImageElement, segments: Array<number>) => {
        console.log('blurring segments');
        // Create a temporary canvas to apply blur to.
        let tmpCanvas = document.createElement('canvas');
        tmpCanvas.height = canvas.height;
        tmpCanvas.width = canvas.width;

        let ctx1 = tmpCanvas.getContext('2d');
        ctx1.clearRect(0, 0, tmpCanvas.width, tmpCanvas.height);
        ctx1.filter = 'blur(50px)';
        ctx1.drawImage(image, 0, 0);

        let ctx2 = canvas.getContext('2d');
        for (let i = 0; i < 9; i++) {
            let x = i % 3;
            let y = Math.floor(i / 3);
            // Safari doesn't like it if you overflow a dimension, so let's cap it
            const calcSize = (offset: number, dimension: number) =>
                (offset * dimension) / 3 + dimension / 3 + 1 > dimension ? dimension / 3 : dimension / 3 + 1;
            if (segments.includes(i)) {
                ctx2.drawImage(
                    tmpCanvas,
                    (x * tmpCanvas.width) / 3,
                    (y * tmpCanvas.height) / 3,
                    calcSize(x, tmpCanvas.width),
                    calcSize(y, tmpCanvas.height),
                    (x * image.width) / 3,
                    (y * image.height) / 3,
                    calcSize(x, image.width),
                    calcSize(y, image.height),
                );
            } else {
                ctx2.drawImage(
                    image,
                    (x * image.width) / 3,
                    (y * image.height) / 3,

                    calcSize(x, image.width),
                    calcSize(y, image.height),
                    (x * image.width) / 3,
                    (y * image.height) / 3,

                    calcSize(x, image.width),
                    calcSize(y, image.height),
                );
            }
        }
    };

    const onImageLoad = () => {
        let image = $NotNull(myImage.current);
        let canvas = $NotNull(myCanvas.current);

        canvas.width = image.width;
        canvas.height = image.height;

        setImgLoaded(true);
    };

    useEffect(() => {
        if (imgLoaded) {
            let image = $NotNull(myImage.current);
            let canvas = $NotNull(myCanvas.current);
            let blurredIndexes;

            if (gameState === 'playing') {
                blurredIndexes = blurredIdxs;
            }
            else if (showBlur) {
                blurredIndexes = rangeBlurIdxs;
            }
            else {
                blurredIndexes = [];
            }
            blurSegments(canvas, image, blurredIndexes);
        }
    }, [imgLoaded, blurredIdxs, rangeBlurIdxs, gameState, showBlur]);

    const submitGuess = (e: Event | null, isSkipGuess: boolean) => {
        const mustRedirect = loadMustRedirect();
        if (mustRedirect) {
            // Game cannot proceed even if users remove the redirect modal manually, uBlock etc
            return;
        }

        let currentInputValue;
        if (isSkipGuess !== undefined) {
            currentInputValue = 'Skipped!';
        } else {
            currentInputValue = guessInputValue;
        }

        if (guessInputValue === '' && isSkipGuess !== true) return;

        const guessFranchise = filteredSuggestionsMetadata.filter(
            (suggestion) => suggestion.title.toLowerCase() === currentInputValue.toLowerCase(),
        )[0]?.franchise;
        const guessFranchiseMatch = guessFranchise === franchise;

        let newGuessNum = 0;
        for (let i = 0; i < guessList.length; i++) {
            if (guessList[i] === '') {
                let newArr = [...guessList];
                newArr[i] = currentInputValue;
                setGuessList(newArr);
                let newFranchiseMatch = [...franchiseMatch];
                newFranchiseMatch[i] = guessFranchiseMatch;
                setFranchiseMatch(newFranchiseMatch);
                saveLocalStorageItem(
                    puzzleId,
                    `${settings.storage_keys.guess}${i + 1}_f`,
                    guessFranchiseMatch.toString(),
                );
                saveLocalStorageItem(puzzleId, `${settings.storage_keys.guess}${i + 1}`, currentInputValue);
                newGuessNum = i + 1;

                setGuessNum(newGuessNum);
                if (isAnswerCorrect(currentInputValue, answer)) {
                    setGameState('win');
                    saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'win');
                    setBlurredIdxs([]);
                } else {
                    // If we're on guess 1-5, then shake and go to the next image
                    if (i < guessList.length - 1) {
                        let newBlurredIdx = [...blurredIdxs];
                        const newGuessNumConst = newGuessNum;
                        newBlurredIdx = newBlurredIdx.filter(
                            // $FlowIgnore
                            (val) => val !== answers[puzzleId].uncover_order[newGuessNumConst - 1],
                        );
                        setBlurredIdxs(newBlurredIdx);
                    } else {
                        // If we're on guess 6, then they lost
                        setGameState('lose');
                        saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'lose');
                        setBlurredIdxs([]);
                    }
                }
                break;
            }
        }
        const initialStats = calcStats(settings);
        dispatch(
            setStats({
                won: initialStats.totalWon,
                played: initialStats.totalPlayed,
                currentStreak: initialStats.currentStreak,
                maxStreak: initialStats.maxStreak,
                puzzleType: puzzleType,
            }),
        );
        dispatch(
            setPreviousPuzzles({
                previousPuzzles: calcPreviousPuzzleResults(settings).previousPuzzles,
                puzzleType: puzzleType,
            }),
        );

        setGuessInputValue('');

        dispatch(
            submitGuessThunk({
                puzzleId: parseInt(puzzleId),
                guessNum: guessNum + 1,
                guess: currentInputValue,
                puzzleType: puzzleType,
            }),
        );
    };

    const skipGuess = () => {
        submitGuess(null, true);
    };

    const debouncedSave = useMemo(
        (userInput, previousGuesses) =>
            debounce((userInput, previousGuesses) => {
                const delayTimer = setTimeout(() => {
                    const unLinked = populateGameList(answers).filter(
                        (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
                    );
                    setFilteredSuggestions(unLinked);
                    setActiveSuggestionIndex(0);
                    setShowSuggestions(true);
                    setACloading(false);
                }, 3000);

                dispatch(
                    autocompleteSearchThunk({
                        userInput: userInput,
                        puzzleId: puzzleId,
                        itemType: settings.guess_item.toLowerCase(),
                        puzzleType: puzzleType,
                    }),
                )
                    .unwrap()
                    .then((response) => {
                        clearTimeout(delayTimer); // Cancel the timeout
                        const unLinked = response.map((result) => result.title);
                        setFilteredSuggestions(unLinked);
                        setFilteredSuggestionsMetadata(response);
                        setActiveSuggestionIndex(0);
                        setShowSuggestions(true);

                        // store unlinked in previous guesses
                        setPreviousGuesses({ ...previousGuesses, [userInput]: unLinked });
                        setACloading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching autocomplete results:', error);
                        setACloading(false);
                    });
            }, 290),
        [dispatch, puzzleId, settings, puzzleType, answers],
    );

    const onChange = (e: Event) => {
        // $FlowIgnore target has value
        const userInput = e.target.value;
        setGuessInputValue(userInput);
        if (userInput.length <= 2) {
            setFilteredSuggestions([]);
            setFilteredSuggestionsMetadata([]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
            return;
        }
        // check if userInput is in previous guesses
        if (previousGuesses[userInput]) {
            debouncedSave.cancel();
            console.log('already searched this');
            setFilteredSuggestions(previousGuesses[userInput]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
            setACloading(false);
            return;
        }
        setACloading(true);
        debouncedSave(userInput, previousGuesses);
    };

    const onKeyDown = (key: KeyboardEvent) => {
        if (key.keyCode === 13 || key.keyCode === 9) {
            if (filteredSuggestions.length > 0) {
                setGuessInputValue(filteredSuggestions[activeSuggestionIndex]);
            }
            setShowSuggestions(false);
        }
    };

    const onClick = (e: Event) => {
        setFilteredSuggestions([]);
        // $FlowIgnore target has innertext
        setGuessInputValue(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    };

    const puzzleDate = calculatePuzzleDate(puzzleId, settings);
    const showPuzzleDate = puzzleDate !== new Date().toDateString();

    return (
        <div className="current-game">
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {settings.title} #{puzzleId}
                <br />
                {showPuzzleDate && puzzleDate}
            </div>
            <div className="Screenshots" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="image-area" style={{ display: 'flex', justifyContent: 'center', height: '400px' }}>
                    {/*check if userState.user exist , if not, then display an error message over this entire div */}
                    {!userState.user && !DEV_MODE ? (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                padding: '20px',
                                fontSize: '13px',
                                zIndex: '100',
                                width: '85%',
                                height: '400px',
                                backgroundColor: '#0f102a',
                            }}
                        >
                            <h2 style={{ color: '#eee' }}>Guess The Cover is currently for premium members!</h2>
                            <span style={{ color: '#eee' }}>
                                Become a premium member by donating or subscribing on Ko-Fi and receive additional
                                benefits:
                                <ul>
                                    <li>Access to Guess The Cover</li>
                                    <li>Automatic Stat Syncing across multiple devices</li>
                                    <li>No Ads</li>
                                    <li>Access to Guess The Cover Previous Puzzles</li>
                                    <li>Export Stats</li>
                                </ul>
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                                        <img src={'/img/kofi_s_tag_dark.png'} alt="Ko-Fi" style={{ height: '50px' }} />
                                    </a>
                                </div>
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                    <img
                        ref={myImage}
                        crossOrigin="anonymous"
                        src={`/${settings.puzzle_type}_games/${puzzleId}/1.webp`}
                        alt="guess the cover"
                        // height="400px"
                        onLoad={() => onImageLoad()}
                        style={{ display: 'none' }}
                    />
                    <canvas ref={myCanvas} />
                </div>
            </div>
            {gameState !== 'playing' && (
                <div className="result">
                    <div className="image-cover-control" >
                        <input className="slider" type="range" value={blurRangeIndex} min="0" max="4" step="1" list="slider-markers" 
                            disabled={!showBlur}
                            onChange={((e) => {
                                setBlurRangeIndex(e.target.value)
                            })}
                        />
                        <datalist id="slider-markers">
                            {Array(5).fill(1).map((_, index) => (
                                <option value={index} label={index} ></option>
                            ))}
                        </datalist>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                            <Switch onChange={setShowBlur} checked={showBlur} height={17} width={31} />
                            <label style={{ marginLeft: '8px', color: '#eee' }}>
                                Blur
                            </label>
                        </div>
                    </div>
                    <FinalMessage gameState={gameState} />
                    <FinalAnswer answer={answer} />
                    <ShareResult
                        guesses={guessNum}
                        gameState={gameState}
                        puzzleId={puzzleId}
                        franchiseMatch={franchiseMatch}
                        maxGuesses={settings.max_guesses}
                    />
                    <ShowGuesses guessList={guessList} answer={answer} />
                    <KofiButton preset="kufi-center" />

                    <NavigateButton
                        text={t('Play Previous Days')}
                        path={settings.paths.previous_games}
                        params={createSearchParams({ puzzleType: puzzleType }).toString()}
                        hash={puzzleId}
                    />
                    <NavigateButton text={t('Back To Home')} path={'/'} />
                </div>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum < settings.max_guesses - 1 && (
                <p className="guesses-remaining">
                    {settings.max_guesses - guessNum} {settings.max_guesses - guessNum === 1 ? 'guess' : 'guesses'}{' '}
                    {t('remaining')}!
                </p>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum >= settings.max_guesses - 1 && (
                <p className="guesses-remaining">{t('Last guess')}!</p>
            )}
            {gameState === 'playing' && (
                <div className="PlayArea">
                    <div className="input-area">
                        <AutoComplete
                            onChangeFn={onChange}
                            onKeyDownFn={onKeyDown}
                            onClickFn={onClick}
                            activeSuggestionIndex={activeSuggestionIndex}
                            filteredSuggestionsList={filteredSuggestions}
                            inputValue={guessInputValue}
                            shouldShowSuggestions={showSuggestions}
                            loading={ACloading}
                        />
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <button
                            type="button"
                            className="mainButton submitButton"
                            onClick={submitGuess}
                            style={{ marginRight: '5px' }}
                        >
                            {t('Submit')}
                        </button>
                        <button
                            type="button"
                            className="mainButton iconButton skipButton"
                            onClick={skipGuess}
                            style={{ marginTop: '0px' }}
                        >
                            Skip <span aria-hidden="true" className="icon-forward3"></span>
                        </button>
                    </div>
                    {guessList.map((guess, idx) => {
                        let emoji = null;
                        if (isAnswerCorrect(guess, answer)) {
                            emoji = '✅';
                        } else if (franchiseMatch[idx]) {
                            emoji = '🟡';
                        } else if (!isAnswerCorrect(guess, answer)) {
                            emoji = '❌';
                        }
                        return guess === '' ? null : (
                            <div key={'gl-' + idx} className="guess-result">
                                {emoji}
                                {guess}
                                <span className="guess-result-franchise">
                                    {franchiseMatch[idx] && ` (Franchise: ${franchise})`}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
            <Footer puzzleId={puzzleId} currentPage={'guess_the_cover'} puzzleType={puzzleType} />
        </div>
    );
};

export default GTCPuzzle;
