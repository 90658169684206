// @flow
import { setModal } from '../../store/slices/uxSlice';

import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import type { TDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';
import type { State } from '../../store/types';
import { CORRECT_EMOJI, INCORRECT_EMOJI, PARTIAL_EMOJI } from '../../constants';
import { useSettings } from '../../settings/useSettings';

const HowToPlayModal = (): React$Element<'div'> => {
    const { t } = useTranslation();
    const settings = useSettings();
    const dispatch: TDispatch = useDispatch();
    const { puzzleType } = useSelector((state: State) => state.ux);
    const { modalTexts } = settings.TEXTS.modal.HowToPlayModal;

    return (
        <div className="modal howtoplay-modal">
            <div className="howtoplay-modal-inner">
                <button
                    type="button"
                    className={'close-modal'}
                    onClick={() => {
                        dispatch(setModal('none'));
                    }}
                >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>

                <h3>
                    {t('How To Play')} {settings.title}
                </h3>
                {modalTexts.map((text, index) => (
                    <p key={index}>
                        {typeof text === 'string' ? (
                            text
                        ) : (
                            <>
                                {text.beforeLink}
                                <a href={text.url} target="_blank" rel="noopener noreferrer">
                                    {text.linkText}
                                </a>
                                {text.afterLink}
                            </>
                        )}
                    </p>
                ))}
                <p>
                    {CORRECT_EMOJI}= {t('Correct')}{' '}
                </p>
                <p>
                    {INCORRECT_EMOJI} = {t('Incorrect')}
                </p>
                {puzzleType === 'gtg' || puzzleType === 'gtgc' ? (
                    <div>
                        {PARTIAL_EMOJI} = {t('Correct Franchise')}
                        <br />
                        <br />
                        <p style={{ fontSize: '12px' }}>
                            {t('Example')}:
                            <br />
                            {t(
                                'Grand Theft Auto 3, Grand Theft Auto 4, Grand Theft Auto 5 would be the right franchise for Grand Theft Auto 1',
                            )}
                            .
                            <br />
                            <br />
                            {t(
                                'Final Fantasy Tactics, Final Fantasy Online, Fintal Fantasy 12 would be the right franchise for Final Fantasy II',
                            )}
                            .
                            <br />
                            <br />
                            {t('Super Mario Brothers, Mario Galaxy would be the right franchise for Paper Mario')}.
                            <br />
                            <br />
                            {t(
                                'Yoshis Island, Super Smash Brothers, Donkey Kong would not be the right franchise for Paper Mario',
                            )}
                            .
                        </p>
                    </div>
                ) : null}
                <p>{t('Good Luck!')}</p>
            </div>
        </div>
    );
};

export default HowToPlayModal;
