/* eslint-disable no-useless-escape */
// @flow

export type TGTHAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    franchise: string,
    sold_on: string,
    city_state: string,
    bed_bath: string,
    year_built: string,
    lot: string,
    square_footage: string,
    affiliate_links: Array<string>,
};

type TGTHAnswers = {
    [string]: TGTHAnswer
};

export const answers: TGTHAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["$1,984,560"],
        franchise: 'None',
        sold_on: '08/13/24',
        city_state: 'Pasadena, CA',
        bed_bath: '3 beds, 2 baths',
        year_built: '1949',
        lot: '10,049 sqft',
        square_footage: '1,737 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/1475-Scenic-Dr-Pasadena-CA-91103/20855710_zpid/?utm_source=zillowgonewild&utm_medium=zillowgonewild&utm_campaign=zillowgonewild"],
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["$660,000"],
        franchise: 'None',
        sold_on: '02/09/24',
        city_state: 'Plympton, MA',
        bed_bath: '3 bed, 3 bath',
        year_built: '1978',
        lot: '2.30 Acres',
        square_footage: '2,304 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/33-Center-St-Plympton-MA-02367/57216465_zpid/"],
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["$1,605,000"],
        franchise: 'None',
        sold_on: '06/28/24',
        city_state: 'Los Angeles, CA',
        bed_bath: '2 beds, 1 baths',
        year_built: '1929',
        lot: '7,501 sqft',
        square_footage: '1,104 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/3318-Isabel-Dr-Los-Angeles-CA-90065/20757137_zpid/?utm_source=zillowgonewild&utm_medium=zillowgonewild&utm_campaign=zillowgonewild"],
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["$41,000,000"],
        franchise: 'None',
        sold_on: '07/10/23',
        city_state: 'New York, NY',
        bed_bath: '8 bd, 10 ba',
        year_built: '1885',
        lot: '3,678 sqft',
        square_footage: '15,000 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/110-E-76th-St-New-York-NY-10021/31535515_zpid/?"],
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["$670,000"],
        franchise: 'None',
        sold_on: '06/26/24',
        city_state: 'Kalamazoo, MI',
        bed_bath: '4 bd, 2 ba',
        year_built: '1951',
        lot: '1.12 Acres',
        square_footage: '1,671 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/2662-Taliesin-Dr-Kalamazoo-MI-49008/74155599_zpid/?utm_source=zillowgonewild&utm_medium=zillowgonewild&utm_campaign=zillowgonewild&utm_content=later-41817772"],
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["$3,075,000"],
        franchise: 'None',
        sold_on: '06/04/24',
        city_state: 'Spirit Lake, IA',
        bed_bath: '5 beds, 6 baths',
        year_built: '2018',
        lot: '7,405 sq ft',
        square_footage: '5,209 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/24704-182nd-St-Spirit-Lake-IA-51360/121538980_zpid/"],
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["$480,000"],
        franchise: 'None',
        sold_on: '07/15/24',
        city_state: 'Kansas City, MO',
        bed_bath: '3 beds, 3 baths',
        year_built: '1905',
        lot: '0.28 Acres',
        square_footage: '4,759 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/3232-Windsor-Ave-S-Kansas-City-MO-64123/2056459237_zpid/?"],
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["$489,000"],
        franchise: 'None',
        sold_on: '06/24/24',
        city_state: 'Clayville, NY',
        bed_bath: '5 beds, 3 baths',
        year_built: '1890',
        lot: '5.13 Acres',
        square_footage: '5,500 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/2-Wire-Mill-Pl-Clayville-NY-13322/31602787_zpid/?utm_source=zillowgonewild&utm_medium=zillowgonewild&utm_campaign=zillowgonewild"],
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["$3,125,000"],
        franchise: 'None',
        sold_on: '06/20/24',
        city_state: 'Wahpeton, IA',
        bed_bath: '6 beds, 4 baths',
        year_built: '1966',
        lot: '0.57 Acres',
        square_footage: '4,654 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/2512-Manhattan-Blvd-Wahpeton-IA-51360/121538696_zpid/"],
    },
    '10': {
        content: 'Paul Reubens AKA Pee-wee Herman’s California Home',
        submitted_by: '',
        answers: ["$3,800,000"],
        franchise: 'None',
        sold_on: '07/29/24',
        city_state: 'Los Angeles, CA',
        bed_bath: '3 beds, 3 baths',
        year_built: '1957',
        lot: '1.44 Acres',
        square_footage: '2,921 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/5835-Green-Oak-Dr-Los-Angeles-CA-90068/20806093_zpid/?utm_source=zillowgonewild&utm_medium=zillowgonewild&utm_campaign=zillowgonewild"],
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["$1,200,000"],
        franchise: 'None',
        sold_on: '05/31/22',
        city_state: 'Crawfordville, FL',
        bed_bath: '3 beds, 4 baths',
        year_built: '2002',
        lot: '0.27 Acres',
        square_footage: '3,884 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/99-Royster-Dr-Crawfordville-FL-32327/48106176_zpid/"],
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["$9,845,000"],
        franchise: 'None',
        sold_on: '06/27/22',
        city_state: 'Charlevoix, MI',
        bed_bath: '7 beds, 9 baths',
        year_built: '2009',
        lot: '3 Acres',
        square_footage: '16,000 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/8425-08425-Raspberry-Ln-Charlevoix-MI-49720/2075417839_zpid/"],
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["$557,500"],
        franchise: 'None',
        sold_on: '09/24/24',
        city_state: 'Indianapolis, IN',
        bed_bath: '3 beds, 4 baths',
        year_built: '2023',
        lot: '3,920 sq ft',
        square_footage: '2,440 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/1226-Fletcher-Ave-Indianapolis-IN-46203/1090015_zpid/"],
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["$360,000"],
        franchise: 'None',
        sold_on: '09/24/24',
        city_state: 'Redding, CA',
        bed_bath: '3 beds, 2 baths',
        year_built: '1992',
        lot: '7,840 sq ft',
        square_footage: '1,080 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/1032-Grouse-Dr-Redding-CA-96003/15221167_zpid/"],
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["$1,400,000"],
        franchise: 'None',
        sold_on: '09/12/24',
        city_state: 'New Albany, OH',
        bed_bath: '5 beds, 4.5 baths',
        year_built: '2016',
        lot: '0.33 acres',
        square_footage: '5,230 sq ft',
        affiliate_links: ["https://www.redfin.com/OH/New-Albany/3920-Lewis-Link-43054/home/100723045"],
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["$1,525,000"],
        franchise: 'None',
        sold_on: '08/22/24',
        city_state: 'New York, NY',
        bed_bath: '1 bed, 1 bath',
        year_built: '1969',
        lot: '908 sq ft',
        square_footage: '908 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/715-Park-Ave-APT-9A-New-York-NY-10021/31535021_zpid/"],
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["$2,350,000"],
        franchise: 'None',
        sold_on: '04/03/24',
        city_state: 'Capitola, CA',
        bed_bath: '3 beds, 2 baths',
        year_built: '1900',
        lot: '3,180 sq ft',
        square_footage: '1,361 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/1550-Lincoln-Ave-Capitola-CA-95010/16130576_zpid/"],
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["$175,000"],
        franchise: 'None',
        sold_on: '09/19/24',
        city_state: 'Wadsworth, OH',
        bed_bath: '3 beds, 2 baths',
        year_built: '1938',
        lot: '6,969 sq ft',
        square_footage: '1,172 sq ft',
        affiliate_links: ["https://www.redfin.com/OH/Wadsworth/407-Oak-St-44281/home/66088427"],
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["$1,565,000"],
        franchise: 'None',
        sold_on: '09/13/24',
        city_state: 'Tallahassee, FL',
        bed_bath: '4 beds, 5 baths',
        year_built: '2001',
        lot: '2.11 Acres',
        square_footage: '5,432 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/3722-Bobbin-Mill-Rd-Tallahassee-FL-32312/45568370_zpid/"],
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["$335,000"],
        franchise: 'None',
        sold_on: '08/21/2024',
        city_state: 'Monroeville, AL',
        bed_bath: '4 beds, 4 baths',
        year_built: '1994',
        lot: '4.5 acres',
        square_footage: '3,208 sq ft',
        affiliate_links: ["https://www.redfin.com/AL/Monroeville/283-Hidden-Creek-Ln-36460/home/80393467"],
    },
    '21': {
        content: 'AKA ‘Utopia’. Hang out spot for celebrities like the Fondas and Elvis Presley',
        submitted_by: '',
        answers: ["$4,500,000"],
        franchise: 'None',
        sold_on: '11/20/23',
        city_state: 'Denver, CO',
        bed_bath: '5 beds, 7 baths',
        year_built: '1972',
        lot: '0.94 Acres',
        square_footage: '15,987 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/3962-S-Chase-Way-Denver-CO-80235/13430184_zpid/"],
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["$78,500"],
        franchise: 'None',
        sold_on: '10/01/24',
        city_state: 'Oklahoma City, OK',
        bed_bath: '3 beds, 2 baths',
        year_built: '1928',
        lot: '7,000 sq ft',
        square_footage: '1,311 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/940-NE-32nd-St-Oklahoma-City-OK-73105/21845940_zpid/"],
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["$1,710,000"],
        franchise: 'None',
        sold_on: '10/03/24',
        city_state: 'Los Gatos, CA',
        bed_bath: '4 beds, 3 baths',
        year_built: '1976',
        lot: '26.51 Acres',
        square_footage: '1,710 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/14136-Long-Ridge-Rd-Los-Gatos-CA-95033/16162244_zpid/"],
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["$500,000"],
        franchise: 'None',
        sold_on: '11/09/23',
        city_state: 'Tullahoma, TN',
        bed_bath: '4 beds, 4 baths',
        year_built: '1981',
        lot: '1.36 Acres',
        square_footage: '3,519 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/105-Regwood-Dr-Tullahoma-TN-37388/40911487_zpid/"],
    },
    '25': {
        content: 'Featured in the movie \"Some Came Running\" in 1958',
        submitted_by: '',
        answers: ["$139,900"],
        franchise: 'None',
        sold_on: '09/05/23',
        city_state: 'Madison, IN',
        bed_bath: '3 beds, 0 bath',
        year_built: '1900',
        lot: '2,831 sq ft',
        square_footage: '1,858 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/748-W-3rd-St-Madison-IN-47250/85425985_zpid/"],
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["$91,000"],
        franchise: 'None',
        sold_on: '09/27/24',
        city_state: 'Petersburg, VA',
        bed_bath: '3 beds, 2 baths',
        year_built: '1959',
        lot: '6,708 sq ft',
        square_footage: '1,386 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/928-Rosewood-Ter-Petersburg-VA-23805/305135615_zpid/"],
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["$1,070,000"],
        franchise: 'None',
        sold_on: '10/04/24',
        city_state: 'Reno, NV',
        bed_bath: '3 beds, 3 baths',
        year_built: '2005',
        lot: '7,405 sq ft',
        square_footage: '2,696 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/1565-Cricketwood-Cir-Reno-NV-89523/63239442_zpid/"],
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["$450,000"],
        franchise: 'None',
        sold_on: '07/18/24',
        city_state: 'Newark, DE',
        bed_bath: '3 beds, 2.5 baths',
        year_built: '1969',
        lot: '0.25 acres',
        square_footage: '3,609 sq ft',
        affiliate_links: ["https://www.redfin.com/DE/Newark/15-Cauline-Ct-19711/home/44950423"],
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["$1,275,000"],
        franchise: 'None',
        sold_on: '01/06/22',
        city_state: 'Mukilteo, WA',
        bed_bath: '5 beds, 3 baths',
        year_built: '1974',
        lot: '0.34 Acres',
        square_footage: '3,807 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/5713-88th-St-SW-Mukilteo-WA-98275/38517897_zpid/"],
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["$305,000"],
        franchise: 'None',
        sold_on: '05/16/23',
        city_state: 'Amery, WI',
        bed_bath: '3 beds, 2 baths',
        year_built: '1995',
        lot: '1.25 Acres',
        square_footage: '2,500 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/805-115th-St-A-Amery-WI-54001/113681812_zpid/"],
    },
    '31': {
        content: 'Owned by Ozzie and Harriet Nelson',
        submitted_by: '',
        answers: ["$25,975,000"],
        franchise: 'None',
        sold_on: '05/16/24',
        city_state: 'Laguna Beach, CA',
        bed_bath: '5 beds, 8 baths',
        year_built: '1999',
        lot: '0.34 Acres',
        square_footage: '8,718 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/16-Lagunita-Dr-Laguna-Beach-CA-92651/25573510_zpid/"],
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["$55,000"],
        franchise: 'None',
        sold_on: '10/03/24',
        city_state: 'Martinsville, VA',
        bed_bath: '1 bed, 1 bath',
        year_built: '1975',
        lot: '1.02 Acres',
        square_footage: '624 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/9383-Chatham-Rd-Martinsville-VA-24112/114652898_zpid/"],
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["$751,000"],
        franchise: 'None',
        sold_on: '10/07/24',
        city_state: 'Birmingham, AL',
        bed_bath: '3 beds, 2 baths',
        year_built: '2011',
        lot: '0.32 Acres',
        square_footage: '1,612 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/503-Baker-Dr-Birmingham-AL-35213/966467_zpid/?"],
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["$395,000"],
        franchise: 'None',
        sold_on: '09/13/24',
        city_state: 'Columbus, OH',
        bed_bath: '4 beds, 1.5 baths',
        year_built: '1919, renovated in 2010',
        lot: '4,356 sq ft',
        square_footage: '2,209 sq ft',
        affiliate_links: ["https://www.redfin.com/OH/Columbus/647-Oakwood-Ave-43205/home/75656498#overview"],
    },
    '35': {
        content: 'Featured in the movie \"Twilight\"',
        submitted_by: '',
        answers: ["$203,000"],
        franchise: 'None',
        sold_on: '11/07/23',
        city_state: 'Saint Helens, OR',
        bed_bath: '4 beds, 1 bath',
        year_built: '1885',
        lot: '5,662 sq ft',
        square_footage: '1,196 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/260-S-2nd-St-Saint-Helens-OR-97051/62745326_zpid/"],
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["$89,000"],
        franchise: 'None',
        sold_on: '09/20/24',
        city_state: 'Council Bluffs, IA',
        bed_bath: '2 beds, 1 bath',
        year_built: '1920',
        lot: '6,000 sq ft',
        square_footage: '728 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/2000-S-10th-St-Council-Bluffs-IA-51501/76813890_zpid/"],
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["$1,015,000"],
        franchise: 'None',
        sold_on: '10/07/24',
        city_state: 'Phoenix, AZ',
        bed_bath: '4 beds, 3 baths',
        year_built: '1998',
        lot: '0.32 Acres',
        square_footage: '3,379 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/15033-S-7th-St-Phoenix-AZ-85048/8130993_zpid/"],
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["$325,000"],
        franchise: 'None',
        sold_on: '09/30/24',
        city_state: 'Custer, SD',
        bed_bath: '3 beds, 2 baths',
        year_built: '1945',
        lot: '8,276 sq ft',
        square_footage: '2,654 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/630-Lincoln-St-Custer-SD-57730/126225819_zpid/"],
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["$6,500,000"],
        franchise: 'None',
        sold_on: '10/08/2024',
        city_state: 'Shaver Lake, CA',
        bed_bath: '6 bed, 6 bath',
        year_built: '2015',
        lot: '12.29acre',
        square_footage: '9,680 sq ft',
        affiliate_links: ["https://www.realtor.com/realestateandhomes-detail/42342-Blue-Cyn_Shaver-Lake_CA_93664_M98849-20622"],
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["$434,900"],
        franchise: 'None',
        sold_on: '10/08/24',
        city_state: 'Pasadena, MD',
        bed_bath: '3 bed, 2 bath',
        year_built: '1997',
        lot: '5,366 sq ft',
        square_footage: '1,744 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/202-Knoll-Ct-Pasadena-MD-21122/36063803_zpid/"],
    },
    '41': {
        content: 'Former home of Charles Boyer and also featured in Phoenix Home and Garden',
        submitted_by: '',
        answers: ["$3,925,000"],
        franchise: 'None',
        sold_on: '5/03/24',
        city_state: 'Paradise Valley, AZ',
        bed_bath: '3 bed, 4 bath',
        year_built: '1967',
        lot: '10,409 sq ft',
        square_footage: '3,313 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/5434-E-Lincoln-Dr-58-Paradise-Valley-AZ-85253/7831743_zpid/"],
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["$430,000"],
        franchise: 'None',
        sold_on: '2024-10-11',
        city_state: 'Warwick, RI',
        bed_bath: '3 beds, 2 baths',
        year_built: '1993',
        lot: '0.40 Acres',
        square_footage: '1,773 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/11-Hunts-River-Ct-Warwick-RI-02818/65989068_zpid/"],
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["$1,210,000"],
        franchise: 'None',
        sold_on: '09/03/24',
        city_state: 'Las Vegas, NV',
        bed_bath: '5 beds, 4.5 baths',
        year_built: '2004',
        lot: '0.54 acres',
        square_footage: '3,912 sq ft',
        affiliate_links: ["https://www.redfin.com/NV/Las-Vegas/8970-W-Verde-Way-89149/home/28978470"],
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["$242,500"],
        franchise: 'None',
        sold_on: '09/23/24',
        city_state: 'Berkshire, NY',
        bed_bath: '4 beds, 2 baths',
        year_built: '1860',
        lot: '1.7 acre',
        square_footage: '3,356 sq ft',
        affiliate_links: ["https://www.realtor.com/realestateandhomes-detail/12499-State-Route-38_Berkshire_NY_13736_M32167-80485"],
    },
    '45': {
        content: 'Featured in March 2021 edition of Vero Beach Magazine and owned by former celebrity designer & original host of HGTV.',
        submitted_by: '',
        answers: ["$1,800,000"],
        franchise: 'None',
        sold_on: '11/30/23',
        city_state: 'Vero Beach, FL',
        bed_bath: '4 beds, 5 baths',
        year_built: '2008',
        lot: '0.29 Acres',
        square_footage: '5,083 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/2217-W-Ocean-Oaks-Cir-Vero-Beach-FL-32963/90157588_zpid/"],
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["$100,000"],
        franchise: 'None',
        sold_on: '09/13/24',
        city_state: 'Sandy, OR',
        bed_bath: '3 bed, 2 bath',
        year_built: '1930',
        lot: '0.36acre',
        square_footage: '2,260 sq ft',
        affiliate_links: ["https://www.realtor.com/realestateandhomes-detail/40220-SE-Cedar-Creek-Rd_Sandy_OR_97055_M24293-52686"],
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["$2,750,000"],
        franchise: 'None',
        sold_on: '09/30/24',
        city_state: 'Charleston, SC',
        bed_bath: '5 beds, 5.5 baths',
        year_built: '2015',
        lot: '0.26 acres',
        square_footage: '4,595 sq ft',
        affiliate_links: ["https://www.redfin.com/SC/Daniel-Island/370-Lesesne-St-29492/home/68544997"],
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["$325,000"],
        franchise: 'None',
        sold_on: '10/08/24',
        city_state: 'Enfield, CT',
        bed_bath: '3 beds, 2 baths',
        year_built: '1956',
        lot: '0.29 Acres',
        square_footage: '1,344 sq ft',
        affiliate_links: ["https://www.zillow.com/homedetails/336-Taylor-Rd-Enfield-CT-06082/57716422_zpid/"],
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["$1,025,000"],
        franchise: 'None',
        sold_on: '2024-07-12',
        city_state: 'Burlington, VT',
        bed_bath: '3 beds, 1 bath',
        year_built: '1922',
        lot: '7,840 sqft',
        square_footage: '1,800 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/64-Adams-St-Burlington-VT-05401/12653776_zpid/"],
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["$469,900"],
        franchise: 'None',
        sold_on: '2024-10-11',
        city_state: 'Simpsonville, SC',
        bed_bath: '3 beds, 3 baths',
        year_built: '1995',
        lot: '0.45 Acres',
        square_footage: '2,503 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/18-Featherwood-Ct-Simpsonville-SC-29680/11058068_zpid/"],
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["$2,249,000"],
        franchise: 'None',
        sold_on: '2024-08-07',
        city_state: 'Woodland Hills, CA',
        bed_bath: '4 beds, 4 baths',
        year_built: '1956',
        lot: '0.28 Acres',
        square_footage: '2,718 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/4995-Campo-Rd-Woodland-Hills-CA-91364/19942634_zpid/"],
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["$354,000"],
        franchise: 'None',
        sold_on: '2024-11-04',
        city_state: 'Byron Center, MI',
        bed_bath: '4 beds, 2 baths',
        year_built: '1960',
        lot: '0.51 Acres',
        square_footage: '2,264 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/635-100th-St-SE-Byron-Center-MI-49315/23894735_zpid/"],
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["$1,210,000"],
        franchise: 'None',
        sold_on: '2024-10-10',
        city_state: 'Maryville, TN',
        bed_bath: '3 beds, 4 baths',
        year_built: '2022',
        lot: '2.95 Acres',
        square_footage: '3,712 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/109-Whaley-Way-Maryville-TN-37804/40824313_zpid/"],
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["$500,000"],
        franchise: 'None',
        sold_on: '2022-01-07',
        city_state: 'Rockford, MI',
        bed_bath: '3 beds, 2 baths',
        year_built: '1954',
        lot: '7,405 sqft',
        square_footage: '1,692 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/8635-Je-Ne-Be-Dr-NE-Rockford-MI-49341/23751399_zpid/"],
    },
    '55': {
        content: 'Featured on The Design Network T.V. show “\'Making Spaces\'\'',
        submitted_by: '',
        answers: ["$585,000"],
        franchise: 'None',
        sold_on: '2023-12-29',
        city_state: 'Bend, OR',
        bed_bath: '2 beds, 1 bath',
        year_built: '1945',
        lot: '6,098 sqft',
        square_footage: '1,054 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/175-SE-Roosevelt-Ave-Bend-OR-97702/80922819_zpid/"],
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["$87,000"],
        franchise: 'None',
        sold_on: '2024-10-03',
        city_state: 'Bessemer, AL',
        bed_bath: '2 beds, 1 bath',
        year_built: '1940',
        lot: '0.65 Acres',
        square_footage: '672 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/3080-Sherry-Dr-Bessemer-AL-35022/1052021_zpid/"],
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["$2,215,000"],
        franchise: 'None',
        sold_on: '2024-10-08',
        city_state: 'Sioux Falls, SD',
        bed_bath: '5 beds, 5 baths',
        year_built: '2018',
        lot: '0.58 Acres',
        square_footage: '5,504 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/1400-S-Scarlet-Oak-Trl-Sioux-Falls-SD-57110/201384942_zpid/"],
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["$430,000"],
        franchise: 'None',
        sold_on: '2024-10-11',
        city_state: 'Warwick, RI',
        bed_bath: '3 beds, 2 baths',
        year_built: '1993',
        lot: '0.40 Acres',
        square_footage: '1,773 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/11-Hunts-River-Ct-Warwick-RI-02818/65989068_zpid/"],
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["$2,061,200"],
        franchise: 'None',
        sold_on: '2024-03-04',
        city_state: 'Lake Geneva, WI',
        bed_bath: '4 beds, 6 baths',
        year_built: '2007',
        lot: '1 Acres',
        square_footage: '9,000 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/W1773-N-Bloomfield-Rd-Lake-Geneva-WI-53147/81371548_zpid/"],
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["$296,000"],
        franchise: 'None',
        sold_on: '2024-10-23',
        city_state: 'Newport, VT',
        bed_bath: '3 beds, 2 baths',
        year_built: '1987',
        lot: '0.30 Acres',
        square_footage: '2,060 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/274-Scottsdale-Rd-Newport-VT-05855/75448326_zpid/"],
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["$1,925,000"],
        franchise: 'None',
        sold_on: '2023-11-30',
        city_state: 'Venice, CA',
        bed_bath: '2 beds, 2 baths',
        year_built: '2009',
        lot: '4,477 sqft',
        square_footage: '1,930 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/540-1-2-Vernon-Ave-Venice-CA-90291/99409197_zpid/"],
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["$60,000"],
        franchise: 'None',
        sold_on: '2024-10-17',
        city_state: 'Fairbury, NE',
        bed_bath: '3 beds, 2 baths',
        year_built: '1900',
        lot: '6,816 sqft',
        square_footage: '1,390 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/1205-F-St-Fairbury-NE-68352/108891931_zpid/"],
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["$1,100,000"],
        franchise: 'None',
        sold_on: '2024-09-30',
        city_state: 'Shepherdstown, WV',
        bed_bath: '3 beds, 3 baths',
        year_built: '1995',
        lot: '9.74 Acres',
        square_footage: '3,427 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/1827-Terrapin-Neck-Rd-Shepherdstown-WV-25443/22578016_zpid/"],
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["$429,000"],
        franchise: 'None',
        sold_on: '2024-10-23',
        city_state: 'Florence, OR',
        bed_bath: '3 beds, 2 baths',
        year_built: '1995',
        lot: '0.28 Acres',
        square_footage: '1,664 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/88601-4th-Ave-Florence-OR-97439/48422019_zpid/"],
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["$227,000"],
        franchise: 'None',
        sold_on: '2023-04-13',
        city_state: 'Madison, AL',
        bed_bath: '2 beds, 2 baths',
        year_built: '1987',
        lot: '1,344 sqft',
        square_footage: '1,257 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/154-Briargate-Ln-Madison-AL-35758/304165500_zpid/"],
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["$40,000"],
        franchise: 'None',
        sold_on: '2024-10-22',
        city_state: 'Cedar Rapids, IA',
        bed_bath: '2 beds, 1 baths',
        year_built: '1923',
        lot: '4,181 sqft',
        square_footage: '1,052 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/1818-Park-Ave-SE-Cedar-Rapids-IA-52403/73065143_zpid/"],
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["$1,350,000"],
        franchise: 'None',
        sold_on: '2024-10-23',
        city_state: 'Gainesville, VA',
        bed_bath: '5 beds, 6 baths',
        year_built: '1993',
        lot: '0.47 Acres',
        square_footage: '6,270 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/14955-Alpine-Bay-Loop-Gainesville-VA-20155/12500501_zpid/"],
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["$400,000"],
        franchise: 'None',
        sold_on: '2024-10-23',
        city_state: 'Edmond, OK',
        bed_bath: '3 beds, 4 baths',
        year_built: '1985',
        lot: '0.30 Acres',
        square_footage: '3,053 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/905-Gleneagles-Dr-Edmond-OK-73013/21924656_zpid/"],
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["$2,400,000"],
        franchise: 'None',
        sold_on: '2024-10-25',
        city_state: 'Arnolds Park, IA',
        bed_bath: '5 beds, 2 baths',
        year_built: '1920',
        lot: '0.46 Acres',
        square_footage: '2,714 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/645-W-Okoboji-Rd-Arnolds-Park-IA-51331/121543227_zpid/"],
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["$600,000"],
        franchise: 'None',
        sold_on: '2024-11-04',
        city_state: 'Silver Spring, MD',
        bed_bath: '3 beds, 3 baths',
        year_built: '1984',
        lot: '6,387 sqft',
        square_footage: '1,566 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/11413-Heather-Crest-Ln-Silver-Spring-MD-20902/37331616_zpid/"],
    },
    '71': {
        content: 'Owned by Joe E Tata, celebrated for his portrayal of Nat Bussichio on the beloved TV show 90210.',
        submitted_by: '',
        answers: ["$2,425,000"],
        franchise: 'None',
        sold_on: '2024-04-09',
        city_state: 'Los Angeles, CA',
        bed_bath: '3 beds, 4 baths',
        year_built: '1987',
        lot: '4,896 sqft',
        square_footage: '3,338 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/8554-Franklin-Ave-Los-Angeles-CA-90069/20798359_zpid/"],
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["$78,950"],
        franchise: 'None',
        sold_on: '2024-09-30',
        city_state: 'Roanoke, VA',
        bed_bath: '4 beds, 2 baths',
        year_built: '1900',
        lot: '5,227 sqft',
        square_footage: '1,372 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/1313-Tazewell-Ave-SE-Roanoke-VA-24013/49665206_zpid/"],
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["$3,230,000"],
        franchise: 'None',
        sold_on: '2024-11-04',
        city_state: 'Seattle, WA',
        bed_bath: '3 beds, 4 baths',
        year_built: '1951',
        lot: '6,481 sqft',
        square_footage: '3,600 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/1250-22nd-Ave-E-Seattle-WA-98112/48725538_zpid/"],
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["$314,000"],
        franchise: 'None',
        sold_on: '2024-11-01',
        city_state: 'Pittsburgh, PA',
        bed_bath: '4 beds, 3 baths',
        year_built: '1940',
        lot: '7,405 sqft',
        square_footage: '1,380 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/112-Amherst-Ave-Pittsburgh-PA-15229/11405654_zpid/"],
    },
    '75': {
        content: 'Also known as the Mohonk Mountain House and the waterfalls featured in the movie Splendor in the Grass is only a few minutes away.',
        submitted_by: '',
        answers: ["$846,500"],
        franchise: 'None',
        sold_on: '2022-10-14',
        city_state: 'High Falls, NY',
        bed_bath: '3 beds, 2 baths',
        year_built: '1900',
        lot: '0.42 Acres',
        square_footage: '2,171 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/7-Old-State-Route-213-High-Falls-NY-12440/2061772902_zpid/"],
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["$50,000"],
        franchise: 'None',
        sold_on: '2024-07-31',
        city_state: 'Indianapolis, IN',
        bed_bath: '2 beds, 1 baths',
        year_built: '1920',
        lot: '5,662 sqft',
        square_footage: '714 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/854-N-Gladstone-Ave-Indianapolis-IN-46201/1079035_zpid/"],
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["$1,374,000"],
        franchise: 'None',
        sold_on: '2024-11-04',
        city_state: 'Boulder, CO',
        bed_bath: '4 beds, 2 baths',
        year_built: '1963',
        lot: '2.14 Acres',
        square_footage: '3,201 sqft',
        affiliate_links: ["https://www.zillow.com/homedetails/135-76th-St-Boulder-CO-80303/13197946_zpid/"],
    },
};
