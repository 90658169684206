import React from 'react';
import ShareResult from './ShareResult';
import { random } from 'lodash';
import { useSettings } from '../settings/useSettings';

const FakeResult = () => {
    const guessNum = random(1, 5);

    const settings = useSettings();
    const puzzleId = settings.num_days_from_start_date().toString();
    return (
        <div className="sentence">
            <ShareResult
                guesses={guessNum}
                gameState={'win'}
                puzzleId={puzzleId}
                franchiseMatch={{}}
                maxGuesses={settings.max_guesses}
            />
        </div>
    );
};

export default FakeResult;
