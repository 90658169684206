// @flow

import GTCPuzzle from '../components/Puzzle/GTCPuzzle';
import PreviousPuzzles from '../components/PreviousPuzzles';
import GTAPuzzle from '../components/Puzzle/GTAPuzzle';
import Setting from '../components/Setting';
import Stats from '../components/Stats';
import { type GameSettings } from './game_settings';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import ResetPuzzle from '../components/ResetPuzzle';
import { PUZZLE_TYPES } from '../constants';
import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { AnswerUnion } from './game_settings';
import { answers } from '../puzzles/gta_puzzles';
import type { TGTAAnswer } from '../puzzles/gta_puzzles';
import { BaseSettings } from './BaseSettings';

export class GTASettings extends BaseSettings {
    static PATHS: {
        email_callback: string,
        previous_games: string,
        puzzle: string,
        guess_the_cover: string,
        reset: string,
        setting: string,
        stats: string,
        ...
    } = {
        puzzle: '/p/:puzzleId',
        guess_the_cover: '/gtac/:puzzleId?',
        previous_games: '/previous-games',
        reset: '/reset/:puzzleId',
        stats: '/stats/:puzzleId',
        email_callback: '/email_callback/',
        setting: '/setting',
    };

    routes(): React$Element<React$FragmentType> {
        return (
            <>
                <Route element={<GTAPuzzle />} path="/" />
                <Route path={GTASettings.PATHS.puzzle} element={<GTAPuzzle />} />
                <Route path={GTASettings.PATHS.stats} element={<Stats />} />
                <Route path={GTASettings.PATHS.previous_games} element={<PreviousPuzzles />} />
                <Route
                    path={GTASettings.PATHS.guess_the_cover}
                    element={<GTCPuzzle puzzleType={PUZZLE_TYPES.GTAC} answers={this.answers} />}
                />
                <Route path={GTASettings.PATHS.reset} element={<ResetPuzzle />} />
                <Route path={GTASettings.PATHS.setting} element={<Setting />} />
                <Route path={GTASettings.PATHS.email_callback} element={<Setting fromEmailCallback={true} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </>
        );
    }

    puzzle_start_date: Date = new Date('5/04/2023');

    website_url: string = 'GuessTheAudio.com';
    title: string = 'Guess The Audio';
    website_title: string = 'Guess The Audio'; // except for GTG this should be the same as title
    parent_abbr: string = 'gta';

    max_guesses: number = 6;
    root_url: string = '/';
    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTA;
    clipboard_tag: string = '#GuessTheAudio';
    storage_keys: { guess: string, game_state: string } = { guess: 'guess', game_state: 'gamestate' };
    answers: AnswerUnion = answers;
    website_emoji: string = '🔊';
    guess_item: string = 'Song';

    progress_ranks: { [string]: string } = {
        '0': 'Rookie Listener',
        '5': 'Tune Enthusiast',
        '10': 'Music Explorer',
        '20': 'Melody Detective',
        '40': 'Harmony Seeker',
        '80': 'Song Sleuth',
        '150': 'Lyric Virtuoso',
        '200': 'Tune Master',
        '250': 'Melody Maestro',
        '300': 'Distinguished Note Detective',
        '350': 'Elite Listener',
        '400': 'Exalted Melody Guru',
        '430': 'Extraordinary Ear',
        '470': 'Champion of Chords',
        '500': 'Prodigious Music Guesser',
        '530': 'Masterful Melody Mind',
        '560': 'Superior Sound Strategist',
        '600': 'Grandmaster Listener',
        '630': 'Supreme Sound Savant',
        '700': 'Sovereign of Songs',
        '750': 'Grand Music Guesser',
        '800': 'Ultimate Melody Connoisseur',
        '900': 'Eternal Expert of Tunes',
        '1000': 'Omni-Listener',
        '1100': 'Galactic Guru of Music',
        '1300': 'Legendary Music Oracle',
    };

    playDurationDict: { [string]: number } = {
        '1': 0.4,
        '2': 1,
        '3': 2,
        '4': 5,
        '5': 7,
        '6': 9,
    };

    // $FlowIgnore
    get_hint_text(imageIndex: number, puzzle: TGTAAnswer, settings: GameSettings): string {
        const HINT_2 = 'Length: ';
        const HINT_3 = 'Release Year: ';
        const HINT_4 = 'Genre: ';
        const HINT_5 = 'Album: ';
        const HINT_6 = 'Artist: ';
        switch (imageIndex) {
            case 2:
                return `${HINT_2} ${puzzle.track_length}`;
            case 3:
                return `${HINT_3} ${puzzle.release_year}`;
            case 4:
                return `${HINT_4} ${puzzle.genre}`;
            case 5:
                return `${HINT_5} ${puzzle.album}`;
            case 6:
                return `${HINT_6} ${puzzle.artist}`;
            default:
                return '';
        }
    }

    getCoverTitle(): string {
        return 'Guess The Album Cover';
    }

    TEXTS: { modal: { AboutModal: { modalTexts: Array<string>, ... }, ... }, ... } = {
        modal: {
            AboutModal: {
                modalTexts: [
                    `Every day a new ${this.guess_item.toLowerCase()} is selected and your goal is to guess what song it is! You will have 6 chances to guess from 6 snippets with each snippet longer than the previous.`,
                    'We try to have a selection of songs from different genres, years, and artists. This ranges from the Top 20 Billboard to your parents favorite songs as a kid.',
                ],
            },
            HowToPlayModal: {
                modalTexts: [
                    'Click play to hear an audio snippet of a song.',
                    'Search for the song that you think the audio snippet belongs to.',
                    'Click skip to move on to the next snippet.',
                    'If you get it wrong, we will reveal an additional snippet of the song to help you.',
                    'You get 6 guesses in total.',
                ],
            },
        },
    };
}
