// @flow
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AsyncThunk, ExtractActionType } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE } from '../../globals';
import { post_wrapper } from './apiUtils';
import type { PUZZLE_TYPES_TYPE } from '../../constants';

export type TSubmitGamePayload = {
    puzzleId: number,
    guess: string,
    guessNum: number,
    puzzleType: PUZZLE_TYPES_TYPE,
};

const submitGuessThunk: AsyncThunk<string, TSubmitGamePayload, any> = createAsyncThunk(
    'game/submitGuess',
    async (payload: TSubmitGamePayload, thunkAPI) => {
        await post_wrapper(`${API_BASE}/api/submit_guess/`, {
            data: {
                puzzle_num: payload.puzzleId,
                guess_num: payload.guessNum,
                guess: payload.guess,
                puzzle_type: payload.puzzleType,
            },
        });
    },
);

type TAutocompleteSearchPayload = {
    userInput: string,
    puzzleId: string,
    puzzleType: string /*gtg,gtgc,gtb*/,
    itemType: string /*book,game,audio*/,
};

const autocompleteSearchThunk: AsyncThunk<string, TAutocompleteSearchPayload, any> = createAsyncThunk(
    'game/autocompleteSearch',
    async (payload: TAutocompleteSearchPayload, thunkAPI) => {
        let response = await axios({
            method: 'get',
            url: `${API_BASE}/api/autocomplete/?q=${payload.userInput}
            &item_type=${payload.itemType}
            &puzzle_type=${payload.puzzleType}
            &pnum=${payload.puzzleId}`,
            timeout: 10000,
        });
        let resp_json = await response.data;
        return resp_json['results'];
    },
);

export type TGameActions = $ObjMap<
    // $FlowIgnore - no idea how to type this
    typeof submitGuessThunk.call | typeof autocompleteSearchThunk.call,
    ExtractActionType,
>;
export { submitGuessThunk, autocompleteSearchThunk };
