import { useSettings } from '../settings/useSettings';

const Socials = ({ facebook_url, instagram_url, twitter_url, youtube_url }) => {
    const settings = useSettings();
    const socialStyle = {
        flexDirection: 'row',
        color: 'white',
        marginTop: '20px',
    };
    return (
        <div className="socials" style={socialStyle}>
            <br />
            {instagram_url.length > 0 && (
                <a href={instagram_url} className="instagram socials-label" target="_blank" rel="noreferrer noopener">
                    <span className="icon-instagram"></span>
                </a>
            )}
            {twitter_url.length > 0 && (
                <a href={twitter_url} className="twitter socials-label" target="_blank" rel="noreferrer noopener">
                    <span className="icon-twitter"></span>
                </a>
            )}{' '}
            {facebook_url.length > 0 && (
                <a href={facebook_url} className="facebook socials-label" target="_blank" rel="noreferrer noopener">
                    <span className="icon-facebook"></span>
                </a>
            )}
            {youtube_url.length > 0 && (
                <a href={youtube_url} className="youtube socials-label" target="_blank" rel="noreferrer noopener">
                    <span className="icon-youtube"></span>
                </a>
            )}
            <a
                href={`https://api.guessthe.game/api/rss/feed/${settings.parent_abbr}`}
                className="rss2 socials-label"
                target="_blank"
                rel="noreferrer noopener"
            >
                <span className="icon-rss2"></span>
            </a>
        </div>
    );
};
export default Socials;
