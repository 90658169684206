import PropTypes from 'prop-types';
import React from 'react';
import { KOFI_URL } from '../constants';

const known_presets = ['', 'default', 'thin', 'skinny', 'circle', 'no_background', 'kufi-right', 'kufi-center'];

export default function KofiButton({ label, title, preset, backgroundColor, animation }) {
    if (preset && !known_presets.includes(preset)) {
        console.warn(`Unknown preset "${preset}", reverting to default`);
        preset = '';
    }
    if (preset === 'default') {
        preset = '';
    }
    //check if label is empty
    if (!label) {
        const potential_messages = [
            "Don't lose your stats!",
            "Don't lose your streak!",
            "Don't lose your progress!",
            'Keep the Streak Alive',
            'Keep your Stats Backed Up',
            'Keep your progress safe',
            'Support Guess The... On Ko-Fi',
        ];
        label = potential_messages[Math.floor(Math.random() * potential_messages.length)];
    }

    return (
        <div className="btn-container" style={{ '--background-color': '#29abe0' }}>
            <a
                className={'kufi-button ' + preset}
                href={KOFI_URL}
                target="_blank"
                rel="noreferrer noopener external"
                title={title}
            >
                <span className="kufitext">
                    <img src="https://storage.ko-fi.com/cdn/cup-border.png" alt={'support'} className="kufiimg" />
                    {label}
                </span>
            </a>
        </div>
    );
}

KofiButton.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    preset: PropTypes.oneOf(known_presets),
    backgroundColor: PropTypes.string,
    animation: PropTypes.oneOf([true, false, 'true', 'false', 'on_hover']),
};

KofiButton.defaultProps = {
    title: '',
    backgroundColor: 'kofiBlue',
    animation: true,
};
