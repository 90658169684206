// @flow
import { isAnswerCorrect, priceCheckAnswer } from './globals';
import { assembleClipboard } from './clientUtils';
import { loadLocalStorageItem } from './localStateUtils';
import type { GameSettings } from './settings/game_settings';
import { PUZZLE_TYPES } from './constants';

export type TpreviousPuzzles = Array<{
    result: 'unplayed' | 'success' | 'fail',
    cubes: Array<'q' | 'f' | 's' | 'p'>, // q is a question mark, f is a fail cube (also indicates it was skipped), s is a success cube
    skippedAll: boolean, // if all the cubes are skipped,
    answer: string,
}>;
type TPrevPuzzleResp = {
    previousPuzzles: TpreviousPuzzles,
    numWinsAtGuess: { [string]: number },
};

export const dummyPreviousPuzzleResults = (): TPrevPuzzleResp => {
    return { previousPuzzles: [], numWinsAtGuess: {} };
};

export const calcPreviousPuzzleResults = (settings: GameSettings): TPrevPuzzleResp => {
    const prevPuzzles: TpreviousPuzzles = [];
    let numWinsAtGuess: { [string]: number } = { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0 };

    const game_answers = settings.answers;
    const storage_key = settings.storage_keys.guess;
    const maxGuesses = settings.max_guesses;
    const num_games = settings.num_days_from_start_date();

    for (let i = 1; i <= num_games; i++) {
        // contains all the previous correct answers
        const prevDaysAnswer = game_answers[i.toString()].answers;
        // iterate though localStorage and get all the guesses for the day. each guess is either null, Skipped!, or a string (the guess)
        const [g1, g2, g3, g4, g5, g6] = Array.from({ length: 6 }, (_, x) => {
            return localStorage.getItem(`${i}_${storage_key}${x + 1}`);
        });
        const [f1, f2, f3, f4, f5, f6] = Array.from({ length: 6 }, (_, x) => {
            return localStorage.getItem(`${i}_guess${x + 1}_f`) === 'true';
        });
        const guessArr = [g1, g2, g3, g4, g5, g6];
        const franchiseArr = [f1, f2, f3, f4, f5, f6];
        // console.log('GA: ', guessArr);
        // console.log('FA: ', franchiseArr);

        // check if g1 is null. if it is we dont need to go through the rest
        if (guessArr[0] === null) {
            prevPuzzles.push({
                result: 'unplayed',
                cubes: ['q', 'q', 'q', 'q', 'q', 'q'],
                skippedAll: false,
                answer: prevDaysAnswer[0],
            });
            continue;
        }

        // now we generate the cubes
        // q is a question mark
        // p is partial (franchise)
        // f is a fail cube
        // s is a success cube
        let successIndex = -1;

        let cubeArr = ['q', 'q', 'q', 'q', 'q', 'q'];

        for (const [idx, guess] of guessArr.entries()) {
            if (guess == null) {
                // if the guess is null, we don't need to continue since there can't be later guesses
                break;
            } else if (guess === 'Skipped!') {
                // means it was skipped
                cubeArr[idx] = 'f';
            } else {
                let isCorrect;
                if (settings.puzzle_type === PUZZLE_TYPES.GTH) {
                    const { isCorrect: correct } = priceCheckAnswer(guess, prevDaysAnswer[0]);
                    isCorrect = correct;
                } else {
                    isCorrect = isAnswerCorrect(guess, prevDaysAnswer);
                }
                if (!isCorrect) {
                    if (franchiseArr[idx]) {
                        cubeArr[idx] = 'p';
                    } else {
                        cubeArr[idx] = 'f';
                    }
                } else {
                    successIndex = idx;
                    cubeArr[idx] = 's';
                    break;
                }
            }
        }

        if (successIndex >= 0) {
            prevPuzzles.push({
                result: 'success',
                cubes: cubeArr.slice(0, maxGuesses),
                skippedAll: false,
                answer: prevDaysAnswer[0],
            });
            numWinsAtGuess[(successIndex + 1).toString()] += 1;
        } else {
            prevPuzzles.push({
                result: 'fail',
                cubes: cubeArr.slice(0, maxGuesses),
                skippedAll: guessArr.every((g) => g === 'Skipped!'),
                answer: prevDaysAnswer[0],
            });
        }
    }
    return { previousPuzzles: prevPuzzles, numWinsAtGuess: numWinsAtGuess };
};

// this function will copy the previous games to the clipboard
export const copyPreviousPuzzlesToClipboard = (prevPuzzles_: TpreviousPuzzles, maxNumGuesses: number): string => {
    const formattedPuzzles = prevPuzzles_.map((prevPuzzle, index: number) => {
        const dayNum = index + 1;
        const guessEmojis = assembleClipboard(
            // number of cubes that are s or f
            prevPuzzles_[index].cubes.filter((c) => c !== 'q').length,
            loadLocalStorageItem(dayNum, 'gamestate') === 'win',
            Array(maxNumGuesses).fill(false),
            maxNumGuesses,
            false,
        );
        return `${guessEmojis.join(' ')} Day #${dayNum}`;
    });
    return formattedPuzzles.join('\n');
};
