// @flow

import type { GameSettings } from './settings/game_settings';
import type { User } from './store/slices/userSlice';

import { gamesPlayedCountKey } from './constants';

export const saveLocalStorageItem = (gameIdx: string, itemName: string, itemValue: string): void => {
    return localStorage.setItem(`${gameIdx}_${itemName}`, itemValue);
};

const deleteLocalStorageItem = (gameIdx: string, itemName: string): void => {
    return localStorage.removeItem(`${gameIdx}_${itemName}`);
};

export const loadLocalStorageItem = (gameIdx: string | number, itemName: string): ?string => {
    return localStorage.getItem(`${gameIdx}_${itemName}`);
};

class SettingToggle {
    constructor(name: string, defaultValue: boolean = false) {
        this.name = name;
        this.defaultValue = defaultValue;
    }
    load() {
        const savedState = localStorage.getItem(this.name);
        return savedState ? JSON.parse(savedState) : this.defaultValue;
    }
    save(itemValue: boolean) {
        return localStorage.setItem(this.name, JSON.stringify(itemValue));
    }
}

export const rankToggle = new SettingToggle('rankToggle', true);
export const franchiseToggle = new SettingToggle('franchiseToggle', true);
export const linkToPuzzleToggle = new SettingToggle('linkToPuzzleToggle', true);

export const resetState = (gameIdx: string, settings: GameSettings): void => {
    console.log('reset state for ', gameIdx, settings.puzzle_type);
    deleteLocalStorageItem(gameIdx, settings.storage_keys.game_state);
    [...Array(settings.max_guesses).keys()].forEach((value) =>
        deleteLocalStorageItem(gameIdx, `${settings.storage_keys.guess}${value + 1}`),
    );
    window.location.reload();
};

export const resetFranchiseState = (gameIdx: string): void => {
    deleteLocalStorageItem(gameIdx, 'guess1_f');
    deleteLocalStorageItem(gameIdx, 'guess2_f');
    deleteLocalStorageItem(gameIdx, 'guess3_f');
    deleteLocalStorageItem(gameIdx, 'guess4_f');
    deleteLocalStorageItem(gameIdx, 'guess5_f');
    deleteLocalStorageItem(gameIdx, 'guess6_f');
    window.location.reload(true);
};

export const saveFranchiseToggleState = (itemValue: boolean): void => {
    return localStorage.setItem('franchiseToggle', JSON.stringify(itemValue));
};

export const loadFranchiseToggleState = (): boolean => {
    const savedState = localStorage.getItem('franchiseToggle');
    return savedState ? JSON.parse(savedState) : true;
};

export const saveDateToLocalStorage = (key: string, date: Date = new Date()): void => {
    return localStorage.setItem(key, date.getTime().toString());
};

export const loadDateFromLocalStorage = (key: string): ?Date => {
    const dateString = localStorage.getItem(key);
    if (dateString === null) {
        return null;
    } else {
        const date = new Date(parseInt(dateString));
        return date;
    }
};

export const saveGamesPlayedCount = (value: number): void => {
    return localStorage.setItem(gamesPlayedCountKey, value.toString());
};

export const loadGamesPlayedCount = (): number => {
    const gamesPlayed = localStorage.getItem(gamesPlayedCountKey);
    return gamesPlayed ? parseInt(gamesPlayed) : 0;
};

export const resetGlobalState = (): void => {
    let resp = window.confirm('are you sure you want to reset everything?');
    if (resp) {
        localStorage.clear();
        window.location.reload(true);
    } else {
    }
};

export const storeUser = (user: User): void => {
    localStorage.setItem('user_hint', JSON.stringify(user));
};

export const loadUser = (): ?User => {
    const userString = localStorage.getItem('user_hint');
    if (!userString) {
        return null;
    }
    return JSON.parse(userString);
};

export const clearUser = () => {
    localStorage.removeItem('user_hint');
};

export const storeJwt = (token: string): void => {
    localStorage.setItem('user_jwt', token);
};

export const loadJwt = (): ?string => {
    return localStorage.getItem('user_jwt');
};

export const clearJwt = (): void => {
    localStorage.removeItem('user_jwt');
}

export const loadMustRedirect = (): boolean => {
    const savedState = localStorage.getItem('mustRedirect');
    return savedState ? JSON.parse(savedState) : false;
};
export const saveMustRedirect = (value: boolean) => {
    return localStorage.setItem('mustRedirect', JSON.stringify(value));
};

export const dummyStats = (): {
    currentStreak: number,
    maxStreak: number,
    totalPlayed: number,
    totalWon: number,
} => {
    return { currentStreak: 0, maxStreak: 0, totalPlayed: 0, totalWon: 0 };
};

export const calcStats = (
    settings: GameSettings,
): { currentStreak: number, maxStreak: number, totalPlayed: number, totalWon: number } => {
    let currentStreak_ = 0;
    let maxStreak_ = 0;
    let totalPlayed_ = 0;
    let totalWon_ = 0;
    //game states are win, playing, lose, null (unplayed)

    // iterate through state for all games and find the current streak and then the max streak
    for (let j = 1; j <= settings.num_days_from_start_date(); j++) {
        const gamestate = loadLocalStorageItem(j, settings.storage_keys.game_state);
        switch (gamestate) {
            case 'win':
                currentStreak_++;
                totalWon_++;
                if (currentStreak_ > maxStreak_) {
                    maxStreak_ = currentStreak_;
                }
                totalPlayed_++;
                break;
            case 'playing':
                totalPlayed_++;
                break;
            case 'lose':
                totalPlayed_++;
                currentStreak_ = 0;
                break;
            case null:
                break;
            default:
                console.error('Invalid gamestate');
        }
    }

    return {
        currentStreak: currentStreak_,
        maxStreak: maxStreak_,
        totalPlayed: totalPlayed_,
        totalWon: totalWon_,
    };
};
