// @flow
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { GTG_COMMUNITY_SUBMISSION_URL, IMAGE_EXTENSION, PUZZLE_TYPES } from '../constants';
import { API_BASE, calculatePuzzleDate } from '../globals';
import { goToDay } from '../clientUtils';
import { loadLocalStorageItem } from '../localStateUtils';
import Footer from './Footer';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { useSettings } from '../settings/useSettings';
import { useSelector } from 'react-redux';
import type { State } from '../store/types';

type TProps = {
    src: string,
    alt: string,
    onLoad?: (() => void) | null,
    ...
};

const ImageLoader = ({ src, alt, onLoad = null, ...props }: TProps) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const handleImageLoaded = () => {
        setLoaded(true);
        if (typeof onLoad == 'function') {
            onLoad();
        }
    };

    const handleImageError = () => {
        setError(true);
    };

    if (error) {
        return <div style={{ textAlign: 'center', color: '#eee' }}>Original Image does not exist</div>;
    }

    return (
        // $FlowIgnore
        <img
            src={src}
            alt={`${loaded.toString()}`}
            onLoad={handleImageLoaded}
            onError={handleImageError}
            style={{ display: loaded ? 'block' : 'none' }}
            {...props}
        />
    );
};

const OriginalImages = (): React$Element<'div'> => {
    const { puzzleId } = useParams();
    const [ogImages, setogImages] = useState(null);
    const [wasBannerSetup, setWasBannerSetup] = useState(false);
    const settings = useSettings();
    const userState = useSelector((state: State) => state.user);
    const [imagesLoaded, setImagesLoaded] = useState([Array(6).fill(false)]);
    const allImagesLoaded = useMemo(() => imagesLoaded.every((b) => b === true), [imagesLoaded]);

    const getAdCounterFromImageIndex = (index: number) => {
        if (index === 1) return 1;
        if (index === 3) return 2;
        if (index === 5) return 3;
        return null;
    };

    const location = useLocation();
    const selectedImage = location.state?.selectedImage;

    const ogStyle = {
        color: 'white',
    };

    const titleStyle = {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
    };

    const captionStyle = {
        textAlign: 'center',
    };

    const buttonStyle = {
        display: 'flex',
        textAlign: 'center',
        margin: '20px auto',
        justifyContent: 'center',
        alignItems: 'center',
    };

    // make sure that you cant go to a puzzle id that is past the current puzzle id
    if (Number(puzzleId) > settings.num_days_from_start_date()) {
        console.log('cant go past');
        goToDay(settings.num_days_from_start_date(), settings.paths.puzzle, settings);
    }

    // make sure that the user has solved the puzzle before they can see the rest of this page
    const gamestate = loadLocalStorageItem(puzzleId, 'gamestate');
    // check if gamestate is 'win' or 'lose'
    if (gamestate !== 'win' && gamestate !== 'lose') {
        console.log('gamestate issue');
        goToDay(puzzleId, settings.paths.puzzle, settings);
    }

    useEffect(() => {
        const getOgImages = async () => {
            try {
                // setogImages({
                //     '1': {
                //         source: 'neogaf.com',
                //         caption: 'landing base',
                //     },
                //     '2': {},
                //     '3': {
                //         source: 'twitter.com',
                //         caption: 'home base',
                //     },
                //     '4': {
                //         source: '',
                //         caption: ' Lorem Ipsum',
                //     },
                //     '5': {
                //         source: 'sijdsflj',
                //         caption: '',
                //     },
                //     '6': {
                //         source: 'sijdsflj',
                //         caption: 'sldkjf',
                //     },
                // });

                const response = await axios.get(`${API_BASE}/api/get_og_images/` + puzzleId + '/');
                setogImages(response['data']);
            } catch (error) {
                console.error(error);
                setogImages({}); // Proceed anyhow even if ogImages is unavailable
            }
        };

        getOgImages();
    }, [puzzleId]);

    useEffect(() => {
        if (ogImages && !wasBannerSetup && !userState.user) {
            window.freestar.config.enabled_slots.push({
                placementName: 'guessthe-game_incontent_1',
                slotId: 'guessthe-game_incontent_1',
            });
            window.freestar.config.enabled_slots.push({
                placementName: 'guessthe-game_incontent_2',
                slotId: 'guessthe-game_incontent_2',
            });
            window.freestar.config.enabled_slots.push({
                placementName: 'guessthe-game_incontent_3',
                slotId: 'guessthe-game_incontent_3',
            });
            setWasBannerSetup(true);
        }
    }, [ogImages, userState.user, wasBannerSetup]);

    useEffect(() => {
        if (allImagesLoaded && selectedImage) {
            console.log('scrolling to: ', selectedImage);
            const element = document.getElementById(selectedImage.toString());
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
    }, [allImagesLoaded, selectedImage]); // Reacting to changes in selectedImage

    if (!ogImages) {
        console.log('loading');
        return <div className={'loadingStyle'}>Loading...</div>;
    }

    console.log('og images: ', ogImages);

    return (
        <div className="current-game">
            <meta name="browsi" />
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {settings.guess_item} #{puzzleId} Original Images
                <br />
                {calculatePuzzleDate(puzzleId, settings)}
            </div>
            <div style={ogStyle}>
                {[1, 2, 3, 4, 5, 6].map((imageIndex) => {
                    let path = `/games/${puzzleId}/og/${imageIndex}.${IMAGE_EXTENSION}`;
                    const ogImage = ogImages[imageIndex];
                    console.log('ogImage: ', ogImage);
                    const currentAdCounter = getAdCounterFromImageIndex(imageIndex);
                    return (
                        <div id={imageIndex} key={imageIndex} style={{ minWidth: '100px', minHeight: '200px' }}>
                            <div>
                                <div style={titleStyle}>Screenshot {imageIndex}</div>
                                <ImageLoader
                                    src={path}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    loading={'lazy'}
                                    width="100%"
                                    height="100%"
                                    alt={`Screenshot ${imageIndex}`}
                                    onLoad={() =>
                                        setImagesLoaded((state) => {
                                            const newState = [...state];
                                            newState[imageIndex - 1] = true;
                                            return newState;
                                        })
                                    }
                                />
                            </div>
                            <div>
                                {ogImage ? (
                                    <div style={captionStyle}>
                                        {ogImage['content'] ? (
                                            <div>
                                                <div>
                                                    <b>
                                                        <i>{ogImage['content']}</i>
                                                    </b>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div></div>
                                            </div>
                                        )}
                                        {ogImage['source'] ? (
                                            <div>
                                                <div>
                                                    <b>Source:</b> <a href={ogImage['source']}>{ogImage['source']}</a>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div></div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            {/* If on imageindex 1,3,5 then add a div with incremented id */}
                            {currentAdCounter != null ? (
                                <div
                                    align="center"
                                    data-freestar-ad={`__512x512`}
                                    id={`guessthe-game_incontent_${currentAdCounter}`}
                                ></div>
                            ) : (
                                <div></div>
                            )}

                            <br />
                        </div>
                    );
                })}
                <div>
                    <button
                        className="mainButton share-results-btn"
                        style={buttonStyle}
                        onClick={() => goToDay(puzzleId, 'puzzle', settings)}
                    >
                        {t('Play This Puzzle')}
                    </button>
                    Unless specified, our images come from <a href={'steampowered.com'}>Steam</a>,{' '}
                    <a href={'mobygames.com'}>Moby Games</a>, ourselves, or players like you!
                    <br />
                    <br />
                    We try to include information and sources as much as we can but sometimes we don't have the original
                    information. Want to add a caption, source, suggestion or clarification?{' '}
                    <a href={'mailto:owner@' + settings.website_url}>Email Us!</a>
                    <br />
                    <br />
                    Want to contribute a daily puzzle?{' '}
                    <a href={GTG_COMMUNITY_SUBMISSION_URL} target="_blank" rel="noreferrer noopener">
                        Click here!
                    </a>
                </div>
            </div>
            <Footer puzzleId={puzzleId} currentPage={'original_images'} puzzleType={PUZZLE_TYPES.GTG} />
        </div>
    );
};

export default OriginalImages;
