import React from 'react';
import { useTranslation } from 'react-i18next';
import { LNGS } from '../constants';

function LanguageSelector() {
    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <select value={i18n.resolvedLanguage} onChange={changeLanguage}>
            {Object.keys(LNGS).map((lng) => (
                <option key={lng} value={lng}>
                    {LNGS[lng].nativeName}
                </option>
            ))}
        </select>
    );
}

export default LanguageSelector;
