import {
    GUESS_IMAGE_FAILURE_COLOR,
    GUESS_IMAGE_FRANCHISE_COLOR,
    GUESS_IMAGE_NEUTRAL_COLOR,
    GUESS_IMAGE_SUCCESS_COLOR,
} from '../constants';

const ImageSelectorButtons = ({ selectedImage, changeImage, gameState, guesses, franchiseMatch }) => {
    const guessImageSuccessStyle = {
        backgroundColor: GUESS_IMAGE_SUCCESS_COLOR,
    };
    const guessImageNeutralStyle = {
        backgroundColor: GUESS_IMAGE_NEUTRAL_COLOR,
    };
    // eslint-disable-next-line no-unused-vars
    const guessImageFranchiseStyle = {
        backgroundColor: GUESS_IMAGE_FRANCHISE_COLOR,
    };
    const guessImageFailStyle = {
        backgroundColor: GUESS_IMAGE_FAILURE_COLOR,
    };

    const createButton = (index) => {
        const isActive = selectedImage === index;
        const isLocked = gameState === 'playing' && guesses <= index - 2;
        const isSuccess = gameState !== 'playing' && guesses === index && gameState === 'win';
        const isFail = gameState !== 'playing' && guesses >= index && !isSuccess;
        const isFranchise = franchiseMatch[index - 1];

        const classNames = [
            'gamecube',
            isActive ? 'active' : '',
            isLocked ? 'locked' : '',
            isSuccess ? 'guess-image-success' : '',
            isFail && !isFranchise ? 'guess-image-fail' : '',
            gameState === 'win' && guesses === 1 ? 'shake-and-change-color' : '',
        ].join(' ');

        return (
            <button
                tabIndex={-1}
                onClick={() => {
                    changeImage(index);
                }}
                className={classNames}
                style={
                    isSuccess
                        ? guessImageSuccessStyle
                        : isFranchise
                        ? guessImageFranchiseStyle
                        : isFail
                        ? guessImageFailStyle
                        : isLocked
                        ? guessImageNeutralStyle
                        : guessImageNeutralStyle
                }
            >
                {index}
            </button>
        );
    };

    return (
        <>
            {createButton(1)}
            {createButton(2)}
            {createButton(3)}
            {createButton(4)}
            {createButton(5)}
            {createButton(6)}
        </>
    );
};

export default ImageSelectorButtons;
