// @flow

import { copyToClipboard } from '../clientUtils';
import { useSettings } from '../settings/useSettings';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ShareGuesses = ({
    guessList,
    puzzleId,
}: {
    guessList: Array<string>,
    puzzleId: number,
}): React$Element<React$FragmentType> => {
    const { t } = useTranslation();
    const [shareButtonText, setButtonText] = useState(t('Share Guesses'));
    const settings = useSettings();
    return (
        <>
            <button
                className="mainButton share-results-btn copy-to-clipboard "
                onClick={() => {
                    let text =
                        settings.clipboard_tag +
                        ` #${puzzleId}\n\n${guessList
                            .map((item, index) => `${index + 1}) ${item}`)
                            .join('\n')}\n\nhttps://${settings.website_url}/`;

                    copyToClipboard(text);
                    setButtonText('Copied');
                    setTimeout(() => {
                        setButtonText('Share Guesses');
                    }, 2000);
                }}
            >
                {shareButtonText}
            </button>
        </>
    );
};

export default ShareGuesses;
