// @flow
import axios from 'axios';

export const API_BASE: string = window.location.href.startsWith('http://localhost:3000')
    ? 'http://localhost:8000'
    : 'https://api.guessthe.game';

async function fetchCSRF(): Promise<any> {
    // $FlowIgnore
    if (!axios.defaults.headers.post['X-CSRFToken']) {
        let response = await axios.get(`${API_BASE}/api/csrf/`);
        let resp_json = await response.data;

        // $FlowIgnore post is a dictionary where we can add headers
        axios.defaults.headers.post['X-CSRFToken'] = resp_json.csrfToken;
        const csrf_token: string = resp_json['csrfToken'];
        return csrf_token;
    }
}

function getCookies(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
}

export function updateCSRF(): void {
    const newCsrf = getCookies('csrftoken');
    if (newCsrf) {
        // $FlowIgnore post is a dictionary where we can add headers
        axios.defaults.headers.post['X-CSRFToken'] = getCookies('csrftoken');
    }
}

async function post_wrapper(url: string, params: Object): Promise<any> {
    await fetchCSRF();
    return await axios.post(url, params);
}

export { post_wrapper };
