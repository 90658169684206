import React from 'react';

const SentenceComponent = ({ sentence }) => {
    const myStyle = {
        color: 'white',
        padding: '10px',
        marginTop: '8%',
        fontSize: '18px',
    };
    return (
        <div className="sentence">
            <p style={myStyle}>{sentence}</p>
        </div>
    );
};

export default SentenceComponent;
