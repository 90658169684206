import React, { useState } from 'react';

const Hint = ({ hintText, customStyle }) => {
    const [showHints, setShowHint] = useState(true);
    const handleClick = () => {
        setShowHint(!showHints);
    };
    const style = {
        minWidth: '100px',
        width: 'auto',
        display: hintText ? 'block' : 'none',
        ...customStyle,
    };

    return (
        <div className={`guess-hint`} onClick={handleClick} style={style}>
            <span style={{ visibility: showHints ? 'visible' : 'hidden' }}>{hintText}</span>
        </div>
    );
};

export default Hint;
