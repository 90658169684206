import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

export function useStatsToast() {

    const { puzzleType } = useSelector((state: State) => state.ux);
    const { currentRank } = useSelector(
        (state: State) => state.stats[puzzleType],
    );
    const [updatedRank, setUpdatedRank] = useState(currentRank)

    useEffect(() => {
        if (currentRank !== updatedRank) {
            setUpdatedRank(currentRank)
            toast.success(`Congratulations! You've reached a new rank of ${currentRank}`, {
                duration: 3500,
            });
        }
    }, [updatedRank, currentRank]);

}

